import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import * as actions from "./subscriptionsActions";
import { shallowEqual, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../../_metronic/_partials/controls";
import { useSubheader } from "../../../../_metronic/layout";
import BootstrapTable from 'react-bootstrap-table-next';
import { sortCaret } from "../../../../_metronic/_helpers";
import { useIntl } from "react-intl";
import { useLang } from "../../../../_metronic/i18n/Metronici18n";
import languages from "../../Shared/Enums/languages";
import { Badge } from "react-bootstrap";
import LocalFloristIcon from "@material-ui/icons/LocalFlorist";
import FastNavigationBar from "../../Shared/Components/fastNavigationBar";

export default function StationInsectOccurrencesCultivationsPage({
  history,
  match: {
    params: { stationId },
  }
}) {
  const subheader = useSubheader();
  const dispatch = useDispatch();

  const intl = useIntl();
  const lang = languages[useLang()];
  const { messages: msg } = intl;

  const [title, setTitle] = useState(msg.LOADING_DATA);

  useEffect(() => {
    dispatch(actions.resetDataState());
    dispatch(actions.fetchStationInsectOccurrencesCultivations(stationId));
  }, [dispatch]);

  const { stationInsectOccurrencesCultivations } = useSelector(
    (state) => ({
      actionsLoading: state.subscriptions.actionsLoading,
      stationInsectOccurrencesCultivations: state.subscriptions.stationInsectOccurrencesCultivations
    }),
    shallowEqual
  );

  const [stationName, setStationName] = useState('');
  const [stationCultivations, setStationCultivations] = useState([]);

  useEffect(() => {
    

    if (stationInsectOccurrencesCultivations) {
      let _title = msg.SUBSCRIPTION_STATION_INSECT_OCCURRENCES_CULTIVATIONS;
      setStationName(stationInsectOccurrencesCultivations.stationName);
      setStationCultivations(stationInsectOccurrencesCultivations.stationCultivations);
      setTitle(`${stationName} - ${_title}`);
      subheader.setTitle(`${stationName} - ${_title}`);
    }

    
  }, [stationInsectOccurrencesCultivations, []]);

  const backToLocations = () => {
    history.push(`/locations`);
  };

  const goToCultivationInsectsOccurrences = cultivationId => {
    history.push(`/locations/${stationId}/insectOccurrences/cultivation/${cultivationId}`);
  }

  const columns = [
    {
      dataField: 'id',
      text: msg.CULTIVATIONS,
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        <>
          <Badge variant="info" className="mr-2" role="button" onClick={() => goToCultivationInsectsOccurrences(row.id)}><LocalFloristIcon /> {row.names[lang]}</Badge>
        </>
      ),
      sort: true,
      sortCaret: sortCaret,
      sortValue: (cell, row) => row.names[lang]
    }
  ];

  return (
    <Card>
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToLocations}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            {msg.MENU_RETURN}
          </button>
          {`  `}
          <FastNavigationBar stationId={stationId} section="Occurrences" history={history} />
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {stationCultivations && <BootstrapTable keyField='id' data={stationCultivations === undefined ? [] : stationCultivations} columns={columns}
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
          defaultSorted={[{
            dataField: 'id',
            order: 'asc'
          }]}
        />}
      </CardBody>
    </Card>
  );
}
