/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import * as actions from "./subscriptionsActions";
import { shallowEqual, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useSubheader } from "../../../../_metronic/layout";
import { useIntl } from "react-intl";
import { useLang } from "../../../../_metronic/i18n/Metronici18n";


export default function VirtualStationForecastPage({
  history,
  match: {
    params: { virtualStationId },
  }
}) {
  const subheader = useSubheader();
  const dispatch = useDispatch();

  const langName = useLang();
  const intl = useIntl();
  const { messages: msg } = intl;

  const [title, setTitle] = useState(msg.LOADING_DATA);

  useEffect(() => {
    dispatch(actions.fetchVirtualStationForecast(virtualStationId, langName));
  }, [dispatch]);

  const { virtualStationForecast } = useSelector(
    (state) => ({
      actionsLoading: state.subscriptions.actionsLoading,
      virtualStationForecast: state.subscriptions.virtualStationForecast
    }),
    shallowEqual
  );

  const [forecastImage, setForecastImage] = useState(null);
  const [stationName, setStationName] = useState('');

  useEffect(() => {
    if (virtualStationForecast) {
      setForecastImage(virtualStationForecast.forecastImage);
      setStationName(virtualStationForecast.stationName);
    }
  }, [virtualStationForecast]);

  useEffect(() => {
    let _title = msg.SUBSCRIPTION_FORECAST;

    if (virtualStationForecast) {
      setTitle(`${_title} ${stationName}`);
    }

    subheader.setTitle(_title);
  }, [virtualStationForecast, []]);

  const backToLocations = () => {
    history.push(`/virtual`);
  };

  return (
    <Card>
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToLocations}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            {msg.MENU_RETURN}
          </button>
          {`  `}

        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5 text-center">
          {forecastImage &&
            <img src={`data:image/png;base64,${forecastImage}`} className="img-fluid" />
          }
        </div>
      </CardBody>
    </Card>
  );
}
