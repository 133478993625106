/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import * as actions from "./subscriptionsActions";
import { shallowEqual, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useSubheader } from "../../../../_metronic/layout";
import { useIntl, FormattedHTMLMessage } from "react-intl";
import { useLang } from "../../../../_metronic/i18n/Metronici18n";
import InsectModelChart from "../../Shared/Charts/insectModelChart";
import InsectOwnModelChart from "../../Shared/Charts/insectOwnModelChart";
import languages from "../../Shared/Enums/languages";
import FastNavigationBar from "../../Shared/Components/fastNavigationBar";
import DatePicker, { registerLocale } from "react-datepicker";
import { Navbar, Button } from "react-bootstrap";
import timezoneHelper from '../../Shared/Helpers/timezoneHelper';
import  {DateTime} from 'luxon';

export default function LocationCultivationInsectModelsPage({
  history,
  match: {
    params: { stationId, cultivationId },
  }
}) {
  const subheader = useSubheader();
  const dispatch = useDispatch();
  const langName = useLang();
  const intl = useIntl();
  const { messages: msg } = intl;
  const lang = languages[langName];
  const [title, setTitle] = useState(msg.LOADING_DATA);
  const [stationName, setStationName] = useState('');
  const year =  new Date().getFullYear();
  const month = `${new Date().getMonth()<10?'0':''}${new Date().getMonth()+1}`;
  const day =  `${new Date().getDate()<10?'0':''}${new Date().getDate()}`;

  registerLocale('currentDatePickerLang', {
    localize: {
      month: n => msg.MONTHS[n],
      day: n => msg.DAYS[n]
    },
    options: {
      weekStartsOn: 1
      /* Monday */
      ,
      firstWeekContainsDate: 2
    },
    formatLong: {}
  });

  const dayString = `${month}-${day}`;
  const newDateFrom = timezoneHelper.toCosmosUTC(timezoneHelper.parseCosmosDateString(`${dayString>='03-01'?year:year-1}-${'03-01'}T00:00:00.000Z`, 'Europe/Warsaw'));
  const newDateTo = timezoneHelper.toCosmosUTC(timezoneHelper.parseCosmosDateString(`${dayString>='03-01'?year:year-1}-${'12-31'}T22:59:59`, 'Europe/Warsaw'));
  const [dateRangeFrom, setDateRangeFrom] = useState(new Date(newDateFrom.dateString));
  const [dateRangeTo, setDateRangeTo] = useState(new Date(newDateTo.dateString));

  const [dateFromString, setDateFromString] = useState(null);
  const [dateToString, setDateToString] = useState(null);
  const [renderInsectModels, setRenderInsectModels] = useState(true);
  const { stationCultivationInsectModels} = useSelector(
    (state) => ({
      actionsLoading: state.subscriptions.actionsLoading,
      stationCultivationInsectModels: state.subscriptions.stationCultivationInsectModels
    }),
    shallowEqual
  );

  useEffect(() => {
    if (dateRangeFrom) {
      setDateFromString(DateTime.fromJSDate(dateRangeFrom, { zone: 'Europe/Warsaw' }).toFormat('yyyy-MM-dd'));
      // console.log(DateTime.fromJSDate(dateRangeFrom, { zone: 'Europe/Warsaw' }).toFormat('yyyy-MM-dd'));

    }
  }, [dateRangeFrom]);

  useEffect(() => {
    if (dateRangeTo)
      setDateToString(DateTime.fromJSDate(dateRangeTo, { zone: 'Europe/Warsaw' }).toFormat('yyyy-MM-dd'));
  }, [dateRangeTo]);

  useEffect(() => {
    let _title = msg.SUBSCRIPTION_INSECT_MODELS;

    if (stationCultivationInsectModels?.insectModelsData) {
      setStationName(stationCultivationInsectModels.stationName);      
      setTitle(`${_title} - ${stationCultivationInsectModels.cultivationNames[lang]} - ${stationName}`);
    }

    subheader.setTitle(_title);


    if (stationCultivationInsectModels?.info == 'No data') {
      setTitle(msg.NO_AVAILABLE_INSECT_MODELS);
    }
    
  }, [stationCultivationInsectModels, []]);

  const backToStationCultivationsModels = () => {
    history.push(`/locations/${stationId}/insectModels/cultivations`);
  };

  useEffect(() => {
    dispatch(actions.fetchStationCultivationInsectModels(stationId, cultivationId, year));
  }, [dispatch]);

  const reRender = _ => {
    setRenderInsectModels(false);
    setTimeout(() => {
      setRenderInsectModels(true);
    }, 500);
  }

  return (
    <Card>
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToStationCultivationsModels}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            {msg.MENU_RETURN}
          </button>
          {`  `}
          <FastNavigationBar stationId={stationId} section="Models" history={history} />
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ul style={{fontSize: '16px'}} className="mb-5">
          <FormattedHTMLMessage id="INSECTS_MODELS_INFO" />
        </ul>
        <hr/>
        <Navbar className="bg-light pt-5 pb-5">
          
          <DatePicker
            locale="currentDatePickerLang"
            selected={dateRangeFrom}
            dateFormat="yyyy-MM-dd"
            onChange={dateFrom => setDateRangeFrom(dateFrom)}
            className="form-control mr-5"
            popperPlacement="bottom-start"
            onKeyDown={(event) => {
              event.preventDefault();
            }}
          />

          <DatePicker
            locale="currentDatePickerLang"
            selected={dateRangeTo}
            dateFormat="yyyy-MM-dd"
            onChange={dateTo => setDateRangeTo(dateTo)}
            className="form-control ml-2"
            popperPlacement="bottom-start"
            onKeyDown={(event) => {
              event.preventDefault();
            }}
          />

      <Button className="btn-primary btn-sm ml-4" onClick={() => {reRender()}}>
            {'OK'}
          </Button>
          {
            <div style={{ width: '100%', textAlign: 'right' }} className="text-right">
            </div>}
        </Navbar>
        <div className="mt-5 text-center">
            { 
              renderInsectModels && stationCultivationInsectModels && Array.isArray(stationCultivationInsectModels.cultivationInsectModels) && stationCultivationInsectModels.cultivationInsectModels.map((insectModel, key) => {
                let insectModelData = stationCultivationInsectModels.insectModelsData.insectModels.find(data => data.modelId == insectModel.insectModelId);
                let modelInfo = stationCultivationInsectModels.insectModelsData.insectModelsInfo.find(data => data.id == insectModel.insectModelId) || null;
                let avgTemp = stationCultivationInsectModels.insectModelsData.tempInfo;
                return <InsectModelChart
                  key={key}
                  insectData={insectModelData}
                  stationName={stationName}
                  cultivationName={stationCultivationInsectModels.cultivationNames[lang]}
                  insectModel={insectModel}
                  chartSettings={modelInfo?.chartSettings || null}
                  dateFromString={dateFromString}
                  dateToString={dateToString}
                  avgTemp={avgTemp}
                  minTemp={modelInfo?.minTemp || ''}
                />
              })
            }

            {
              stationName  != '' && renderInsectModels && typeof stationCultivationInsectModels?.ownInsectsModels?.ownInsectsModelsData == 'object' && stationCultivationInsectModels.ownInsectsModels?.ownInsectsModelsData != {} && Object.keys(stationCultivationInsectModels?.ownInsectsModels?.ownInsectsModelsData)?.length>0 && Object.keys(stationCultivationInsectModels?.ownInsectsModels?.ownInsectsModelsData)?.map((insectModel, key) => {
                let insectModelData = stationCultivationInsectModels.ownInsectsModels.ownInsectsModelsData[insectModel];
                let chartSettings   = stationCultivationInsectModels.ownInsectsModels.chartsSettings[insectModel];

                return <InsectOwnModelChart
                  key={key}
                  insectData={insectModelData}
                  stationName={stationName}
                  cultivationName={stationCultivationInsectModels.cultivationNames[lang]}
                  chartSettings={chartSettings}
                  dateFromString={dateFromString}
                  dateToString={dateToString}
                />
              })
            }
        </div>
      </CardBody>
    </Card>
  );
}
