import React, { Suspense, lazy, useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import SignUpPage from "./modules/SignUpPartners/SignUp";
import SignUpMapCommonPage from "./modules/SignUpPartners/SignUpMapCommon";

export default function SignUpRoutesPage({companyName}) {
  return (
      <SignUpMapCommonPage companyName={companyName} />
  );
}
