import React from 'react'
import { Button } from 'react-bootstrap';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import GetApp from '@material-ui/icons/GetApp';
import timezoneHelper from '../Helpers/timezoneHelper';

export default function ExportAsXLS({ stationName, stationSensorsList, language }) {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToXLS = async (stationName, stationSensorsList, language) => {

        const fileName = `${stationName}`;
        let wb = {};
        let ws;
        let SheetNames = [];
        let Sheets = {};
        let i = 0;

        for (const stationSensor of stationSensorsList) {
            let rows = [];
            let row = [];
            i++;

            let { settings } = stationSensor.sensorGroup;
            if (settings?.includedInXLS != true) continue;

            let SheetName = `${settings.names[language]} [${settings.unit}]`;

            let SheetNameUnique = `${i}. ${settings.names[language]}`;
            SheetNames.push(SheetNameUnique.substring(0, 31));

            row = [`${SheetName}`];
            rows.push(row);

            row = [''];
            for (const pesslType of settings.types) {
                row.push(pesslType.names[language])
            }
            rows.push(row);

            for (const pesslData of stationSensor.sensorData) {
                let parsedDate = timezoneHelper.parseCosmosDateString(pesslData.date, pesslData.dateTimeZone);
                row = [timezoneHelper.toDateTimeString(parsedDate)];
                for (const value of pesslData.values) {
                    let valIndex = settings.types.findIndex(type => type.typePesslName == value.type);
                    if (valIndex != -1)
                        row[valIndex + 1] = value.value;
                }
                rows.push(row);
            }

            var wscols = [
                { width: 30 },
                { width: 15 },
                { width: 15 },
                { width: 15 },
                { width: 15 },
                { width: 15 },
                { width: 15 },
                { width: 15 },
                { width: 15 }
            ];



            ws = XLSX.utils.aoa_to_sheet(rows);
            ws['!cols'] = wscols;

            Sheets[SheetNameUnique.substring(0, 31)] = ws;

        }


        wb = { Sheets, SheetNames };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', Props: { Author: "FarmSmart.pl" } });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${fileName}${fileExtension}`);
    }

    return (
        <Button variant="outlined" className="btn-sm btn-secondary" onClick={(e) => exportToXLS(stationName, stationSensorsList, language)}><GetApp /> xls</Button>
    )
}