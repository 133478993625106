import React, { useEffect, useState, useRef } from "react";
import { Chart, Bar } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import _ from "lodash";
import timezoneHelper from '../Helpers/timezoneHelper';
import { useLang } from "../../../../_metronic/i18n/Metronici18n";
import { useIntl } from "react-intl";
import languages from "../Enums/languages";
import ExportAsJPG from '../Data/exportAsJPG';

export default function SensorBarChart({
    sensorGroup,
    sensorData,
    stationName,
    virtual
}) {

    const toggleXAxesDisplayPlugin = {
        id: 'toggleXAxesDisplayPlugin',
        resize: function(chartInstance) {
            if (window.innerWidth > 1200) {
                chartInstance.config.options.aspectRatio = 2;
                chartInstance.config.options.scales.x.ticks.font.size = 11;
            } else
            if (window.innerWidth > 800) {
                chartInstance.config.options.aspectRatio = 1.4;
                if (window.innerWidth > window.innerHeight) chartInstance.config.options.aspectRatio = 1.5;
                chartInstance.config.options.scales.x.ticks.font.size = 11;
            } else 
            if (window.innerWidth > 500) {
                chartInstance.config.options.aspectRatio = 1;
                if (window.innerWidth > window.innerHeight) chartInstance.config.options.aspectRatio = 1.5;
                chartInstance.config.options.scales.x.ticks.font.size = 11;
            } else 
            if (window.innerWidth > 400) {
                chartInstance.config.options.aspectRatio = 0.8;
                chartInstance.config.options.scales.x.ticks.font.size = 10;
            } else {
                chartInstance.config.options.aspectRatio = 0.6;
                chartInstance.config.options.scales.x.ticks.font.size = 8;
            }

            chartInstance.update();
        }
    }

    const backgroundPlugin = {
        id: 'backgroundPlugin',
        beforeDraw: function (chart) {
            var ctx = chart.canvas.getContext('2d');
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
        }
    }

    Chart.register([annotationPlugin, backgroundPlugin, toggleXAxesDisplayPlugin]);

    const langName = useLang();
    const lang = languages[langName];
    const chartRef = useRef(null);
    const intl = useIntl();
    const { messages: msg } = intl;

    useEffect(() => {
        if (sensorGroup?.settings) {

            let { settings } = sensorGroup;

            if (sensorData.length > 0) {
                let labels = [];
                let chartDataArray = [];

                settings.types.map((type, key) => {
                    let dataModelToAdd = {
                        id: key + 1,
                        label: type.names[lang],
                        typePesslName: type.typePesslName,
                        data: [],
                        backgroundColor: type.chartColor,
                        borderColor: `${type.chartColor}33`,
                        borderWidth: 0

                    }
                    chartDataArray.push(dataModelToAdd);
                });

                var precipitationSum = 0;
                var snowSum = 0;
                var temperatureMin = null;
                var temperatureMax = null;

                sensorData.map(data => {
                    if (data.values.length > 0) {
                        let date = timezoneHelper.parseCosmosDateString(data.date, data.dateTimeZone);
                        
                        labels.push(timezoneHelper.toDateTimeString(date).substring(2));
                        data.values.map(value => {
                            let typeIndex = chartDataArray.findIndex(type => type.typePesslName === value.type);
                            if (typeIndex !== -1) {
                                let typeData = [...chartDataArray[typeIndex].data];
                                if (sensorGroup.groupKey == 1) { //sensor temp.
                                    if (temperatureMin === null || temperatureMin > value.value) temperatureMin = value.value;
                                    if (temperatureMax === null || temperatureMax < value.value) temperatureMax = value.value;
                                }

                                if (sensorGroup.groupKey == 5) { //sens. opadów
                                    if (virtual) {
                                        if (value.type == 'snow_sum') snowSum += value.value;
                                    }
                                    if (value.type == 'sum')
                                    precipitationSum += value.value;
                                }
                                typeData.push(value.value);
                                chartDataArray[typeIndex].data = [...typeData];
                            }
                        });
                        setLabels(labels);
                        setSensorChartData(chartDataArray);
                    }
                });

            }

            var titleText = [`${sensorGroup.settings.names[lang]} [${sensorGroup.settings.unit}]`];

            if (sensorGroup.groupKey == 1) {

                titleText.push('');
                titleText.push(`(${msg.TEMPERATURE_MIN}: ${temperatureMin} ${sensorGroup.settings.unit}, ${msg.TEMPERATURE_MAX}: ${temperatureMax} ${sensorGroup.settings.unit})`);
            }

            if (sensorGroup.groupKey == 5) {

                titleText.push('');
                if (virtual) {
                    titleText.push(`(${msg.PRECIPITATION_SUM}: ${precipitationSum.toFixed(1)} ${sensorGroup.settings.unit}, ${msg.SNOW_SUM}: ${snowSum.toFixed(1)} mm)`);
                } else {
                    titleText.push(`(${msg.PRECIPITATION_SUM}: ${precipitationSum.toFixed(1)} ${sensorGroup.settings.unit})`);
                }
            }

            titleText.push('');
            titleText.push(stationName);

            setChartLabel(`${settings.names[0]} [${settings.unit}]`);
            setChartUnit(settings.unit);
            if (settings.minVal != '') setMinValue(settings.minVal);
            if (settings.maxVal != '') setMaxValue(settings.maxVal);

            const optionsModel = {
                scales: {
                    y: {
                        max: maxValue,
                        min: minValue
                    },
                    x: {
                        ticks: {
                            font: {
                                size: 11,
                            }
                        }
                      }
                },
                plugins: {
                    title: {
                        display: true,
                        text: titleText,
                        font: {
                            family: "Poppins, Helvetica",
                            size: 18,
                            weight: 444,
                        }
                    }
                }
            };

            setChartOptions(optionsModel);

            setTimeout(() => {
                setOptionsArePrepared(true);
            }, 100);
        }

    }, [sensorGroup && sensorData]);

    const [labels, setLabels] = useState([]);
    const [sensorChartData, setSensorChartData] = useState([]);
    const [chartLabel, setChartLabel] = useState('');
    const [maxValue, setMaxValue] = useState(null);
    const [minValue, setMinValue] = useState(null);
    const [optionsArePrepared, setOptionsArePrepared] = useState(false);
    const [chartOptions, setChartOptions] = useState();
    const [chartUnit, setChartUnit] = useState('');

    const data = (canvas, cenas) => {
        const chartData = {
            labels: labels,
            datasets: sensorChartData
        };

        return chartData;
    };

    return (
        <div className="container p-5">
            <div className="mt-5 mb-5 text-center col-sm-12 col-md-12 col-lg-9 mx-auto">
                <h4>{optionsArePrepared && <ExportAsJPG fileName={sensorGroup.settings.names[lang]} chartRef={chartRef} />}</h4>
                {optionsArePrepared && <Bar ref={chartRef} data={data} options={chartOptions} />}
            </div>
            <hr />
        </div>
    );
}