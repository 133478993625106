import React, { useEffect, useState, useRef } from "react";
import { Line, Chart } from "react-chartjs-2";
import timezoneHelper from '../Helpers/timezoneHelper';
import { useLang } from "../../../../_metronic/i18n/Metronici18n";
import { useIntl } from "react-intl";
import languages from "../Enums/languages";
import ExportAsJPG from '../Data/exportAsJPG';
import ExportInsectOccurencesAsXLS from "../Data/exportInsectOccurencesAsXLS";
import annotationPlugin from "chartjs-plugin-annotation";
import { Badge } from "react-bootstrap";

export default function InsectOccurrencesPercentBarChart({
    occurrences,
    insect,
    occurrencesPrevious,
    stationName,
    cultivationName,
}) {

    const toggleXAxesDisplayPlugin = {
        id: 'toggleXAxesDisplayPlugin',
        resize: function(chartInstance) {
            if (window.innerWidth > 1200) {
                chartInstance.config.options.aspectRatio = 2;
                chartInstance.config.options.scales.x.ticks.font.size = 11;
            } else
            if (window.innerWidth > 800) {
                chartInstance.config.options.aspectRatio = 1.4;
                if (window.innerWidth > window.innerHeight) chartInstance.config.options.aspectRatio = 1.5;
                chartInstance.config.options.scales.x.ticks.font.size = 11;
            } else 
            if (window.innerWidth > 500) {
                chartInstance.config.options.aspectRatio = 1;
                if (window.innerWidth > window.innerHeight) chartInstance.config.options.aspectRatio = 1.5;
                chartInstance.config.options.scales.x.ticks.font.size = 11;
            } else 
            if (window.innerWidth > 400) {
                chartInstance.config.options.aspectRatio = 0.8;
                chartInstance.config.options.scales.x.ticks.font.size = 10;
            } else {
                chartInstance.config.options.aspectRatio = 0.6;
                chartInstance.config.options.scales.x.ticks.font.size = 8;
            }

            chartInstance.update();
        }
    }

    const backgroundPlugin = {
        id: 'backgroundPlugin',
        beforeDraw: function (chart) {
            var ctx = chart.canvas.getContext('2d');
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
        }
    }

    Chart.register([annotationPlugin, backgroundPlugin, toggleXAxesDisplayPlugin]);

    const langName = useLang();
    const lang = languages[langName];
    const chartRef = useRef(null);
    const intl = useIntl();
    const { messages: msg } = intl;
    const timeZone = "Europe/Warsaw";
    const [labels, setLabels] = useState([]);
    const [chartOccurrencesData, setChartOccurrencesData] = useState([]);
    const [chartOptions, setChartOptions] = useState({});
    const [optionsArePrepared, setOptionsArePrepared] = useState(false);
    const [chartColor, setChartColor] = useState("rgba(137, 80, 251, 0.7)");
    const [pointRadius, setPointRadius] = useState(2);
    const [chartLabel, setChartLabel] = useState('');
    const [lineHeight, setLineHeight] = useState(2);

    useEffect(() => {

        if (occurrences.length > 0) {
            let labels = [];
            let chartData = [];
            let maxValue = 0;
            let previousValue = 0;
            let valueToAdd;

            let {chartSettings} = insect;
            let {insectsMaxCount} = chartSettings;
            setChartColor(chartSettings.lineColor);
            setPointRadius(chartSettings.pointRadius);
            setLineHeight(chartSettings.lineHeight);

            let foundPreviousValue = occurrencesPrevious.find(occurrencePrevious => occurrencePrevious.insectId == insect.id);

            if (foundPreviousValue && foundPreviousValue?.quantities) {
                
                let foundQuantity = foundPreviousValue.quantities.find(quantity => quantity.stadium == 6);

                if (foundQuantity && foundQuantity?.quantity) previousValue = parseInt(foundQuantity.quantity);
            }

            occurrences.map(occurrence => {

                let date = timezoneHelper.parseCosmosDateString(occurrence.date, timeZone);
                let dateVal = timezoneHelper.toDateTimeString(date).substring(0,10);

                let { quantity } = occurrence;

                

                if (quantity >= previousValue) valueToAdd = quantity-previousValue;
                if (quantity < previousValue) valueToAdd = quantity;

                previousValue = quantity;
                
                labels.push(dateVal);

                let valueToCalc = valueToAdd>insectsMaxCount ? insectsMaxCount : valueToAdd;
                let percentVal = Math.round((valueToCalc/parseInt(insectsMaxCount))*100);
                chartData.push(percentVal);

                if (valueToAdd > maxValue) maxValue = valueToAdd;
            });

            setLabels(labels);
            setChartOccurrencesData(chartData);
    
            var titleText = [`${insect.names[lang]}`];
            titleText.push([]);
            titleText.push(`${cultivationName}, ${stationName}`);

            const optionsModel = {
                responsive: true,
                elements: {
                    bar: {
                        borderWidth: 2,
                    },
                },
                scales: {
                    y: {
                        ticks: {
                            stepSize: 1,
                            beginAtZero: true,
                            max: 100,
                            min: 0,
                            callback: function(val, index) {
                                if (val < 0 ) return '0';
                                if (val > 100) return '100';
                                return val;
                            }
                        }
                    },
                    x: {
                        ticks: {
                            font: {
                                size: 11,
                            }
                        }
                      }
                },
                plugins: {
                    title: {
                        display: true,
                        text: titleText,
                        font: {
                            family: "Poppins, Helvetica",
                            size: 18,
                            weight: 444,
                        }
                    },
                    annotation: {
                        annotations: {
                        }
                    }
                }
            };

            const annotationModel = {
                drawTime: "beforeDatasetsDraw",
                display: true,
                type: "box",
                yMin: null,
                yMax: null,
                backgroundColor: "",
                borderColor: "",
                borderWidth: 0
            }

            let createdAnnotations = {};
            let {levels} = chartSettings;

            levels.map((level, key) => {
                let newAnnotation = { ...annotationModel };

                newAnnotation.yMin = parseInt(level.minVal);
                newAnnotation.yMax = parseInt(level.maxVal);
                
                newAnnotation.backgroundColor = `${level.color}`;
                if (newAnnotation.yMin == 0) newAnnotation.yMin = -1;
                if (newAnnotation.yMax == 100) newAnnotation.yMax = 101;
                createdAnnotations[`box${key + 1}`] = { ...newAnnotation };
            });

            optionsModel.plugins.annotation.annotations = { ...createdAnnotations };

            setChartOptions(optionsModel);

            setTimeout(() => {
                setOptionsArePrepared(true);
            }, 100);

        }
    }, [occurrences]);

    const data = (canvas, cenas) => {
        const chartData = {
            labels: labels,
            datasets: [{
                label: `${msg.SUBSCRIPTION_INSECT_OCCURRENCES} [%]`,
                data: chartOccurrencesData,
                borderColor: chartColor,
                backgroundColor: `${chartColor}cc`,
                pointRadius: pointRadius,
                tension: 0.2,
                borderWidth: lineHeight,
              }]
        };

        return chartData;
    };

    return (
        <div className="container p-5">
            <div className="mt-5 mb-5 text-center col-sm-12 col-md-12 col-lg-9 mx-auto">
                <h4>{optionsArePrepared && <><ExportAsJPG fileName={insect.names[lang]} chartRef={chartRef} /> <ExportInsectOccurencesAsXLS className="pull-right" insect={insect} stationName={stationName} timeZone={timeZone} labels={labels} chartOccurrencesData={chartOccurrencesData} /> </>}</h4>
                
                {optionsArePrepared && <Line ref={chartRef} data={data} options={chartOptions} />}
                <div className="mt-3">{insect.chartSettings && insect.chartSettings.levels.map((level, key) => {
                    return <Badge key={key} className="mr-2" style={{ backgroundColor: level.color }}>{level.names[lang]}</Badge>
                })}</div>
            </div>
            <hr />
        </div>
    );
}