import { createSlice } from "@reduxjs/toolkit";

const initialSubscriptionsState = {
  listLoading: false,
  actionsLoading: false,
  entities: null,
  lastError: null,
  allAvailableLocations: null,
  cultivationsList: [],
  userCultivationsList: [],
  prices: {},
  calculatedOrder: null,
  invoiceInfo: null,
  userSubscriptions: null,
  stationCultivationInfo: null,
  stationAvailableSensors: null,
  virtualStationAvailableSensors: null,
  stationAvailableDiseaseModels: null,
  stationCultivationInsectModels: null,
  stationCultivationInsectOccurrences: null,
  virtualStations: [],
  virtualStationForecast: null,
  discountCodeInfo: null,
  affiliateEarnings: null,
  userSubscriptionsOrders: []
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState: initialSubscriptionsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    cultivationsListFetched: (state, action) => {
      const entities = action.payload;
      state.listLoading = false;
      state.error = null;
      state.cultivationsList = entities;
    },
    userCultivationsListFetched: (state, action) => {
      const entities = action.payload;
      state.listLoading = false;
      state.error = null;
      state.userCultivationsList = entities;
    },
    locationsFetched: (state, action) => {
      const entities = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
    },
    allAvailablelocationsFetched: (state, action) => {
      const entities = action.payload;
      state.listLoading = false;
      state.error = null;
      state.allAvailableLocations = entities.getAllAvailableStations;
    },
    stationForecastFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const { stationForecast } = action.payload;
      state.stationForecast = stationForecast;
    },
    virtualStationForecastFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const { virtualStationForecast } = action.payload;
      state.virtualStationForecast = virtualStationForecast;
    },
    stationAvailableSensorsFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const { stationAvailableSensors } = action.payload;
      state.stationAvailableSensors = stationAvailableSensors;
    },
    virtualStationAvailableSensorsFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const { virtualStationAvailableSensors } = action.payload;
      state.virtualStationAvailableSensors = virtualStationAvailableSensors;
    },
    availableVirtualStationsFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const { virtualStations } = action.payload;
      state.virtualStations = virtualStations;
    },
    virtualStationsReset: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.virtualStations = [];
    },
    stationAvailableDiseaseModelsFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const { stationAvailableDiseaseModels } = action.payload;
      state.stationAvailableDiseaseModels = stationAvailableDiseaseModels;
    },
    stationDiseasesCultivationsFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const { stationDiseasesCultivations } = action.payload;
      state.stationDiseasesCultivations = stationDiseasesCultivations;
    },
    stationCultivationInsectOccurrencesFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const { stationCultivationInsectOccurrences } = action.payload;
      state.stationCultivationInsectOccurrences = stationCultivationInsectOccurrences;
    },
    stationCultivationInsectModelsFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const { stationCultivationInsectModels } = action.payload;
      state.stationCultivationInsectModels = stationCultivationInsectModels;
    },
    stationInsectOccurrencesCultivationsFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const { stationInsectOccurrencesCultivations } = action.payload;
      state.stationInsectOccurrencesCultivations = stationInsectOccurrencesCultivations;
    },
    stationInsectModelsCultivationsFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const { stationInsectModelsCultivations } = action.payload;
      state.stationInsectModelsCultivations = stationInsectModelsCultivations;
    },
    pricesFetched: (state, action) => {
      const prices = action.payload;
      state.prices = prices;
      state.listLoading = false;
      state.error = null;
    },
    orderCalculated: (state, action) => {
      const order = action.payload;
      state.calculatedOrder = order;
      state.listLoading = false;
      state.error = null;
    },
    invoiceInfoFetched: (state, action) => {
      const invoiceInfo = action.payload;
      state.invoiceInfo = invoiceInfo;
      state.listLoading = false;
      state.error = null;
    },
    stationCultivationInfoFetched: (state, action) => {
      const stationCultivationInfo = action.payload;
      state.stationCultivationInfo = stationCultivationInfo;
      state.listLoading = false;
      state.error = null;
    },
    stationCultivationInfoReset: (state, action) => {
      state.stationCultivationInfo = null;
      state.listLoading = false;
      state.error = null;
    },
    resetOrder: (state, action) => {
      state.calculatedOrder = null;
    },
    resetDataStateDone: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.stationAvailableDiseaseModels = undefined;
      state.stationDiseasesCultivations = undefined;
      state.stationInsectOccurrencesCultivations = undefined;
      state.stationCultivationInsectOccurrences = undefined;
      state.stationForecast = undefined;
      state.stationAvailableSensors = undefined;
    },
    resetSensorsState: (state, action) => {
      state.stationAvailableSensors = undefined;
    },
    resetVirtualSensorsState: (state, action) => {
      state.virtualStationAvailableSensors = undefined;
    },
    resetStationAvailableDiseaseModels: (state, action) => {
      state.stationAvailableDiseaseModels = undefined;
    },
    resetStationCultivationInsectOccurrences: (state, action) => {
      state.stationCultivationInsectOccurrences = undefined;
    },
    resetStationCultivationInsectModels: (state, action) => {
      state.stationCultivationInsectModels = undefined;
    },
    subscriptionsFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.userSubscriptions = action.payload;
    },
    subscriptionsOrdersFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.userSubscriptionsOrders = action.payload;
    },
    userSettingsFetched: (state, action) => {
      state.error = null;
      state.listLoading = false;
      const userSettings = action.payload;
      state.userSettings = userSettings;
    },
    discountCodeInfoFetched: (state, action) => {
      state.error = null;
      state.listLoading = false;
      const discountCodeInfo = action.payload;
      state.discountCodeInfo = discountCodeInfo;
    },
    discountCodeInfoReset: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.discountCodeInfo = null;
    },
    affiliateEarningsFetched: (state, action) => {
      state.error = null;
      state.listLoading = false;
      const affiliateEarnings = action.payload;
      state.affiliateEarnings = affiliateEarnings;
    }
  },

});
