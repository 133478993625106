import React from 'react'
import { Button, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import WbSunny from '@material-ui/icons/WbSunny';
import BarChartIcon from '@material-ui/icons/BarChart';
import EmojiNature from '@material-ui/icons/EmojiNature';
import LocalFloristIcon from "@material-ui/icons/LocalFlorist";
import CenterFocusStrong from '@material-ui/icons/CenterFocusStrong';
import { useIntl } from 'react-intl';
import  { useLang } from '../../../../_metronic/i18n/Metronici18n';

export default function FastNavigationBar({ stationId, section, history }) {

    const goTo = url => {
        history.push(url);
    }

    const langName = useLang();
    const intl = useIntl();
    const { messages: msg } = intl;

    return (
        <div className="pull-right text-right ml-5 pl-5" style={{borderLeftWidth: 1, borderLeftColor: "#ddd", borderLeftStyle: 'solid'}}>
        <OverlayTrigger
            placement={`top`}
            overlay={
                <Tooltip id={`tooltip-${`top`}`}>
                    {msg.SUBSCRIPTION_STATION_DATA}
                </Tooltip>
            }
        >
            <Button className="btn-success ml-1" onClick={() => goTo(`/locations/${stationId}/sensors`)}><BarChartIcon /></Button>
        </OverlayTrigger>
        <OverlayTrigger
            placement={`top`}
            overlay={
                <Tooltip id={`tooltip-${`top`}`}>
                    {msg.SUBSCRIPTION_DISEASES}
                </Tooltip>
            }
        >
            <Button className="btn-primary ml-1" onClick={() => goTo(`/locations/${stationId}/diseases/cultivations`)}><LocalFloristIcon /></Button>
        </OverlayTrigger>
        <OverlayTrigger
            placement={`top`}
            overlay={
                <Tooltip id={`tooltip-${`top`}`}>
                    {msg.SUBSCRIPTION_INSECT_MODELS}
                </Tooltip>
            }
        >
            <Button className="btn-info ml-1" style={{backgroundColor: '#6035b7'}} onClick={() => goTo(`/locations/${stationId}/insectModels/cultivations`)}><EmojiNature /></Button>
        </OverlayTrigger>
        <OverlayTrigger
            placement={`top`}
            overlay={
                <Tooltip id={`tooltip-${`top`}`}>
                    {msg.SUBSCRIPTION_INSECT_OCCURENCES}
                </Tooltip>
            }
        >
            <Button className="btn-info ml-1" onClick={() => goTo(`/locations/${stationId}/insectOccurrences/cultivations`)}><CenterFocusStrong /></Button>
        </OverlayTrigger>
        <OverlayTrigger
            placement={`top`}
            overlay={
                <Tooltip id={`tooltip-${`top`}`}>
                    {msg.SUBSCRIPTION_FORECAST}
                </Tooltip>
            }
        >
            <Button className="btn-warning ml-1" onClick={() => goTo(`/locations/${stationId}/forecast`)}><WbSunny /></Button>
        </OverlayTrigger>
        </div>
    )
}