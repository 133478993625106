import React, { Suspense, lazy, useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import SignUpMapPage from "./modules/SignUpPartners/SignUpMap";

export default function SignUpMapRoutesPage({companyName}) {
  return (
      <SignUpMapPage companyName={companyName} />
  );
}
