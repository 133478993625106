/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useSubheader } from "../../../../_metronic/layout";
import { useIntl } from "react-intl";
import languages from "../../Shared/Enums/languages";
import { useLang } from "../../../../_metronic/i18n/Metronici18n";
import axios from 'axios';
import { toast } from 'react-toastify';


export default function CalculatorFertilizer({
  history
}) {
  const langName = useLang();
  const intl = useIntl();
  const { messages: msg } = intl;
  const lang = languages[langName];

  const backToLocations = () => {
    history.push(`/locations`);
  };

  const axiosConnection = axios.create({
    baseURL: 'https://api.farmcloud.eu/api/v1/calculators/calculate-fertilizer-needs',
    headers: {'Berear': `${sessionStorage.getItem('msal.09354d56-898b-48cc-90c9-4c1021d6f764.idtoken')}`}
  });

  const cultivations = [
    ['Cultivation.WinterWheat', 'Pszenica ozima', 'Winter wheat'],
    ['Cultivation.WinterBarley', 'Jęczmień ozimy', 'Winter barley'],
    ['Cultivation.WinterRye', 'Żyto ozime', 'Winter rye'],
    ['Cultivation.WinterTriticale', 'Przenżyto ozime', 'Winter triticale'],
    ['Cultivation.Oat', 'Owies', 'Oat'],
    ['Cultivation.Corn', 'Kukurydza', 'Corn'],
    ['Cultivation.Pea', 'Groch', 'Pea'],
    ['Cultivation.BlueLupine', 'Łubin wąskolistny', 'NBlue lupine'],
    ['Cultivation.WinterRapeseed', 'Rzepak ozimy', 'Winter rapessed'],
    ['Cultivation.Potato', 'Ziemniak', 'Potato'],
    ['Cultivation.WhiteBeet', 'Burak cukrowy', 'White beet'],
    ['Cultivation.FodderBeet', 'Burak pastewny', 'Fodder beet'],
    ['Cultivation.RedClover', 'Koniczyna czerwona', 'Red clover'],
    ['Cultivation.Lucerne', 'Lucerna', 'Lucerne'],
    ['Cultivation.Beetroot', 'Burak ćwikłowy', 'Beetroot'],
    ['Cultivation.Onion', 'Cebula', 'Onion'],
    ['Cultivation.Beans', 'Fasola', 'Beans'],
    ['Cultivation.Cauliflower', 'Kalafior', 'Cauliflower'],
    ['Cultivation.BrusselsSprouts', 'Kapusta brukselska', 'Brussels sprouts'],
    // ['Cultivation.CabbageWhite', 'Kapusta głowiasta biała', 'Cabbage white'],
    ['Cultivation.Carrot', 'Marchew', 'Carrot'],
    ['Cultivation.Cucumber', 'Ogórek', 'Cucumber'],
    ['Cultivation.Parsley', 'Pietruszka', 'Parsley'],
    ['Cultivation.Tomato', 'Pomidor', 'Tomato'],
    ['Cultivation.Leek', 'Por', 'Leek'],
    ['Cultivation.Radish', 'Rzodkiewka', 'Radish'],
    ['Cultivation.Lettuce', 'Sałata', 'Lettuce'],
    ['Cultivation.Celery', 'Seler', 'Celery'],
    ['Cultivation.Spinach', 'Szpinak', 'Spinach']
  ]

  const manures = [
   ['None', 'Brak', 'None'],
   ['Manure', 'Obornik', 'Manure'],
   ['BirdDroppings', 'Pomiot ptasi', 'Bird droppings'],
   ['Slurry', 'Gnojowica', 'Slurry'],
   ['LiquidManureCattle', 'Gnojówka (bydło)', 'Liquid manure cattle'],
   ['LiquidManurePig', 'Gnojówka (trzoda)', 'Liquid manure pig']
  ]

  const soilCat = [
    ['VeryLight', 'Bardzo lekka', 'Very light'],
    ['Light', 'Lekka', 'Light'],
    ['Average', 'Średnia', 'Average'],
    ['Heavy', 'Ciężka', 'Heavy']
  ]

  const rain = [
    ['VeryDry', 'Bardzo suchy', 'Very dry'],
    ['Dry', 'Suchy', 'Dry'],
    ['Normal', 'Opad w normie', 'Normal'],
    ['Wet', 'Mokry', 'Wet'],
    ['VeryWet', 'Bardzo mokry', 'Very wet']
  ]

  const limings = [
    ['Redundant', 'Zbędne', 'Redundant'],
    ['Moderated', 'Ograniczone', 'Moderated'],
    ['Advisable', 'Wskazane', 'Advisable'],
    ['Needed', 'Potrzebne', 'Needed'],
    ['Necessary', 'Konieczne', 'Necessary']
  ];

  const warzywnicze = [
    'Cultivation.Beetroot', 
    'Cultivation.Onion',
    'Cultivation.Beans',
    'Cultivation.Cauliflower',
    'Cultivation.CabbageWhite',
    'Cultivation.BrusselsSprouts',
    'Cultivation.Carrot',
    'Cultivation.Cucumber',
    'Cultivation.Parsley',
    'Cultivation.Tomato',
    'Cultivation.Leek',
    'Cultivation.Radish',
    'Cultivation.Lettuce',
    'Cultivation.Celery',
    'Cultivation.Asparagus',
    'Cultivation.Spinach'
  ]

  const [cropAmount, setCropAmount] = useState('0');
  const [foreCrop, setForeCrop] = useState(cultivations[0][0]);
  const [crop, setCrop] = useState(cultivations[0][0]);
  const [calculationType, setCalculationType] = useState("Agriculture");
  const [cropManureType, setCropManureType] = useState(manures[0][0]);
  const [foreCropAmount, setForeCropAmount] = useState('0');
  const [foreCropManureType, setForeCropManureType] = useState(manures[0][0]);
  const [foreCropRemainsPlowed, setForeCropRemainsPlowed] = useState(false);
  const [catchCropUsed, setCatchCropUsed] = useState(false);
  const [catchCrop, setCatchCrop] = useState(cultivations[0][0]);
  const [catchCropPlowed, setCatchCropPlowed] = useState(false);
  const [argonomicCategory, setArgonomicCategory] = useState(soilCat[0][0]);
  const [rainfallDuringYear, setRainfallDuringYear] = useState(rain[0][0]);
  const [cropManureAmount, setCropManureAmount] = useState('0');
  const [foreCropManureAmount, setForeCropManureAmount] = useState('0');
  const [results, setResults] = useState(null);
  const [soilMineralNitrogen, setSoilMineralNitrogen] = useState('0');
  const [soilOrganicNitrogen, setSoilOrganicNitrogen] = useState('0');
  const [isMineralNitrogen, setIsMineralNitrogen] = useState(false);
  const [soilPhosphorus, setSoilPhosphorus] = useState('0');
  const [soilPotassium, setSoilPotassium] = useState('0');
  const [soilMagnesium, setSoilMagnesium] = useState('0');
  const [liming, setLiming] = useState(limings[0][0]);


  const calculate = async() => {
    setResults(null);
    const res = await axiosConnection.post('', {
      cropAmount,
      foreCrop,
      crop,
      calculationType,
      cropManureType,
      foreCropAmount,
      foreCropManureType,
      foreCropRemainsPlowed,
      catchCropUsed,
      catchCrop,
      catchCropPlowed,
      argonomicCategory,
      rainfallDuringYear,
      cropManureAmount,
      foreCropManureAmount,
      soilAnalysis: {
        isMineralNitrogen,
        liming,
        soilMagnesium,
        soilMineralNitrogen,
        soilOrganicNitrogen,
        soilPhosphorus,
        soilPotassium
      }
    }).catch(function(error) {
      if (error.response.status === 500) 
      {
        toast.error(`Nieprawidłowo wypełnione pola`);
      }
    });
    if (res?.data)
    setResults(res.data);
  }

  useEffect(() => {
    warzywnicze.includes(crop) ? setCalculationType("Vegetables") : setCalculationType("Agriculture");
  }, [crop]);

  return (
    <Card>
      <CardHeader title={msg["ASIDE.MENU.FERTILIZER_CALCULATOR"]}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToLocations}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            {msg.MENU_RETURN}
          </button>
          {`  `}

        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div>
          {msg.FERTILIZER_DESCRIPTION} 
          <a href={msg.FERTILIZER_PDF} target="_blank">
            {msg.CALCULATORS_HERE}
          </a>
        </div>
        <br />
        <div className="mt-5 text-center">
          <h6 className="text-left">{msg.FIELD_INFO}</h6><hr/>
          <div className="row mb-5">
            <div className="col-md-6 text-left">
              <label>{msg.CULTIVATION}</label>
              <select className="form-control" value={crop} onChange={e=>setCrop(e.target.value)}>
                {
                  cultivations
                  .slice()
                  .sort((a, b) => a[lang + 1].localeCompare(b[lang + 1]))
                  .map((c, key) => {
                    return (<option key={key} value={c[0]}>{c[lang+1]}</option>
                  )})
                }
              </select>
            </div>
            <div className="col-md-6 text-left">
                <label>{msg.CULTIVATION_CROP}</label>
                <input type="text" name="cropAmount" className="form-control" value={cropAmount} onChange={e=> setCropAmount(e.target.value)} />
            </div>
            <div className="col-md-6 text-left mt-5">
              <label>{msg.MANURE}</label>
              <select className="form-control" value={cropManureType} onChange={e=>setCropManureType(e.target.value)}>
                {
                  manures.map((m, key) => {
                    return (<option key={key} value={m[0]}>{m[lang+1]}</option>
                  )})
                }
              </select>
            </div>
            {cropManureType != "None" &&
            <div className="col-md-6 text-left mt-5">
              <label>{msg.CROP_MANURE_AMOUNT}</label>
              <input type="text" className="form-control" value={cropManureAmount} onChange={e=> setCropManureAmount(e.target.value)} />
            </div>
            }
            <div className="col-md-6 text-left mt-5">
              <label>{msg.FORE_CROP}</label>
              <select className="form-control" value={foreCrop} onChange={e=>setForeCrop(e.target.value)}>
                {
                  cultivations
                  .slice()
                  .sort((a, b) => a[lang + 1].localeCompare(b[lang + 1]))
                  .map((c, key) => {
                    return (<option key={key} value={c[0]}>{c[lang+1]}</option>
                  )})
                }
              </select>
            </div>
            <div className="col-md-6 text-left mt-5">
                <label>{msg.CULTIVATION_CROP_AMOUNT}</label>
                <input type="text" className="form-control" value={foreCropAmount} onChange={e=> setForeCropAmount(e.target.value)} />
            </div>
            <div className="col-md-6 text-left mt-5">
              <label>{msg.MANURE_FORE_CROP}</label>
              <select className="form-control" value={foreCropManureType} onChange={e=>setForeCropManureType(e.target.value)}>
                {
                  manures.map((m, key) => {
                    return (<option key={key} value={m[0]}>{m[lang+1]}</option>
                  )})
                }
              </select>
            </div>
            {foreCropManureType != "None" &&
            <div className="col-md-6 text-left mt-5">
              <label>{msg.FORE_CROP_MANURE_AMOUNT}</label>
              <input type="text" className="form-control" value={foreCropManureAmount} onChange={e=> setForeCropManureAmount(e.target.value)} />
            </div>
            }
            <div className="col-md-6 text-left mt-5">
                <label>{msg.FORE_CROP_REMAINS_PLOWED}</label>
                <select className="form-control" value={foreCropRemainsPlowed} onChange={e=>setForeCropRemainsPlowed(e.target.value)}>
                  <option value={true}>{msg.YES}</option>
                  <option value={false}>{msg.NO}</option>
              </select>
            </div>
            <div className="col-md-6 text-left mt-5">
            <label>{msg.CATCH_CROP}</label>
                <select className="form-control" value={catchCropUsed} onChange={e=>setCatchCropUsed(e.target.value)}>
                  <option value={true}>{msg.YES}</option>
                  <option value={false}>{msg.NO}</option>
              </select>
            </div>
          {catchCropUsed == "true" && <>
            <div className="col-md-6 text-left mt-5">
                <label>{msg.CATCH_CROP}</label>
                <select className="form-control" value={catchCrop} onChange={e=>setCatchCrop(e.target.value)}>
                {
                  cultivations.map((c, key) => {
                    return (<option key={key} value={c[0]}>{c[lang+1]}</option>
                  )})
                }
              </select>
            </div>
            <div className="col-md-6 text-left mt-5">
            <label>{msg.CATCH_CROP_PLOWED}</label>
                <select className="form-control" value={catchCropPlowed} onChange={e=>setCatchCropPlowed(e.target.value)}>
                  <option value={true}>{msg.YES}</option>
                  <option value={false}>{msg.NO}</option>
              </select>
            </div>
          </>}

            <div className="col-md-6 text-left mt-5">
                <label>{msg.AGRONOMIC_CATEGORY}</label>
                <select className="form-control" value={argonomicCategory} onChange={e=>setArgonomicCategory(e.target.value)}>
                {
                  soilCat.map((m, key) => {
                    return (<option key={key} value={m[0]}>{m[lang+1]}</option>
                  )})
                }
              </select>
            </div>
            <div className="col-md-6 text-left mt-5">
              <label>{msg.RAINFALL}</label>
              <select className="form-control" value={rainfallDuringYear} onChange={e=>setRainfallDuringYear(e.target.value)}>
                {
                  rain.map((m, key) => {
                    return (<option key={key} value={m[0]}>{m[lang+1]}</option>
                  )})
                }
              </select>
            </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-left mt-5">
                <h6>{msg.SOIL_ANALYSIS_RESULTS}</h6><hr/>
             </div>
            </div>
            <div className="row">
              <div className="col-md-6 text-left mt-5">
                <label>{msg.SOIL_ORGANIC_NITROGEN}</label>
                <input type="text" className="form-control" value={soilOrganicNitrogen} onChange={e=> setSoilOrganicNitrogen(e.target.value)} />
              </div>
              <div className="col-md-6 text-left mt-5">
                <label>{msg.SOIL_NITROGEN_ANALYSIS}</label>
                <select className="form-control" value={isMineralNitrogen} onChange={e=>setIsMineralNitrogen(e.target.value)}>
                  <option value={true}>{msg.YES}</option>
                  <option value={false}>{msg.NO}</option>
              </select>
            </div>
            {isMineralNitrogen == "true" && <div className="col-md-6 text-left mt-5">
                <label>
                  {msg.SOIL_NITROGEN}
                  {warzywnicze.includes(crop) && <> [mg/dm3]</>}
                  {!(warzywnicze.includes(crop)) && <> [mg/100g]</>}
                </label>
                <input type="text" className="form-control" value={soilMineralNitrogen} onChange={e=> setSoilMineralNitrogen(e.target.value)} />
            </div>}
            
            <div className="col-md-6 text-left mt-5">
                <label>
                  {msg.SOIL_PHOSPHORUS}
                  {warzywnicze.includes(crop) && <> [mg/dm3]</>}
                  {!(warzywnicze.includes(crop)) && <> [mg/100g]</>}
                </label>
                <input type="text" className="form-control" value={soilPhosphorus} onChange={e=> setSoilPhosphorus(e.target.value)} />
              </div>
              <div className="col-md-6 text-left mt-5">
                <label>
                  {msg.SOIL_POTASSIUM}
                  {warzywnicze.includes(crop) && <> [mg/dm3]</>}
                  {!(warzywnicze.includes(crop)) && <> [mg/100g]</>}
                </label>
                <input type="text" className="form-control" value={soilPotassium} onChange={e=> setSoilPotassium(e.target.value)} />
              </div>
              <div className="col-md-6 text-left mt-5">
                <label>
                  {msg.SOIL_MAGNESIUM}
                  {warzywnicze.includes(crop) && <> [mg/dm3]</>}
                  {!(warzywnicze.includes(crop)) && <> [mg/100g]</>}
                </label>
                <input type="text" className="form-control" value={soilMagnesium} onChange={e=> setSoilMagnesium(e.target.value)} />
              </div>
              <div className="col-md-6 text-left mt-5">
              <label>{msg.LIMING}</label>
              <select className="form-control" value={liming} onChange={e=>setLiming(e.target.value)}>
                {
                  limings.map((m, key) => {
                    return (<option key={key} value={m[0]}>{m[lang+1]}</option>
                  )})
                }
              </select>
            </div>
              </div>
            <div className="row">
              <div className="col-md-12 mt-5">
                <input type="button" value={msg.CALCULATORS_BUTTON}  className="btn btn-primary" onClick={() => calculate()} />
              </div>
            </div>
            {results && <div className="row text-left mt-5">
                <div className="col-md-12 mt-2"><h6>{msg.RESULTS}</h6><hr/>{msg.DOSE}:</div>
                <div className="col-md-12 mt-2">
                  {msg.NITROGEN} = <strong>{results.nitrogenPureNeeded}</strong> kg/ha
                </div>
                <div className="col-md-12 mt-2">
                  {msg.PHOSPHORUS} = <strong>{results.phosphorusPureNeeded}</strong> kg/ha
                </div>
                <div className="col-md-12 mt-2">
                  {msg.POTASSIUM} = <strong>{results.potassiumPureNeeded}</strong> kg/ha
                </div>
                <div className="col-md-12 mt-2">
                  {msg.MAGNESIUM} = <strong>{results.magnesiumPureNeeded} </strong>kg/ha
                </div>
                <div className="col-md-12 mt-2">
                  {msg.CALCIUM} = <strong>{results.calciumCaONeeded}</strong> kg/ha
                </div>
            </div>}
        </div>
      </CardBody>
    </Card>
  );
}
