import React, { useEffect, useState, useRef } from "react";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import * as afActions from "./affiliateActions";
import { sortCaret } from "../../../../_metronic/_helpers";
import BootstrapTable from 'react-bootstrap-table-next';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useIntl } from "react-intl";
import { registerLocale } from "react-datepicker";
import {Badge} from 'react-bootstrap';
import timeZoneHelper from  "../../Shared/Helpers/timezoneHelper";

export default function AgentPanelPageSubscriptions({
  history,
  match: {
    params: { userId },
  }
}) {

  registerLocale('currentDatePickerLang', {
    localize: {
      month: n => msg.MONTHS[n],
      day: n => msg.DAYS[n]
    },
    options: {
      weekStartsOn: 1
      /* Monday */
      ,
      firstWeekContainsDate: 2
    },
    formatLong: {}
  });

  const dispatch = useDispatch();
  const intl = useIntl();
  const { messages: msg } = intl;
  const [title, setTitle] = useState(msg["AGENT_USER_SUBSCRIPTIONS_LIST"]);
  
  const { subscriptions } = useSelector(
    (state) => ({
      subscriptions: state.affiliate.userSubscriptions,
      error: state.subscriptions.error
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(afActions.fetchUserSubscriptions(userId));
  }, [dispatch]);

  let goToPanel = () => {
    history.push(`/agentPanel`);
  }

  useEffect(() => {
    setTitle(`${msg["AGENT_USER_SUBSCRIPTIONS_LIST"]} ${subscriptions.userEmail}`);
  }, [subscriptions]);

  const columns = [

    {
      dataField: 'stationId',
      text: 'Stacja',
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row, rowIndex, extraData) => (
        subscriptions.stations && subscriptions.stations.find(t => t.id === row.stationId)?.name
      )
    },
    {
      dataField: 'startDate',
      text: 'Od',
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => {
        let parsedDate = timeZoneHelper.parseCosmosDateString(row.startDate, row.startDateTimeZone);
        return timeZoneHelper.toDateTimeString(parsedDate);
      }
    },
    {
      dataField: 'expirationDate',
      text: 'Do',
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => {
        let parsedDate = timeZoneHelper.parseCosmosDateString(row.expirationDate, row.expirationDateTimeZone);
        return timeZoneHelper.toDateTimeString(parsedDate);
      }
    },
    {
      dataField: 'id',
      text: 'Rodzaje subskrypcji',
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => {
        return (<>
          {row?.subscriptionForDisease && <Badge variant="danger mr-2 mb-1 mt-1">{msg["SUBSCRIPTION_DISEASES"]}</Badge>}
          {row?.subscriptionForInsects && <Badge variant="warning mr-2 mb-1 mt-1">{msg["SUBSCRIPTION_INSECT_MODELS"]}</Badge>}
          {row?.subscriptionForInsectOccurrences && <Badge variant="info mr-2 mb-1 mt-1">{msg["SUBSCRIPTION_INSECT_OCCURENCES"]}</Badge>}
          {row?.subscriptionForForecast && <Badge variant="primary mr-2 mb-1 mt-1">{msg["SUBSCRIPTION_FORECAST"]}</Badge>}
          {row?.subscriptionForStationData && <Badge variant="success mr-2 mb-1 mt-1">{msg["SUBSCRIPTION_STATION_DATA"]}</Badge>}
        </>
        )
      }
    },
    {
      dataField: 'cultivationId',
      text: 'Uprawa',
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        <>
          {((row.subscriptionForDisease === true || row.subscriptionForInsects === true || row.subscriptionForInsectOccurrences === true) && !row.allCultivations) && <Badge variant="outline-primary mr-2 mb-1 mt-1">{subscriptions.cultivations.find(cultivation => cultivation.id == row.cultivationId)?.names[0]}</Badge>}
        </>
      )
    }
  ];

  return (
    <Card>
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={()=>history.push('/agentPanel')}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            {msg.MENU_RETURN}
          </button>
          <button
            type="button"
            onClick={()=>{history.push(`/agentPanel/subscriptions/${userId}/new`)}}
            className="btn btn-danger ml-2"
          >
            {msg["AGENT_CREATE_SUBSCRIPTION"]}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>

          {/* <Button className="btn-primary btn-sm ml-4" onClick={() => {fetchInfo()}}>
            {'OK'}
          </Button> */}
        <div className="mt-5">
          {subscriptions && subscriptions?.subscriptions?.length>0 &&
          <BootstrapTable keyField='id' data={subscriptions.subscriptions || []} columns={columns}
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
            sort={{dataField: 'distance', order: 'asc'}}
          />}
          {subscriptions?.subscriptions?.length == 0 && <div className="text-center mb-5 mt-5">
          <h5>{msg["AGENT_SUBSCRIPTIONS_LIST_EMPTY"]}</h5>
        </div>}
        </div>
      </CardBody>
    </Card>
  );
}
