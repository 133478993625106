import React from 'react'
import { Button } from 'react-bootstrap';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import GetApp from '@material-ui/icons/GetApp';
import timezoneHelper from '../Helpers/timezoneHelper';
import { useLang } from "../../../../_metronic/i18n/Metronici18n";
import { useIntl } from "react-intl";
import languages from "../Enums/languages";

export default function ExportInsectOccurencesAsXLS({ 
    insect,
    stationName,
    cultivationName,
    labels,
    chartOccurrencesData
 }) {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const langName = useLang();
    const lang = languages[langName];
    const intl = useIntl();
    const { messages: msg } = intl;

    const exportToXLS = async (
            insect,
            stationName,
            cultivationName,
            labels,
            chartOccurrencesData
        ) => {

        const fileName = `${insect.names[lang]}, ${stationName}`;
        let wb = {};
        let ws;
        let SheetNames = [];
        let Sheets = {};
        let i = 0;
        
            let rows = [];
            let row = [];
            let SheetName = `${insect.names[lang]}`;

            let SheetNameUnique = `${SheetName}`;
            SheetNames.push(SheetNameUnique.substring(0, 31));

            row = [`${SheetName}`, '%'];
            rows.push(row);
            console.log(labels);
            console.log(chartOccurrencesData);
            row = [];
            for (const label of labels) {
                // let parsedDate = timezoneHelper.parseCosmosDateString(pesslData.date, pesslData.dateTimeZone);
                // row = [timezoneHelper.toDateTimeString(parsedDate)];
                rows.push([`${label}`, chartOccurrencesData[i]]);
                i++;
            }

            var wscols = [
                { width: 30 },
                { width: 15 },
                { width: 15 },
                { width: 15 },
                { width: 15 },
                { width: 15 },
                { width: 15 },
                { width: 15 },
                { width: 15 }
            ];



            ws = XLSX.utils.aoa_to_sheet(rows);
            ws['!cols'] = wscols;

            Sheets[SheetNameUnique.substring(0, 31)] = ws;


        wb = { Sheets, SheetNames };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', Props: { Author: "FarmSmart.pl" } });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${fileName}${fileExtension}`);
    }

    return (
        <div className="pull-right text-right mt-2">
            <Button variant="outlined" role="button" style={{ fontSize: 11, fontWeight: 333, color: "#222" }} className="btn-sm btn-light" onClick={(e) => exportToXLS(
                insect,
                stationName,
                cultivationName,
                labels,
                chartOccurrencesData
            )}><GetApp /> xls</Button>
        </div>
    )
}