import React, { useEffect } from "react";
import * as subscriptionsActions from "./subscriptionsActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Button } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useLang } from "../../../../_metronic/i18n/Metronici18n";
import languages from "../../Shared/Enums/languages";
import axios from 'axios';

export default function UserSubscriptionsOrders({
  history
}) {
  const intl = useIntl();
  const { messages: msg } = intl;
  const langName = useLang();
  const lang = languages[langName];

  const { userSubscriptionsOrders, cultivationsList, error } = useSelector(
    (state) => ({
      userSubscriptionsOrders: state.subscriptions.userSubscriptionsOrders,
      cultivationsList: state.subscriptions.userCultivationsList,
      error: state.subscriptions.error
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(subscriptionsActions.fetchSubscriptionsOrders());
    dispatch(subscriptionsActions.fetchUserCultivationsList())
  }, [dispatch]);

  const downloadInvoice = (orderId, filename) => {
    axios.get(`/orders/downloadInvoice/${orderId}`,  {responseType: 'blob'} ).then(response => {
      //  window.open(URL.createObjectURL(response.data));
       const url = window.URL.createObjectURL(new Blob([response.data]));
       const link = document.createElement('a');
       link.href = url;
       link.setAttribute('download', `${filename}.pdf`);
       document.body.appendChild(link);
       link.click();
    });
  }

  return (<>
    <Card>
      <CardHeader title={msg.ORDERS_HISTORY}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={()=>history.push('/subscriptions')}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            {msg.MENU_RETURN}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
    
        {userSubscriptionsOrders && userSubscriptionsOrders.length == 0 && <div className="text-center mb-5 mt-5">
          <h5>{msg.ORDERS_HISTORY_EMPTY}</h5>
        </div>}
        <table className="table">
          <thead>
            <th>{msg.BUY_DATE}</th>
            <th>{msg.ORDER_DETAILS}</th>
            <th>{msg.ORDER_SUM}</th>
            <th>{msg.FILES_TO_DOWNLOAD}</th>
          </thead>
          <tbody>
            {userSubscriptionsOrders && cultivationsList.length>0 && userSubscriptionsOrders.map(orderEntity => {

              return <tr>
                <td>{orderEntity.date.replace('T', ' ').replace('Z','').substr(0,19)}</td>
                <td>{orderEntity.orderDetails.map(order => {

                return <div>{`${msg.STATION_UPPER}: ${order.stationName} (${msg.SUBSCRIPTION_STATION_DATA}, ${msg.SUBSCRIPTION_FORECAST})${order.monitoringOption ? cultivationsList.length>0&&` ${msg.CULTIVATION}: ${cultivationsList.find(c=>c.id === order.cultivationId).names[lang]} (${msg.SUBSCRIPTION_DISEASES}, ${msg.SUBSCRIPTION_INSECT_MODELS}, ${msg.SUBSCRIPTION_INSECT_OCCURENCES})`: ''} - ${msg.DATE_FROM}: ${order.dateFrom.replace('T', ' ').replace('Z','').substr(0,19)}, ${msg.SUBSCRIPTION_PERIOD}: ${order.monthsCount} ${msg.TO} 23:59:59.`}</div>
})}</td>
                <td>{Math.round(orderEntity.orderSum/100,2)} PLN</td>
                <td>{orderEntity.pdfCreated ? <Button onClick={()=>downloadInvoice(orderEntity.id, orderEntity.id)}>{orderEntity.isInvoice ? msg.DOWNLOAD_INVOICE : msg.DOWNLOAD_BILL}</Button>: '-'}</td>
              </tr>
            })}
          </tbody>
        </table>
      </CardBody>
    </Card></>
  )
}