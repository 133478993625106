/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import { Checkbox } from '@material-ui/core';
import LocationOn from '@material-ui/icons/LocationOn';
import axios from 'axios';
import GoogleMapReact from 'google-map-react';
import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLang } from "../../../_metronic/i18n/Metronici18n";
import { useSubheader } from "../../../_metronic/layout";
import googleMap from "../Shared/PublicKeys/googleMapKey";
import reCaptchaKey from "../Shared/PublicKeys/reCaptcha";
import * as Yup from 'yup';
import { toast } from 'react-toastify';

var marker;
var lines = [];

export default function SignUpMapPage({
  history,
  companyName
}) {
  const subheader = useSubheader();
  const dispatch = useDispatch();
  const langName = useLang();
  const intl = useIntl();
  const { messages: msg } = intl;
  const recaptcha = useRef();
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [stations, setStations] = useState(null);
  const [cultivations, setCultivations] = useState(null);
  const [cultivation, setCultivation] = useState(null);
  const [cultivation2, setCultivation2] = useState(null);
  const [cultivation3, setCultivation3] = useState(null);
  const [cultivation4, setCultivation4] = useState(null);
  const [cultivation5, setCultivation5] = useState(null);
  const [selectedStation, setSelectedStation] = useState(null);
  const [postCode, setPostCode] = useState('');
  const [postCodeCoords, setPostcodeCoords] = useState({lat: null, lng: null});
  const [promoCode, setPromoCode] = useState('');
  const [nearestDistance, setNearestDistance] = useState('');
  const [nearestDistanceDescription, setNearestDistanceDescription] = useState('');
  const [nearestDistanceColor, setNearestDistanceColor] = useState('transparent');
  const [userMobile, setUserMobile] = useState('');

  const [emailErrorMsg, setEmailErrorMsg] = useState('');
  const [passwordErrorMsg, setPasswordErrorMsg] = useState('');
  const [pzuErrorMsg, setPzuErrorMsg] = useState('');
  const [mobileErrorMsg, setMobileErrorMsg] = useState('');
  const [postCodeOrLocErrorMsg, setPostCodeOrLocErrorMsg] = useState('');

  const formRef = useRef();
  const mapRef = useRef();
  const cultivationsRef = useRef();
  const postCodeRef = useRef();

  function createEmailValidation() {
    return Yup.string()
      .required("To pole jest wymagane")
      .max(120, "Max. 120 znaków)")
      .email("Nieprawidłowy adres email");
  }
  function createPasswordValidation(invalidPasswordMsg) {
    return Yup.string()
      .required("To pole jest wymagane")
      .min(8, "Zbyt krótkie hasło (min. 8 znaków)")
      .max(100, "Zbyt długie hasło (max. 100 znaków)")
      .matches(/[A-Z]/, "Minimum 1 wielkia litera")
      .matches(/[a-z]/, "Minimum 1 mała litera")
      .matches(/[0-9]/, "Musi zawierać min. 1 cyfrę")
      .test('no-whitespace', "Nie może zawierać spacji ani odstępów", value => !/\s/.test(value))
  }
  function createCustomCodeValidation() {
    return Yup.string()
      .required("To pole jest wymagane")
      .matches(/^.{7}$/, "Wprowadź 7 znaków")
      .matches(/^[A-Za-z]{3}[0-9]{4}$/, "Nieprawidłowy kod");
  }
  function createPhoneNumberValidation(invalidPhoneMsg) {
    return Yup.string()
      .required("To pole jest wymagane")
      .matches(/^\d{9}$/, "Podaj 9 cyfr");
  }
  function createPostalCodeOrCityValidation() {
    return Yup.string()
      .required("To pole jest wymagane")
      .test('is-postal-or-city', "Proszę wprowadzić właściwą wartość", value => {
        const postalCodeRegex = /^\d{2}-\d{3}$/;
        const cityRegex = /^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ ]{2,}$/;
        return postalCodeRegex.test(value) || cityRegex.test(value);
      });
  }
  const validationSchema = Yup.object().shape({
    email: createEmailValidation(),
    password: createPasswordValidation(),
    pzu: createCustomCodeValidation(),
    mobile: createPhoneNumberValidation(),
    postCode: createPostalCodeOrCityValidation(),
  });
  const updateErrorHook = (fieldName, fieldValue, errorMessageHook) => {
    validationSchema
        .validateAt(fieldName, { [fieldName]: fieldValue })
        .then(() => errorMessageHook(""))
        .catch((error) => errorMessageHook(error.message));
  };

  const handleApiLoaded = async (mapEntity, mapsEntity) => {
    setMap(mapEntity);
    setMaps(mapsEntity);
  };

  useEffect(() => {
    const instance = axios.create({
      baseURL: 'https://api.admin.farmsmart.pl/getStationAvailableForUser'
    });

    instance('').then(res => {
      const {data} = res;
      setStations(data);
    });

    const instance2 = axios.create({
      baseURL: 'https://api.admin.farmsmart.pl/getCultivationsAvailableForUser'
    });

    instance2('').then(res => {
      const {data} = res;

      setCultivations(data.sort(compare));
    });
  }, []);

  const [mapSettings, setMapSettings] = useState({
    center: {
      lat: 52.133231,
      lng: 19.596804
    },
    zoom: 6
  });

  function compare( a, b ) {
    if ( a.names[0] < b.names[0] ){
      return -1;
    }
    if ( a.names[0] > b.names[0] ){
      return 1;
    }
    return 0;
  }

  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [termsAccept, setTermsAccept] = useState(false);
  const [logoImage, setLogoImage] = useState('');
  const [token, setToken] = useState('');
  const [code, setCode] = useState('');
  const [maxPostcodeOrCityLength, setMaxPostcodeOrCityLength] = useState(40);
  const [markerPos, setMarkerPos] = useState({lat: null, lng: null});

  useEffect(()=>{
    if (!isNaN(postCode.charAt(0)) && !isNaN(postCode.charAt(1)) ) {
      setMaxPostcodeOrCityLength(6);
      if (postCode.length === 2) {
        setPostCode(`${postCode}-`);
      }
    }
    else {
      setMaxPostcodeOrCityLength(40);
    }
  }, [postCode]);

  const getPostcodeCoords = async (locationString) => {
    try {
      axios.post(`/getLocationCoords`,
        { locationString 
      }).then((res) => {
        // const {lat, lng} = res.data.results[0].geometry.location;
        const {lat, lng} = res.data;
        setPostcodeCoords({lat, lng});
        map.setCenter({lat, lng});

        if (postCode == '')
        map.setZoom(7);
        resetLines();
        putMarker(lat, lng);
        
      });
    } catch (e) {
    }
  }

  const calculateDistance = (lat1, lon1, lat2, lon2) => {    
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    }
    else {
      var radlat1 = Math.PI * lat1/180;
      var radlat2 = Math.PI * lat2/180;
      var theta = lon1-lon2;
      var radtheta = Math.PI * theta/180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180/Math.PI;
      dist = dist * 60 * 1.1515;
      dist = dist * 1.609344;
      return dist;
    }
    }
  
  const drawLines = (lat, lng, selectedStation = null) => {
    var dist = null;
    var stationId = null;
    stations.map((location)=> {
      const distance = calculateDistance(location.latitude, location.longitude, lat, lng);
      if (dist === null || distance < dist) {
        dist = distance;
        stationId = location.id;
      }
    });
    if (selectedStation != null) stationId = selectedStation;

    stations.map((location)=> {
      const distance = calculateDistance(location.latitude, location.longitude, lat, lng);
      if (distance <= 20 || location.id == stationId)
      {
        var newline = new maps.Polyline({
          path: [
              new maps.LatLng(lat, lng), 
              new maps.LatLng(location.latitude, location.longitude)
          ],
          strokeColor: stationId == location.id?"#FF0000":"#aaa",
          strokeOpacity: 1.0,
          strokeWeight: 2,
          map: map
        });
        lines.push(newline);
      }   
    });
  }
  const markNearestStation = (lat, lng) => {
    var calculatedDistance = null;
    var nearestStation = null;
    stations.map((location)=> {

      const distance = calculateDistance(location.latitude, location.longitude, lat, lng);
     
      if (distance < calculatedDistance || calculatedDistance === null) {
        calculatedDistance = distance;
        nearestStation = location;
      }
    })
    setSelectedStation(nearestStation);
    setNearestDistance(calculatedDistance);
    showStationDescription(calculatedDistance);
  }

const showStationDescription = (calculatedDistance) => {
  var description = `Odległość od zaznaczonej stacji ${Math.round(calculatedDistance, 1)} km - `;
  var distanceDescription = 'wysoka dokładność.';
  setNearestDistanceColor('#019640');
  if (calculatedDistance > 10) {
    distanceDescription = 'średnia dokładność.';
    setNearestDistanceColor('#93c21c')
  }
  
    if (calculatedDistance > 20) {
      distanceDescription = 'niska dokładność.';
      setNearestDistanceColor('lightcoral');
    }
  setNearestDistanceDescription(`${description}${distanceDescription}`)
}

  const resetLines = _ => {
    if (lines.length > 0) {
      for (let loop=0; loop<lines.length; loop++) 
      {                           
        lines[loop].setMap(null);
      }
      lines = [];
    }
  }

  const putMarker = (lat, lng) => {
    if (marker!=null)
        marker.setMap(null);
      marker = new maps.Marker({
        position: {lat, lng},
        map,
        draggable:true,
        labelInBackground: false,
        labelAnchor: new maps.Point(0, 40),
        animation: maps.Animation.DROP,
        label: {
          text: `${msg.DRAG_TO_SELECT_LOCATION}`,
          color: 'black',
          fontSize: '16px',      
          className: 'marker-position',
        },
      });
      setTimeout(() => {
        drawLines(lat, lng);
        markNearestStation(lat, lng);
      }, 1000);
      marker.addListener('drag', e => {
        let {lat, lng} = marker.getPosition().toJSON();
        setPostcodeCoords({lat, lng});
        resetLines();
        drawLines(lat, lng);
      });

      marker.addListener('dragend', e => {
        let {lat, lng} = marker.getPosition().toJSON();
        if (map.getZoom() < 10)
        map.setZoom(10);
        map.setCenter({lat, lng});
        setMarkerPos({lat, lng});
        markNearestStation(lat, lng);
      });
}

  useEffect(() => {
    document.title = "Tworzenie nowego konta";

    switch(companyName) {
      case 'Plantpress': setLogoImage('plantpress'); break;
      case 'PZU': setLogoImage('PZU'); break;
      case 'PodOslonami': setLogoImage('podoslonami'); break;
    }
  }, []);

  const postData = async (token) => {
    try {
      
      await axios.post('createAccountTemporaryForPZU', {userEmail, userPassword,  companyName, token, selectedStation, cultivation, userMobile, cultivation2, cultivation3, cultivation4, cultivation5}).then(function(response) {

        if (response.data?.err) {

          let decoded = JSON.parse(response.data.err);

          switch(decoded.message) {
            case 'Another object with the same value for property userPrincipalName already exists.': toast.error("Użytkownik z podanym adresem email już istnieje."); break;
            case 'Another object with the same value for property proxyAddresses already exists.': toast.error("Proszę podać inny adres email."); break;
            default: toast.error(decoded.message); break;
          }        
          recaptcha.current.reset();
        } else {
          // since user is redirected immidietly, toast doesnt work (alert pauses before redirection)
          // toast.success('Konto zostało utworzone.');
          alert('Konto zostało utworzone.');
          window.location.href = '/';
        }
      });
    } catch(e) {
      console.log(e);
    }
  }

  const verify = async () => {
    updateErrorHook('email', userEmail, setEmailErrorMsg);
    updateErrorHook('password', userPassword, setPasswordErrorMsg);
    updateErrorHook('pzu', code, setPzuErrorMsg);
    updateErrorHook('mobile', userMobile, setMobileErrorMsg);
    updateErrorHook('postCode', postCode, setPostCodeOrLocErrorMsg);

    const cultivationsFilledValues = [cultivation, cultivation2, cultivation3, cultivation4, cultivation5]
    .filter(c => {if (c !== null && c !== 'Wybierz uprawę z listy') {
        return c;
    }});
  
    const captchaValue = recaptcha.current.getValue();
    if (emailErrorMsg || passwordErrorMsg || pzuErrorMsg || mobileErrorMsg || !userEmail || !userPassword || !code || !userMobile) {
      toast.error("Proszę poprawnie wypełnić formularz");
      formRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    else if (postCodeOrLocErrorMsg || !postCode) {
      toast.error("Proszę wpisać kod pocztowy lub miejscowość.");
      postCodeRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    else if (selectedStation == null) {
      toast.error("Proszę wybrać stację \n(kliknij w punkt na mapie).");
      mapRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    else if (cultivationsFilledValues < 1) {
      toast.error("Proszę wybrać przynajmniej jedną uprawę.");
      cultivationsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    else if (new Set(cultivationsFilledValues).size !== cultivationsFilledValues.length) {
      toast.error("Wybrane uprawy powtarzają się.");
      cultivationsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    else if (!captchaValue) {
      toast.error("Proszę zaznaczyć pole \"Nie jestem robotem\".");
      setToken(token);
    }
    else if (!termsAccept) toast.error("Proszę zaakceptować regulamin.");
    else {
      await postData(captchaValue);
    }
  }

  let Marker = ({location, markerColor, markerSize, entity}) => {
    let {name, latitude, longitude} = location;
    return (
      <div lat={latitude} lng={longitude}>
        <LocationOn 
          onClick={()=>clickMarker(entity)} 
          style={{
            color: markerColor,
            filter: 'drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3))', 
            fontSize: `${markerSize}px`,
            marginTop: '-38px',
            marginLeft: '-16px'
          }} 
        />
        <h4>{name}</h4>
      </div>
    )
  }
  
  const clickMarker = (location) => {
    setSelectedStation(location);
    if (postCodeCoords.lat != null)
    {
      var tempDist = calculateDistance(location.latitude, location.longitude, postCodeCoords.lat, postCodeCoords.lng);
      showStationDescription(tempDist);
      resetLines();
      drawLines(postCodeCoords.lat, postCodeCoords.lng, location.id);
    }
  }

  // the primitive solution for styling below was caused by the nature of current PZU cooperation process (enforced new style that doesnt match the rest of the app, multiple temporary solutions)
  
  // needs to be outside cause of font import
  const mainStyle = {
    fontFamily: 'Inter, sans-serif',
    '@import': 'url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900;&display=swap")',
    width: "100%",
    height: "auto",
    backgroundColor: "white",
    color: "black",
  };
  const videosSection = {
    width: "100%",
    minWidth: "500px",
    display: 'flex',
    gap: '35px'
  };
  const labelStyle = {
    display: 'block', 
    marginLeft: "8px", 
    marginBottom: '2px', 
    fontSize: "12px", 
    fontWeight: "bold", 
    textShadow: "0px 0px .1px black"
  }
  const inputFieldStyle = {
    fontSize: "15px",
    padding: "0px 14px",
    margin: "2px 0px",
    width: '100%',
    borderColor: '#459BD9',
    borderRadius: "8px",
    height: "2.9em",
  }

  const StepComponent = ({step, text}) => {
    return (
      <>
        <div style={{fontSize: "24px", color: "#459BD9", margin: "40px 0 0 0"}}>
          <span style={{
            backgroundColor: "#459BD9",
            color: "white",
            fontSize: "32px",
            borderRadius: "50%",
            padding: "5px 14px",
            fontWeight: "600"}}
          >
            {step}
          </span>
          <span style={{fontWeight: "bold", marginLeft: "12px"}}>
            Krok
          </span>
          &nbsp;-&nbsp;{text}
        </div>
        <br/>
      </>
    )
  }
  const UnderlinedTitle = ({text}) => {
    return (
      <div style={{
        margin: "40px 0 15px 0", 
        fontSize: "32px", 
        fontWeight: "bold", 
        color: "#0C344D", 
        lineHeight: "39px",}}
      >
        {text}
        <br/>
        <hr style={{backgroundColor: "#459BD9"}}></hr>
      </div>
    )
  }
  const VideoContainer = ({description, link}) => {
    return (
      <div style={{width: '50%'}}>
        <h6 style={{fontSize: "16px", fontWeight: "bold", marginBottom: '10px'}}>
          {description}
        </h6>
        <iframe
          width="100%"
          height="200px"
          src={link}
          style={{borderRadius: '12px'}}
        ></iframe>
      </div>
    )
  }

  const FullVideoContainer = ({link}) => {
    return (
      <div style={{width: '100%', marginTop: "25px"}}>
        <iframe
          width="100%"
          height="400vh"
          src={link}
          style={{borderRadius: '12px'}}
        ></iframe>
      </div>
    )
  }

  const PickCultivation = ({ cultValue, cultToSet }) => {
    return (
      <div className='flex mb-3'>
        <select
          className="form-control"
          style={inputFieldStyle}
          value={cultValue}
          onChange={e => cultToSet(e.target.value)}
        >
          <option value={null}>Wybierz uprawę z listy</option>
          {cultivations && cultivations.map(cult => (
            <option value={cult.id} key={cult.id}>{cult.names[0]}</option>
          ))}
        </select>
        <div className='text-danger mt-1'></div>
      </div>
    );
  };
  const ErrorMsg = ({errorHook}) => {
    return (
      <div style={{
        color: 'red', 
        position: 'relative', 
        top: '1px', 
        left: '8px', 
        marginBottom: '5px'
      }}>
        {errorHook}
      </div>
    )
  }

  return (
    <div className="text" style={mainStyle}>
      <div style={{width: '960px', minWidth: '600px', height: 'auto', margin: '20px auto', backgroundColor: '#EEF0F8', borderRadius: "10px"}}>
      
        <div className="text" style={{width: "864px"}}>
          <img src="/media/logos/farm-smart-logo.png" style={{width: '270px', height: 'auto', margin: '35px 48px'}} />
        </div>
        
        <div style={{width: '864px', minWidth: '500px', height: 'auto', margin: '0px auto', backgroundColor: 'white', borderRadius: "10px", padding: "40px 48px"}}>
          <div className='mb-3' style={{fontSize: "36px", fontWeight: "bold"}}>
            Dzień dobry!
          </div>
          <b style={{fontSize: "20px", lineHeight: "24px"}}>
            Jesteś w formularzu rejestracyjnym systemu Farm Smart. To oferta specjalna we&nbsp;współpracy z PZU.
          </b>
          
          {/* could be refactored */}
          <FullVideoContainer link="https://www.youtube.com/embed/-Wx9Ao0AErU" />
        </div>

        <div style={{width: '864px', minWidth: '500px', height: 'auto', margin: '20px auto', padding: "0 48px"}}>
          <div className='mt-10' style={{fontSize: "20px", lineHeight: "28px"}}>
            <h3>Uzupełnij poniższy formularz w celu otrzymania dostępu do:</h3>
            <ul>
              <li>prognozy pogody z informacją o optymalnym terminie oprysku,</li>
              <li>danych pogodowych z najbliższej stacji pogodowej,</li>
              <li>kalkulatora bilansu CO2 w uprawie,</li>
              <li>managera pola.</li>
            </ul>
          </div>

          <StepComponent step={1} text="tutaj wpisz swój e-mail, ustal hasło, wpisz numer telefonu i&nbsp;podaj kod z PZU" />
          <div ref={formRef} style={{ display: 'flex', gap: '10px', marginBottom: '15px' }}>
            <div style={{ flex: 1 }}>
              <label style={labelStyle}>ADRES E-MAIL</label>
              <input type="email" 
                className="form-control" 
                placeholder={`Wpisz swój adres e-mail`} 
                style={inputFieldStyle} 
                value={userEmail} 
                onChange={e=>setUserEmail(e.target.value)}
                onBlur={e => {updateErrorHook('email', e.target.value, setEmailErrorMsg)}}
              />
              <ErrorMsg errorHook={emailErrorMsg} />
            </div>
            <div style={{ flex: 1 }}>
              <label style={labelStyle}>HASŁO</label>
              <input type="password" 
                className="form-control" 
                placeholder={`Ustaw swoje hasło`} 
                style={inputFieldStyle}  
                value={userPassword} 
                onChange={e=>setUserPassword(e.target.value)}
                onBlur={e => {updateErrorHook('password', e.target.value, setPasswordErrorMsg)}}
              />
              <ErrorMsg errorHook={passwordErrorMsg} />
            </div>
          </div>
          <div style={{ display: 'flex', gap: '10px', marginBottom: '15px' }}>
            <div style={{ flex: 1 }}>
              <label style={labelStyle}>KOD PZU</label>
              <input type="text" 
                className="form-control" 
                placeholder={`Wpisz kod w formacie PZUXXXX`} 
                style={inputFieldStyle} 
                value={code} 
                onChange={e=>setCode(e.target.value)}
                onBlur={e => {updateErrorHook('pzu', e.target.value, setPzuErrorMsg)}}
              />
              <span className="p-2">XXXX to 4 ostatnie cyfry numeru polisy</span>
              <ErrorMsg errorHook={pzuErrorMsg} />
            </div>
            <div style={{ flex: 1 }}>
              <label style={labelStyle}>NUMER TELEFONU KOMÓRKOWEGO</label>
              <input type="number" 
                className="form-control" 
                placeholder={`Wpisz 9 cyfrowy numer telefonu`} 
                style={inputFieldStyle} 
                value={userMobile} 
                onChange={e=>setUserMobile(e.target.value)}
                onBlur={e => {updateErrorHook('mobile', e.target.value, setMobileErrorMsg)}}
              />
              <ErrorMsg errorHook={mobileErrorMsg} />
            </div>
          </div>
          <StepComponent step={2} text="tutaj wpisz swój kod pocztowy (lub miejscowość) i wybierz najbliższą stację meteorologiczną" />
          <div style={{ display: 'flex', gap: '10px', marginBottom: '15px' }}>
            
            <div ref={postCodeRef} style={{ flex: 1 }}>
              <label style={labelStyle}>KOD POCZTOWY LUB MIEJSCOWOŚĆ</label>
              <input type="text" 
                className="form-control" 
                placeholder={"Wpisz kod pocztowy lub miejscowość"} 
                style={inputFieldStyle} 
                value={postCode} 
                maxLength={maxPostcodeOrCityLength}
                onChange={e=>{if (e.nativeEvent.data == '-') return false; if (e.nativeEvent.inputType === 'deleteContentBackward') setPostCode(''); else setPostCode(e.target.value)}}
                onKeyDown={e => {if (e.nativeEvent.key == 'Enter') {getPostcodeCoords(postCode); if (postCode!='') map.setZoom(10)}}}
                onBlur={e => {updateErrorHook('postCode', e.target.value, setPostCodeOrLocErrorMsg)}}
              />
              <ErrorMsg errorHook={postCodeOrLocErrorMsg} />
            </div>
            <div style={{ flex: 1 }}>
            <label style={labelStyle}>&nbsp;</label>
              <Button 
                style={{fontSize: "15px", height: "2.9em", padding: "0px 14px", width: '100%', borderRadius: "8px"}} 
                onClick={()=>{getPostcodeCoords(postCode); if (postCode!='') map.setZoom(10)}} 
              >
                {msg.SHOW_NEAREST_LOCATIONS}
              </Button></div>
          </div>
          
          <div ref={mapRef} style={{ height: '450px', borderRadius: "10px", border: "1px #459BD9 solid", overflow: "hidden"}}>
            {postCodeCoords.lat !=null && <div style={{width:'100%', height: 34, backgroundColor: nearestDistanceColor, padding:5, textAlign: 'center', color: 'white', fontSize: 16}}>{nearestDistanceDescription}</div>}
            <GoogleMapReact
              bootstrapURLKeys={{key: googleMap.publicKey}}
              defaultCenter={mapSettings.center}
              defaultZoom={mapSettings.zoom}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
              options={{'dataMode': 'regions', 'region': 'PL'}}
            >
              {stations && stations.map((station, key) => {
                return <Marker key={key} id={station.id} lat={station.latitude} lng={station.longitude} location={station.name} markerColor={station.id!==selectedStation?.id?'green':'darkblue'} markerSize={28} entity={station} />;
              })}
            </GoogleMapReact>
          </div>
          <StepComponent step={3} text="Wybierz do pięciu upraw, a otrzymasz bezpłatnie dostęp do monitoringu infekcji chorobowych na 14 dni" />
          <div ref={cultivationsRef} className="flex-grow-1">
            <label style={labelStyle}>WYBIERZ DO 5 UPRAW</label>
            <PickCultivation cultValue={cultivation} cultToSet={setCultivation}/>
            <PickCultivation cultValue={cultivation2} cultToSet={setCultivation2}/>
            <PickCultivation cultValue={cultivation3} cultToSet={setCultivation3}/>
            <PickCultivation cultValue={cultivation4} cultToSet={setCultivation4}/>
            <PickCultivation cultValue={cultivation5} cultToSet={setCultivation5}/>
          </div>
          <StepComponent step={4} text="zaznacz, że nie jesteś robotem i zaakceptuj regulamin" />
          <div style={{display: "flex", alignItems: "center", fontSize: "15px"}}>
            <div style={{position: "relative", top: "1.5px", display: 'inline-block', marginRight: "10px"}}>
              <ReCAPTCHA ref={recaptcha} sitekey={reCaptchaKey.publicKey} />
            </div>
            <div style={{height: "100%", backgroundColor: "#f9f9f9", padding:"18px 20px", border:"1px solid #d3d3d3", borderRadius: "3px"}}>
              <Checkbox
                checked={termsAccept}
                onChange={e=>setTermsAccept(e.target.checked)}
                value="termsAccept"
                style = {{transform: 'scale(1.7)', marginTop: "-2.5px", color: "#BBB"}}
              /> Akceptuję <a href="https://farmsmart.pl/regulamin-portalu/" target="_blank"><u>regulamin</u></a>
            </div>
          </div>
          <StepComponent step={5} text="kliknij w przycisk poniżej i GOTOWE!" />
          <Button 
            style={{height: 64, width: '100%', fontSize: "19px", fontWeight: "600"}} 
            className="btn"
            onClick={_=>{verify()}}
          >
            REJESTRUJĘ BEZPŁATNE KONTO FARM SMART
          </Button>

          <div style={{height: "116px", display: "flex", flexDirection: "column", justifyContent: "center", margin: "60px 0px", backgroundColor: "#F5E400", padding: "25px", borderRadius: "8px"}}>
            <div style={{fontSize: "24px", fontWeight: "bold"}}>Masz pytania?</div>
            <div style={{fontSize: "20px", lineHeight: "22px"}}>
              Zadzwoń lub napisz do naszego eksperta.<br/>Arkadiusz PLISZKA / <b>728 847 285</b> / arkadiusz.pliszka@agrosmartlab.com
            </div>
          </div>

          <UnderlinedTitle text="Zobacz prezentację systemu Farm Smart" />
          <div style={videosSection}>
            <VideoContainer description="Prezentacja systemu Farm Smart" link="https://www.youtube.com/embed/q-QbGVXv-8s" />
          </div>
          <UnderlinedTitle text="Zobacz na poniższych filmach co rolnicy mówią o Farm Smart" />
          <div style={videosSection}>
            <VideoContainer description="Producent warzyw" link="https://www.youtube.com/embed/CO81ieWGPhs" />
            <VideoContainer description="Producent owoców" link="https://www.youtube.com/embed/4uAP0cTblKc"/>
          </div>
          <br/><br/>
        </div>
      </div>
    </div>
  );
}
