import React, { useEffect, useState, useRef } from "react";
import * as subscriptionsActions from "./subscriptionsActions";
import * as Yup from 'yup';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Button } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useLang } from "../../../../_metronic/i18n/Metronici18n";
import languages from "../../Shared/Enums/languages";
import GoogleMapReact from 'google-map-react';
import LocationOn from '@material-ui/icons/LocationOn';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import axios from 'axios';
import DatePicker, { registerLocale } from "react-datepicker";
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep, set } from 'lodash';
import {toast} from 'react-toastify';
import googleMap from "../../Shared/PublicKeys/googleMapKey";

let marker;
let lines = [];

let btn = React.createRef();

export default function BuyAccessPage({
  history
}) {
  const ref = useRef(null);
  const ref2 = useRef(null);
  const intl = useIntl();
  const { messages: msg } = intl;
  const langName = useLang();
  const lang = languages[langName];
  const [postCode, setPostCode] = useState('');
  const [postCodeCoords, setPostcodeCoords] = useState({lat: null, lng: null});
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({lat: 0, lng: 0});
  const [cultivationsListSorted, setCultivationsListSorted] = useState([]);
  const [maxPostcodeOrCityLength, setMaxPostcodeOrCityLength] = useState(40);
  const [discountCode, setDiscountCode] = useState('');
  const [disablePaymentBtn, setDisablePaymentBtn] = useState(false);
  useEffect(()=>{
    if (!isNaN(postCode.charAt(0)) && !isNaN(postCode.charAt(1)) ) {
      setMaxPostcodeOrCityLength(6);
      if (postCode.length === 2) {
        setPostCode(`${postCode}-`);
      }
    }
    else {
      setMaxPostcodeOrCityLength(40);
    }
  }, [postCode]);

  const validationReceiptSchema = Yup.object().shape({
    personalname: Yup.string()
      .required(msg['USER.VALIDATION.REQUIRED'])
      .min(3, msg["USER.VALIDATION.MIN3"])
      .max(30, msg["USER.VALIDATION.MAX15"])
      .matches(/^[\sA-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ]*$/ , msg["USER.VALIDATION.LETTERS"]),
    personalsurname: Yup.string()
      .required(msg['USER.VALIDATION.REQUIRED'])
      .min(3, msg["USER.VALIDATION.MIN3"])
      .max(30, msg["USER.VALIDATION.MAX15"])
      .matches(/^[\sA-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ]*$/ , msg["USER.VALIDATION.LETTERS"]),
    phone: Yup.string()
      .required(msg['USER.VALIDATION.REQUIRED'])
      .min(9, msg["USER.VALIDATION.MIN9"])
      .max(15, msg["USER.VALIDATION.MAX15"])
      .matches(/^[\s0-9 +]*$/ , msg["USER.VALIDATION.DIGITS"]),
    street: Yup.string()
      .max(30, msg["USER.VALIDATION.MAX20"])
      .matches(/^[\sA-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ 0-9]*$/ , msg["USER.VALIDATION.DIGANDLET"]),
    number: Yup.string()
      .max(10, msg["USER.VALIDATION.MAX10"])
      .matches(/^[\sA-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ 0-9]*$/ , msg["USER.VALIDATION.DIGANDLET"]),
    postcode: Yup.string()
      .matches(/^(?:[0-9]{2}-[0-9]{3})?$/, msg["USER.VALIDATION.ZIPCODE"]),
    city: Yup.string()
      .max(30, msg["USER.VALIDATION.MAX20"])
      .matches(/^[\sA-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ 0-9]*$/ , msg["USER.VALIDATION.DIGANDLET"]),
    termsCheck: Yup.string()
      .matches(true , msg["USER.VALIDATION.TERMS"]),
  });

  const validationInvoiceSchema = Yup.object().shape({
    name: Yup.string()
      .required(msg['USER.VALIDATION.REQUIRED'])
      .min(3, msg["USER.VALIDATION.MIN3"])
      .max(40, msg["USER.VALIDATION.MAX20"])
      .matches(/^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ 0-9]+$/ , msg["USER.VALIDATION.DIGANDLET"]),
    phone: Yup.string()
      .required(msg['USER.VALIDATION.REQUIRED'])
      .min(9, msg["USER.VALIDATION.MIN9"])
      .max(12, msg["USER.VALIDATION.MAX12"])
      .matches(/^[\s0-9 +]*$/ , msg["USER.VALIDATION.DIGITS"]),
    street: Yup.string()
      .required(msg['USER.VALIDATION.REQUIRED'])
      .min(3, msg["USER.VALIDATION.MIN3"])
      .max(30, msg["USER.VALIDATION.MAX20"])
      .matches(/^[\sA-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ 0-9]*$/ , msg["USER.VALIDATION.DIGANDLET"]),
    number: Yup.string()
      .required(msg['USER.VALIDATION.REQUIRED'])
      .max(20, msg["USER.VALIDATION.MAX20"])
      .matches(/^[\sA-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ 0-9]*$/ , msg["USER.VALIDATION.DIGANDLET"]),
    postcode: Yup.string()
      .required(msg['USER.VALIDATION.REQUIRED'])
      .matches(/^[0-9]{2}-[0-9]{3}$/ , msg["USER.VALIDATION.ZIPCODE"]),
    city: Yup.string()
      .required(msg['USER.VALIDATION.REQUIRED'])
      .min(3, msg["USER.VALIDATION.MIN3"])
      .max(30, msg["USER.VALIDATION.MAX20"])
      .matches(/^[\sA-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ 0-9]*$/ , msg["USER.VALIDATION.DIGANDLET"]),
    nip: Yup.string()
      .required(msg['USER.VALIDATION.REQUIRED'])
      .matches(/^[0-9]{9,10}$/ , msg["USER.VALIDATION.NIP"]),
    termsCheck: Yup.string()
      .matches(true , msg["USER.VALIDATION.TERMS"]),
    });

  let validationSchema = validationReceiptSchema

  const [dateFrom, setDateFrom] = useState(new Date());
  const [monitoringOption, setMonitoringOption] = useState(true);
  const [monthsCount, setMonthsCount] = useState(12);
  const [selectedCultivation, setSelectedCultivation] = useState('');
  const [orderSum, setOrderSum] = useState(0);
  const [orderDiscount, setOrderDiscount] = useState(0);
  const [orderSumWithDiscount, setOrderSumWithDiscount] = useState(0);
  const [orderDiscountValue, setOrderDiscountValue] = useState(0);
  const [markerPos, setMarkerPos] = useState({lat: null, lng: null});
  const [invoice, setInvoice] = useState(false);
  const [invoiceCompanyName, setInvoiceCompanyName] = useState('');
  const [invoicePersonalName, setInvoicePersonalName] = useState('');
  const [invoicePersonalSurname, setInvoicePersonalSurname] = useState('');
  const [invoicePhone, setInvoicePhone] = useState('');
  const [invoiceStreetName, setInvoiceStreetName] = useState('');
  const [invoiceStreetNumber, setInvoiceStreetNumber] = useState('');
  const [invoicePostCode, setInvoicePostCode] = useState('');
  const [invoiceCity, setInvoiceCity] = useState('');
  const [invoiceNip, setInvoiceNip] = useState('');
  const [terms, setTerms] = useState(false);
  const [monitoredDiseases, setMonitoredDiseases] = useState('-');
  const [insectModels, setInsectModels] = useState('-');
  const [monitoredInsects, setMonitoredInsects] = useState('-');

  const [nameErrorMessage, setnameErrorMessage] = useState('');
  const [personalnameErrorMessage, setpersonalnameErrorMessage] = useState('');
  const [personalsurnameErrorMessage, setpersonalsurnameErrorMessage] = useState('');
  const [phoneErrorMessage, setphoneErrorMessage] = useState('');
  const [streetErrorMessage, setstreetErrorMessage] = useState('');
  const [numberErrorMessage, setnumberErrorMessage] = useState('');
  const [postcodeErrorMessage, setpostcodeErrorMessage] = useState('');
  const [cityErrorMessage, setcityErrorMessage] = useState('');
  const [nipErrorMessage, setnipErrorMessage] = useState('');
  const [termsErrorMessage, settermsErrorMessage] = useState(false);


  const updateErrorHook = (fieldName, fieldValue, errorMessageHook) => {
    const receiptBasedFieldValidationSchema = Yup.object().shape({
      [fieldName]: validationReceiptSchema.fields[fieldName],
    });
    const invoiceBasedFieldValidationSchema = Yup.object().shape({
      [fieldName]: validationInvoiceSchema.fields[fieldName],
    });
    
    if (invoice) {
      invoiceBasedFieldValidationSchema
      .validate({ [fieldName]: fieldValue }, { abortEarly: false })
      .then(() => {
        errorMessageHook("");
      })
      .catch((error) => {
        errorMessageHook(error.errors[0]);
      });

    }
    else {
      receiptBasedFieldValidationSchema
      .validate({ [fieldName]: fieldValue }, { abortEarly: false })
      .then(() => {
        errorMessageHook("");
      })
      .catch((error) => {
        errorMessageHook(error.errors[0]);
      });
    }
  }

  function compare( a, b ) {
    if ( a.names[lang] < b.names[lang] ){
      return -1;
    }
    if ( a.names[lang] > b.names[lang] ){
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    if (selectedCultivation != "")
    {
        dispatch(subscriptionsActions.fetchStationCultivationInfo(selectedLocation.id, selectedCultivation));
    }
  }, [selectedCultivation]);

  useEffect(() => {
    if (cultivationsList) {
      let sorted = [...cultivationsList];
      sorted.sort(compare);
      setCultivationsListSorted(sorted);
    }
  }, [monitoringOption])

  const [mapSettings, setMapSettings] = useState({
    center: {
      lat: 52.133231,
      lng: 19.596804
    },
    zoom: 6
  });

  const showLocation = (lat, lng) => {
    map.setCenter({lat, lng});
    map.setZoom(12);
  }

  const instance = axios.create({
    baseURL : 'https://maps.google.com/maps/api/',
  });


  const drawLines = (lat, lng) => {
    allAvailableLocations.map((location)=> {

      const distance = calculateDistance(location.latitude, location.longitude, lat, lng);
      if (distance <= 20)
      {
        var newline = new maps.Polyline({
          path: [
              new maps.LatLng(lat, lng), 
              new maps.LatLng(location.latitude, location.longitude)
          ],
          strokeColor: "#FF0000",
          strokeOpacity: 1.0,
          strokeWeight: 2,
          map: map
        });
        lines.push(newline);
      }
    
 });
  }

  const resetLines = _ => {
    if (lines.length > 0) {
      for (let loop=0; loop<lines.length; loop++) 
      {                           
        lines[loop].setMap(null);
      }
      lines = [];
    }
  }

  const putMarker = (lat, lng) => {
      if (marker!=null)
          marker.setMap(null);
        marker = new maps.Marker({
          position: {lat, lng},
          map,
          draggable:true,
          labelInBackground: false,
          labelAnchor: new maps.Point(0, 40),
          animation: maps.Animation.DROP,
          label: {
            text: msg.DRAG_TO_SELECT_LOCATION,
            color: 'black',
            fontSize: '16px',      
            className: 'marker-position',
          },
        });
        setTimeout(() => {
          drawLines(lat, lng);
        }, 1000);
        drawLines(lat, lng);
        marker.addListener('drag', e => {
          let {lat, lng} = marker.getPosition().toJSON();
          setSelectedLocation({lat, lng});
          setPostcodeCoords({lat, lng});
          resetLines();
          drawLines(lat, lng);
        });

        marker.addListener('dragend', e => {
          let {lat, lng} = marker.getPosition().toJSON();
          if (map.getZoom() < 10)
          map.setZoom(10);
          map.setCenter({lat, lng});
          setMarkerPos({lat, lng});
        });
  }

  const getPostcodeCoords = async (locationString) => {
    try {
      axios.post(`/getLocationCoords`,
        { locationString 
      }).then((res) => {
        // const {lat, lng} = res.data.results[0].geometry.location;
        const {lat, lng} = res.data;
        setPostcodeCoords({lat, lng});
        map.setCenter({lat, lng});

        if (postCode == '')
        map.setZoom(7);
        putMarker(lat, lng);
        
        resetLines();
      });
    } catch (e) {
    }
  }

  const calculateDistance = (lat1, lon1, lat2, lon2) => {    
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    }
    else {
      var radlat1 = Math.PI * lat1/180;
      var radlat2 = Math.PI * lat2/180;
      var theta = lon1-lon2;
      var radtheta = Math.PI * theta/180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180/Math.PI;
      dist = dist * 60 * 1.1515;
      dist = dist * 1.609344;
      return dist;
    }
    }
  
  const handleApiLoaded = async (mapEntity, mapsEntity) => {
    setMap(mapEntity);
    setMaps(mapsEntity);
    setTimeout(() => {
      try {
        btn.current.click();
      } catch {}
    }, 1000);
  };

  const { allAvailableLocations, cultivationsList, prices, calculatedOrder, invoiceInfo, stationCultivationInfo, discountCodeInfo, error } = useSelector(
    (state) => ({
      allAvailableLocations: state.subscriptions.allAvailableLocations,
      cultivationsList: state.subscriptions.userCultivationsList,
      prices: state.subscriptions.prices,
      calculatedOrder: state.subscriptions.calculatedOrder,
      invoiceInfo: state.subscriptions.invoiceInfo,
      stationCultivationInfo: state.subscriptions.stationCultivationInfo,
      discountCodeInfo: state.subscriptions.discountCodeInfo,
      error: state.subscriptions.error
    }),
    shallowEqual
  );

  useEffect(() => {
    if (stationCultivationInfo)
    {
      let cultMonitoredDiseases = [];
      for (const diseases of stationCultivationInfo.monitoredDiseases) {
        let diseaseEntity = diseases.names[lang];
        if (diseases?.dates && diseases.dates?.length>0)
        {
          let ranges = [];
          let diseaseDates = [...diseases.dates];
          diseaseDates.sort(( a, b ) => {
            if ( a.dateFrom < b.dateFrom ){
              return -1;
            }
            if ( a.dateFrom > b.dateFrom ){
              return 1;
            }
            return 0;
          })
          for (const dateEnt of diseaseDates)
          {
            let dateFromConverted = '';
            let dateToConverted = '';
            let df = dateEnt.dateFrom.split('-');
            let dt = dateEnt.dateTo.split('-');
            console.log(df, '-', dt);
            dateFromConverted = `${parseInt(df[1])} ${msg.MONTHS_SHORT[parseInt(df[0])-1].toLowerCase()}`;
            dateToConverted = `${parseInt(dt[1])} ${msg.MONTHS_SHORT[parseInt(dt[0])-1].toLowerCase()}`;
            ranges.push(`${dateFromConverted}-${dateToConverted}`);
          }

          diseaseEntity = `${diseaseEntity} (${ranges.join(', ')})`;
        }

        cultMonitoredDiseases.push(diseaseEntity);

      }
      let diseasesToSet = cultMonitoredDiseases.length > 0 ? cultMonitoredDiseases.join(', ') : '';
      setMonitoredDiseases(diseasesToSet);

      let cultInsectModels = [];
      for (const models of stationCultivationInfo.insectModels) {
        cultInsectModels.push(models[lang]);
      }
      let insectModelsToSet = cultInsectModels.length > 0 ? cultInsectModels.join(', ') : '-';
      setInsectModels(insectModelsToSet);

      let cultMonitoredInsects = [];
      for (const insect of stationCultivationInfo.monitoredInsects) {
        cultMonitoredInsects.push(insect[lang]);
      }
      let monitoredInsectsToSet = cultMonitoredInsects.length > 0 ? cultMonitoredInsects.join(', ') : '-';
      setMonitoredInsects(monitoredInsectsToSet);
    }
  }, [stationCultivationInfo]);

  useEffect(() => {
    if (invoiceInfo)
    {
      setInvoice(invoiceInfo.invoice);
      setInvoiceCompanyName(invoiceInfo.invoiceCompanyName);
      setInvoicePersonalName(invoiceInfo.invoicePersonalName);
      setInvoicePersonalSurname(invoiceInfo.invoicePersonalSurname);
      setInvoicePhone(invoiceInfo.invoicePhone);
      setInvoiceStreetName(invoiceInfo.invoiceStreetName);
      setInvoiceStreetNumber(invoiceInfo.invoiceStreetNumber);
      setInvoicePostCode(invoiceInfo.invoicePostCode);
      setInvoiceCity(invoiceInfo.invoiceCity);
      setInvoiceNip(invoiceInfo.invoiceNip);
    }
  }, [invoiceInfo]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(subscriptionsActions.fetchUserInvoiceInfo());
    dispatch(subscriptionsActions.fetchAllAvailableLocations());
    dispatch(subscriptionsActions.fetchUserCultivationsList());
    dispatch(subscriptionsActions.fetchPrices());
  }, [dispatch]);

  const clickMarker = (location) => {
    setSelectedLocation(location);
    setSelectedCultivation("");
    setShowLocationMenu(true);
    setMonitoringOption(false);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
      setMonitoringOption(true);
    }, 500);
  }

  registerLocale('currentDatePickerLang', {
    localize: {
      month: n => msg.MONTHS[n],
      day: n => msg.DAYS[n]
    },
    options: {
      weekStartsOn: 1
      /* Monday */
      ,
      firstWeekContainsDate: 2
    },
    formatLong: {}
  });

  useEffect(() => {
    if (selectedLocations.length>0 && calculatedOrder !== null)
    {
        let {stationDataSum, stationDataSumAfterDiscount, monitoringSum,  monitoringSumAfterDiscount } = calculatedOrder;
        let orderSumFinal = stationDataSum+monitoringSum;
        let orderSumWithDiscountFinal = stationDataSumAfterDiscount+monitoringSumAfterDiscount;
        setOrderSum(orderSumFinal);
        setOrderSumWithDiscount(orderSumWithDiscountFinal);

        let finalDiscountPerc = 100 - Math.round((orderSumWithDiscountFinal/orderSumFinal) * 100);
        setOrderDiscount(finalDiscountPerc);
        
        setOrderDiscountValue(orderSumFinal-orderSumWithDiscountFinal);
    }
  }, [calculatedOrder]);

  const lowAccuracyMarkerColor = 'green';
  const lowAccuracyMarkerSize = '32';
  const midAccuracyMarkerColor = 'blue';
  const midAccuracyMarkerSize = '32';
  const highAccuracyMarkerColor = 'darkblue';
  const highAccuracyMarkerSize = '32';

  let Marker = ({location, markerColor, markerSize}) => {
    let {name, id, latitude, longitude} = location;
    
    return <div lat={latitude} lng={longitude}><LocationOn onClick={()=>clickMarker(location)} style={{color: markerColor, fontSize: `${markerSize}px`, marginTop: '-38px', marginLeft: '-16px'}} /> </div>
  }

  const LocationMenu = location => {
    let {name, id, latitude, longitude} = location.location;
    const distance = calculateDistance(latitude, longitude, postCodeCoords.lat, postCodeCoords.lng).toFixed(0);
    let distanceInfo = msg.ACCURACY_LOW;
    if (distance <= 20) distanceInfo =  msg.ACCURACY_MID;
    if (distance <= 10) distanceInfo =  msg.ACCURACY_HIGH;
    return (<div key={id} ref={ref}
    style={{boxShadow: '0 2px 16px 1px rgba(0, 0, 0, 0.8)', position: 'relative', width: "80%", minHeight: '220px', marginLeft: "10%", top: '-55vh', backgroundColor: '#ffffffe9', padding: '10px'}}>
    <Button className="btn-sm btn-danger" style={{position: 'absolute', right: 10, top: 10, padding: '5px', width: '30px', height: '30px'}} onClick={() => {setShowLocationMenu(false)}}>X</Button>
    <div style={{color: 'black', fontSize: '16px', fontWeight:'777', marginTop: '0px'}}>{selectedLocation.name}</div>
    {postCodeCoords.lat!==null && <div style={{fontSize: '14px', fontStyle: 'ita', marginTop: '5px', fontWeight: 'bold'}}>{`(${distanceInfo}, ${msg.DISTANCE_TO_STATION} ${distance} km)`}</div>}

    <hr />
    <div className="row">
        <div className="col-5"><h6>{msg.SUBSCRIPTION_FROM}:</h6></div>
        <div className="col-7"><h6 style={{textAlign: 'left'}}>{msg.SUBSCRIPTION_VALID_FOR}:</h6></div>
        <div className="col-5 mb-2" style={{color: '#fff', fontSize: '11px'}}>
        <DatePicker
            locale="currentDatePickerLang"
            selected={dateFrom}
            dateFormat="yyyy-MM-dd"
            onChange={dateFrom => setDateFrom(dateFrom)}
            className="form-control mr-5"
            popperPlacement="bottom-start"
            onKeyDown={(event) => {
              event.preventDefault();
            }}
          />
        </div>
        <div className="col-7 mb-2" style={{color: '#fff', fontSize: '11px'}}>
          <select className="form-control" defaultValue={monthsCount} onChange={e => setMonthsCount(e.target.value)}>
            <option value={1} key={1}>{msg.SUBSCRIPTION_1_MONTH}</option>
            <option value={2} key={2}>{msg.SUBSCRIPTION_2_MONTH}</option>
            <option value={3} key={3}>{msg.SUBSCRIPTION_3_MONTH}</option>
            <option value={4} key={4}>{msg.SUBSCRIPTION_4_MONTH}</option>
            <option value={5} key={5}>{msg.SUBSCRIPTION_5_MONTH}</option>
            <option value={6} key={6}>{msg.SUBSCRIPTION_6_MONTH}</option>
            <option value={7} key={7}>{msg.SUBSCRIPTION_7_MONTH}</option>
            <option value={8} key={8}>{msg.SUBSCRIPTION_8_MONTH}</option>
            <option value={9} key={9}>{msg.SUBSCRIPTION_9_MONTH}</option>
            <option value={10} key={10}>{msg.SUBSCRIPTION_10_MONTH}</option>
            <option value={11} key={11}>{msg.SUBSCRIPTION_11_MONTH}</option>
            <option value={12} key={12}>{msg.SUBSCRIPTION_12_MONTH}</option>
          </select>
        </div>
    </div>
    <div className="row">
      <div className="col-12 mt-4">
        <h5><input type="checkbox" defaultChecked={true} disabled /> {msg.STATION_DATA_AND_FORECAST}</h5>
      </div>
      <div className="col-12 mb-4">
        <h5><label style={{cursor: 'pointer'}}><input type="checkbox" defaultChecked={monitoringOption} onClick={e=>setMonitoringOption(e.target.checked)} /> {msg.DISEASES_INSECTS_MONITORING}</label></h5>
      </div>
    </div>
    {monitoringOption &&
    <div className="row">
        <div className="col-12 mb-2" style={{ fontSize: '11px'}}>
          <h6>{msg.CULTIVATION}:</h6>
        </div>
        <div className="col-12 mb-2" style={{ fontSize: '11px'}}>
        <select className="form-control" aria-label={msg.CULTIVATION_SELECT} defaultValue={selectedCultivation} onChange={(e) => {setSelectedCultivation(e.target.value)}}>
          <option value="">{msg.CULTIVATION_SELECT}</option>
          {cultivationsListSorted && cultivationsListSorted.map(cultivation => {
              return <option key={cultivation.id} value={cultivation.id}>{cultivation.names[lang]}</option>
            }
          )}
        </select>
        {
          selectedCultivation != "" && stationCultivationInfo &&
          <>
            <div style={{fontSize:14}} className="mt-4">
              <div className="mt-2">{msg.MONITORED_DISEASES}:<br/><strong>{monitoredDiseases}</strong></div>
              <div className="mt-2">{msg.INSECTS_MODELS}:<br/><strong>{insectModels}</strong></div>
              <div className="mt-2">{msg.MONITORED_INSECTS}:<br/><strong>{monitoredInsects}</strong></div>
            </div>
          </>
        }
        </div>
    </div>
    }
    <div className="mb-3 mt-2" style={{textAlign: 'center'}}>
      <Button onClick={()=>addItem(location)} disabled={monitoringOption&selectedCultivation==""}>{msg.ADD_TO_ORDER}</Button>
    </div>
  </div>)
  }  

  const addItem = location => {
    let tmpLocations = [...selectedLocations];
    let locationOptions = {
      id: Math.floor(Math.random() * Date.now()),
      dateFrom,
      monthsCount,
      monitoringOption,
      selectedCultivation,
      markerPos
    }
    let uuid = uuidv4();
    tmpLocations.push({location: location.location, locationOptions, uid: uuid});
    setSelectedLocations(tmpLocations);
    setShowLocationMenu(false);
    ref2.current?.scrollIntoView({ behavior: 'smooth' });
    dispatch(subscriptionsActions.calculateOrder(tmpLocations, discountCode));
  }

  const removeItem = itemId => {
    let items = cloneDeep(selectedLocations);
    let itemsFiltered = items.filter(item => item.uid != itemId);

    for(var i = 0; i < items.length; i++) {
      if(items[i].uid == itemId) {
        items.splice(i, 1);
        break;
      }
    }
    setSelectedLocations(items);
    dispatch(subscriptionsActions.calculateOrder(items, discountCode));

  }

  const pay = _ => {
    let invoiceData = {
      invoice,
      invoiceCompanyName,
      invoicePersonalName,
      invoicePersonalSurname,
      invoicePhone,
      invoiceStreetName,
      invoiceStreetNumber,
      invoicePostCode,
      invoiceCity,
      invoiceNip
    };
    
    let listToValidate = {
      name: invoiceCompanyName,
      personalname: invoicePersonalName,
      personalsurname: invoicePersonalSurname,
      phone: invoicePhone,
      street: invoiceStreetName,
      number: invoiceStreetNumber,
      postcode: invoicePostCode,
      city: invoiceCity,
      nip: invoiceNip,
      termsCheck: terms
    }
    invoice ? validationSchema = validationInvoiceSchema : validationSchema = validationReceiptSchema;
    validationSchema.validate(listToValidate).then(validData => {
      setDisablePaymentBtn(true);
      dispatch(subscriptionsActions.pay(selectedLocations, langName, invoiceData, discountCode));
    }).catch(validationError => {
      updateErrorHook('name', invoiceCompanyName, setnameErrorMessage)
      updateErrorHook('personalname', invoicePersonalName, setpersonalnameErrorMessage)
      updateErrorHook('personalsurname', invoicePersonalSurname, setpersonalsurnameErrorMessage)
      updateErrorHook('phone', invoicePhone, setphoneErrorMessage)
      updateErrorHook('street', invoiceStreetName, setstreetErrorMessage)
      updateErrorHook('number', invoiceStreetNumber, setnumberErrorMessage)
      updateErrorHook('postcode', invoicePostCode, setpostcodeErrorMessage)
      updateErrorHook('city', invoiceCity, setcityErrorMessage)
      updateErrorHook('nip', invoiceNip, setnipErrorMessage)
      updateErrorHook('termsCheck', terms, settermsErrorMessage)
    });
  }

  const [showLocationMenu, setShowLocationMenu] = useState(false);

  const checkCode = async () => {
      dispatch(subscriptionsActions.fetchDiscountCodeInfo(discountCode)).then(res=> console.log(res));
  }

  useEffect(() => {
    if (discountCodeInfo) {
    if (discountCodeInfo.exists) {
      let { code, isUsed} = discountCodeInfo;
      if (isUsed) {
        toast.error(msg.DISCOUNT_CODE_USED);
        setDiscountCode('');
      } else {
        toast.success(msg.DISCOUNT_CODE_VALID);
      }
    } else {
      toast.error(msg.DISCOUNT_CODE_INVALID);
      setDiscountCode('');
    }
  }
  dispatch(subscriptionsActions.calculateOrder(selectedLocations, discountCode));
  dispatch(subscriptionsActions.resetDiscountCodeInfo());

  }, [discountCodeInfo]);


  return (
    <Card ref={ref2}>
      <CardHeader title={msg.BUY_ACCESS}>
        <CardHeaderToolbar>
          <label>
            <input type="text" 
              className="form-control" 
              placeholder={msg.POST_CODE_OR_CITY} 
              style={{marginTop:7, width: 250}} 
              value={postCode} 
              maxLength={maxPostcodeOrCityLength}
              onChange={e=>{if (e.nativeEvent.data == '-') return false; if (e.nativeEvent.inputType === 'deleteContentBackward') setPostCode(''); else setPostCode(e.target.value)}}
              onKeyDown={e => {if (e.nativeEvent.key == 'Enter') {getPostcodeCoords(postCode); if (postCode!='') map.setZoom(10)}}}
            />
          </label> 
          <Button 
            style={{marginLeft: 10}} 
            onClick={()=>{getPostcodeCoords(postCode); if (postCode!='') map.setZoom(10)}} 
            ref={btn}>{msg.SHOW_NEAREST_LOCATIONS}
          </Button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* {postCodeCoords.lat &&<div>Twoja lokalizacja: {postCodeCoords.lat} {postCodeCoords.lng}</div>} */}
        <div style={{border: '1px solid #eee', padding: '10px'}}>
        {selectedLocations.length > 0 && <>
              <h4 className="text-center mb-5">{msg.YOUR_ORDER}</h4>
                <table className="table">
                  <thead>
                    <th>{msg.SUBSCRIPTION_LOCATION}</th>
                    <th>{msg.SUBSCRIPTION_DATE_FROM}</th>
                    <th>{msg.SUBSCRIPTION_PERIOD}</th>
                    <th>{msg.SUBSCRIPTION_STATION_DATA_FORECAST}</th>
                    <th>{msg.SUBSCRIPTION_DISEASES_MODELS}</th>
                    <th>{msg.CULTIVATION}</th>
                    <th>{msg.SUBSCRIPTION_PRICE}</th>
                    <th>{msg.SUBSCRIPTION_DELETE}</th>
                  </thead>
                  <tbody>
                {selectedLocations.length > 0 && selectedLocations.map((l, key) => {
                  return (
                    <tr key={key}>
                      <td>
                          {l.location.name} <a className="btn btn-sm btn-success" style={{height:"30px", lineHeight: '14px', display: "block"}} onClick={() => clickMarker(l.location)}>+ {msg.ADD_NEXT_CULTIVATION}</a>
                      </td>
                      <td>
                          {l.locationOptions.dateFrom.toJSON().substr(0,10)}
                      </td>
                      <td>
                          {l.locationOptions.monthsCount}
                      </td>
                      <td>
                        <CheckCircleIcon style={{color:'green',fontSize:'22px'}} />
                      </td>
                      <td>
                          {l.locationOptions.monitoringOption ? <CheckCircleIcon style={{color:'green',fontSize:'22px'}} /> : '-'}
                      </td>
                      <td>
                          {l.locationOptions.monitoringOption?cultivationsList.find(cultivation => cultivation.id === l.locationOptions.selectedCultivation).names[lang]:'-'
                          }
                      </td>
                      <td>
                          {l.locationOptions.monitoringOption ? parseInt(prices.realStationPrices.monitoring.pln.base)*l.locationOptions.monthsCount : prices.realStationPrices.stationData.pln.base*l.locationOptions.monthsCount} PLN
                      </td>
                      <td>
                        <Button className="btn-sm btn-secondary" style={{height:'14px', fontSize: '12px', lineHeight:'0px'}} onClick={() => removeItem(l.uid)}>x</Button>
                      </td>
                  </tr>)
                })}
                </tbody>
              </table>
              <div className="row" style={{padding: "10px"}}>
                <div className="col-3" style={{ display: 'inline-block'}}><input style={{maxWidth: "60%", display: 'inline-block'}}  placeholder={msg.DISCOUNT_CODE} type="text"className="form-control" value={discountCode} onChange={e=>setDiscountCode(e.target.value)} /> <input style={{ display: 'inline-block', width: "40px"}}  type="button" className="btn btn-primary btn-sm" onClick={_=>checkCode()} value="OK" />
                </div>
              </div>
        </>
        }      
            {selectedLocations.length > 0 && <>             
              <div className="text-center" style={{fontSize: '16px'}}><h3 className="mt-3"><strong>
                {calculatedOrder != null && orderDiscount == 0 && orderSum}
                {orderDiscount>0&&<h5><s>{orderSum} PLN</s> {msg.DISCOUNT} {orderDiscountValue} PLN<br/></h5>}
                {orderDiscount>0 && `${msg.TO_PAY}: ${orderSumWithDiscount} `} 
                 PLN 
                {calculatedOrder != null && orderDiscount>0&& ` (-${orderDiscount}%)`}</strong></h3>
                {orderSum && calculatedOrder === null && <strong>{msg.RECALCULATING}</strong>}
                <div className="col-md-12">
                  <hr />
                    <label className="mr-3" style={{fontSize: "18px", fontWeight: 777}}><input type="radio" value={false} name="invoice" checked={!invoice} onClick={() => setInvoice(false)} /> {msg.RECEIPT}</label>
                    <label  style={{fontSize: "18px", fontWeight: 777}}>
                      <input type="radio" value={true} name="invoice" checked={invoice} onClick={() => setInvoice(true)} /> {msg.INVOICE}
                    </label>
                 <br/>
                    <div className="row text-left" style={{marginTop: '10px'}}>
                      {!invoice &&
                      <div className="col-md-2 offset-md-3">
                        <label style={{width:'100%'}}>{msg.INVOICE_PERSONAL_NAME} <input onBlur={e => {updateErrorHook('personalname', e.target.value, setpersonalnameErrorMessage)}} className="form-control" style={{width: '100%'}} value={invoicePersonalName}  onChange={e=>setInvoicePersonalName(e.target.value)} /> </label>
                        <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px' ,marginBottom: '10px'}}>{personalnameErrorMessage}</div>
                      </div>
                      }
                      {!invoice &&
                      <div className="col-md-2">
                        <label style={{width:'100%'}}>{msg.INVOICE_PERSONAL_SURNAME} <input onBlur={e => {updateErrorHook('personalsurname', e.target.value, setpersonalsurnameErrorMessage)}} className="form-control" style={{width: '100%'}} value={invoicePersonalSurname}  onChange={e=>setInvoicePersonalSurname(e.target.value)} /> </label>
                        <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px' ,marginBottom: '10px'}}>{personalsurnameErrorMessage}</div>
                      </div>
                      }
                      {invoice &&
                      <div className="col-md-4 offset-md-3">
                        <label style={{width:'100%'}}>{msg.INVOICE_NAME} 
                          <input onBlur={e => {updateErrorHook('name', e.target.value, setnameErrorMessage)}} className="form-control" style={{width: '100% !important'}} value={invoiceCompanyName}  onChange={e=>setInvoiceCompanyName(e.target.value)} /> 
                          <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px' ,marginBottom: '10px'}}>{nameErrorMessage}</div>
                        </label>
                      </div>
                      }
                      <div className="col-md-2">
                        <label style={{width:'100%'}}>{msg.INVOICE_PHONE} 
                          <input onBlur={e => {updateErrorHook('phone', e.target.value, setphoneErrorMessage)}} className="form-control" style={{width: '100%'}} value={invoicePhone} onChange={e=>setInvoicePhone(e.target.value)}  /> 
                          <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px' ,marginBottom: '10px'}}>{phoneErrorMessage}</div>
                        </label>
                      </div>
                      <div className="col-md-4 offset-md-3">
                        <label style={{width:'100%'}}>{msg.INVOICE_STREET_NAME} 
                          <input onBlur={e => {updateErrorHook('street', e.target.value, setstreetErrorMessage)}} className="form-control" style={{width: '100%'}} value={invoiceStreetName} onChange={e=>setInvoiceStreetName(e.target.value)}  /> 
                          <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px' ,marginBottom: '10px'}}>{streetErrorMessage}</div>
                        </label>
                      </div>
                     <div className="col-md-2">
                     <label style={{width:'100%'}}>{msg.INVOICE_STREET_NUMBER} 
                        <input onBlur={e => {updateErrorHook('number', e.target.value, setnumberErrorMessage)}} className="form-control" style={{width: '100%'}} value={invoiceStreetNumber} onChange={e=>setInvoiceStreetNumber(e.target.value)}  /> 
                        <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px' ,marginBottom: '10px'}}>{numberErrorMessage}</div>
                     </label>
                     </div>
                    <div className="col-md-2 offset-md-3">
                     <label style={{width:'100%'}}>{msg.INVOICE_POSTCODE} 
                      <input onBlur={e => {updateErrorHook('postcode', e.target.value, setpostcodeErrorMessage)}} className="form-control" style={{width: '100%'}} value={invoicePostCode} onChange={e=>setInvoicePostCode(e.target.value)}  /> 
                      <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px' ,marginBottom: '10px'}}>{postcodeErrorMessage}</div>
                     </label>
                     </div>
                     {invoice &&
                      <div className="col-md-2">
                      <label style={{width:'100%'}}>{msg.INVOICE_CITY} 
                        <input onBlur={e => {updateErrorHook('city', e.target.value, setcityErrorMessage)}} className="form-control" style={{width: '100%'}} value={invoiceCity} onChange={e=>setInvoiceCity(e.target.value)}  /> 
                        <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px' ,marginBottom: '10px'}}>{cityErrorMessage}</div>
                      </label>
                      </div>
                      }
                      {!invoice &&
                      <div className="col-md-4">
                        <label style={{width:'100%'}}>{msg.INVOICE_CITY} 
                          <input onBlur={e => {updateErrorHook('city', e.target.value, setcityErrorMessage)}} className="form-control" style={{width: '100%'}} value={invoiceCity} onChange={e=>setInvoiceCity(e.target.value)}  /> 
                          <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px' ,marginBottom: '10px'}}>{cityErrorMessage}</div>
                        </label>
                      </div>
                      }

                    {invoice &&
                    <div className="col-md-2">
                     <label>{msg.INVOICE_NIP} <input onBlur={e => {updateErrorHook('nip', e.target.value, setnipErrorMessage)}} className="form-control" style={{width: '100%'}} value={invoiceNip}  onChange={e=>setInvoiceNip(e.target.value)} /> </label>
                     <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px' ,marginBottom: '10px'}}>{nipErrorMessage}</div>
                    </div>
                    }
                    </div>
                </div>
                <label>
                  <input 
                    type="checkbox" 
                    className="form-control" 
                    style={{marginTop:'5px', fontSize: '2px'}} 
                    checked={terms} 
                    onBlur={e => {updateErrorHook('termsCheck', e.target.checked, settermsErrorMessage)}}
                    onChange={e => setTerms(e.target.checked)} /> 
                  <span 
                    style={{fontSize: '13px', lineHeight: '14px'}}>{msg.ACCEPT_PAYMENT_TERMS}
                  </span>
                  <a style={{fontSize: '11px', lineHeight: '8px'}} target="_blank" href="https://farmsmart.pl/regulamin-sklepu/">
                    <OpenInNewIcon style={{fontSize: '14px'}} />
                  </a>
                  <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px' ,marginBottom: '0px'}}>{termsErrorMessage}</div>
                </label><br/>
                <Button className="btn btn-success mt-5 mb-3" disabled={disablePaymentBtn} onClick={() => pay()}>{msg.MAKE_ORDER}</Button>
              </div>
              </>
            }
            {selectedLocations.length === 0 && <div className="text-center" style={{fontSize:'20px', paddingTop: '20px', paddingBottom: '20px'}}>{msg.EMPTY_ORDER}</div>}
        </div>
        <div style={{fontSize: '16px', marginTop: '20px', marginBottom: '5px', textAlign: 'center'}}>
          <LocationOn style={{color: lowAccuracyMarkerColor}} /> {msg.STATION}
          <LocationOn style={{color: midAccuracyMarkerColor}} className="ml-3" /> {msg.ACCURACY_MID}
          <LocationOn style={{color: highAccuracyMarkerColor}} className="ml-3" /> {msg.ACCURACY_HIGH}
          <br/>
          <i>{msg.SELECT_STATIONS}</i>
        </div>
  
        {allAvailableLocations && allAvailableLocations.length>0 &&
        <div style={{ height: '60vh', width: '100%'}}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: googleMap.publicKey }}
            defaultCenter={mapSettings.center}
            defaultZoom={mapSettings.zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            options={{'dataMode': 'regions', 'region': 'PL'}}
          >
           {allAvailableLocations.map((location)=> {

              let markerColor = lowAccuracyMarkerColor;
              let markerSize = lowAccuracyMarkerSize;

              if (postCodeCoords.lat !== null) {
                const distance = calculateDistance(location.latitude, location.longitude, postCodeCoords.lat, postCodeCoords.lng);
                if (distance <= 10)
                {
                  markerColor = highAccuracyMarkerColor;
                  markerSize = highAccuracyMarkerSize;
                } else if (distance <= 20) {
                  markerColor = midAccuracyMarkerColor;
                  markerSize = midAccuracyMarkerSize;
                }
              }

              return <Marker key={location.id} lat={location.latitude} lng={location.longitude} location={location} markerColor={markerColor} markerSize={markerSize} />;
           })}
           
          </GoogleMapReact>
          {showLocationMenu && <LocationMenu lat={selectedLocation.lat} lng={selectedLocation.lng} location={selectedLocation} />}
          </div>
          }
          
      </CardBody>
    </Card>
  )
}