/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useIntl } from "react-intl";
import languages from "../../Shared/Enums/languages";
import { useLang } from "../../../../_metronic/i18n/Metronici18n";
import axios from 'axios';
import { toast } from 'react-toastify';
import * as Yup from 'yup';


export default function CalculatorEmission({
  history
}) {
  const langName = useLang();
  const intl = useIntl();
  const { messages: msg } = intl;
  const lang = languages[langName];

  const backToLocations = () => {
    history.push(`/locations`);
  };

  const axiosConnection = axios.create({
    baseURL: 'https://api.farmcloud.eu/api/v1/calculators/emission-calculations',
    headers: {'Berear': `${sessionStorage.getItem('msal.09354d56-898b-48cc-90c9-4c1021d6f764.idtoken')}`}
  });


  const [groundAreaInHaErrorMessage, setGroundAreaInHaErrorMessage] = useState('');
  const [groundPhErrorMessage, setGroundPhErrorMessage] = useState('');
  const [groundHumusPercentageInSoilErrorMessage, setGroundHumusPercentageInSoilErrorMessage] = useState('');
  const [cultivationCropYieldErrorMessage, setCultivationCropYieldErrorMessage] = useState('');
  const [cultivationCropResiduesErrorMessage, setCultivationCropResiduesErrorMessage] = useState('');
  const [cultivationCropSeedWeightErrorMessage, setCultivationCropSeedWeightErrorMessage] = useState('');
  const [cultivationInterCropSeedWeightErrorMessage, setCultivationInterCropSeedWeightErrorMessage] = useState('');

  const [cropTreatmentPloughingErrorMessage, setCropTreatmentPloughingErrorMessage] = useState('');
  const [cropTreatmentTillingErrorMessage, setCropTreatmentTillingErrorMessage] = useState('');
  const [cropTreatmentCultivatingErrorMessage, setCropTreatmentCultivatingErrorMessage] = useState('');
  const [cropTreatmentDiscingErrorMessage, setCropTreatmentDiscingErrorMessage] = useState('');
  const [cropTreatmentHarrowingErrorMessage, setCropTreatmentHarrowingErrorMessage] = useState('');
  const [cropTreatmentMulchingErrorMessage, setCropTreatmentMulchingErrorMessage] = useState('');
  const [cropTreatmentMachineCultivationErrorMessage, setCropTreatmentMachineCultivationErrorMessage] = useState('');
  const [cropTreatmentManureSpreadingErrorMessage, setCropTreatmentManureSpreadingErrorMessage] = useState('');
  const [cropTreatmentFertilizationErrorMessage, setCropTreatmentFertilizationErrorMessage] = useState('');
  const [cropTreatmentSeedingErrorMessage, setCropTreatmentSeedingErrorMessage] = useState('');
  const [cropTreatmentMachineSeedingErrorMessage, setCropTreatmentMachineSeedingErrorMessage] = useState('');
  const [cropTreatmentPlantingErrorMessage, setCropTreatmentPlantingErrorMessage] = useState('');
  const [cropTreatmentCoveringErrorMessage, setCropTreatmentCoveringErrorMessage] = useState('');
  const [cropTreatmentSprayingErrorMessage, setCropTreatmentSprayingErrorMessage] = useState('');
  const [cropTreatmentMachineMowingErrorMessage, setCropTreatmentMachineMowingErrorMessage] = useState('');
  const [cropTreatmentHarvesterMowingErrorMessage, setCropTreatmentHarvesterMowingErrorMessage] = useState('');
  const [cropTreatmentRakingErrorMessage, setCropTreatmentRakingErrorMessage] = useState('');
  const [cropTreatmentPressingErrorMessage, setCropTreatmentPressingErrorMessage] = useState('');
  const [cropTreatmentStrawHarvestingErrorMessage, setCropTreatmentStrawHarvestingErrorMessage] = useState('');
  const [cropTreatmentCerealHarvestingErrorMessage, setCropTreatmentCerealHarvestingErrorMessage] = useState('');
  const [cropTreatmentPotatoHarvestingErrorMessage, setCropTreatmentPotatoHarvestingErrorMessage] = useState('');
  const [cropTreatmentPotatoMachineHarvestingErrorMessage, setCropTreatmentPotatoMachineHarvestingErrorMessage] = useState('');
  const [cropTreatmentBeetHarvestingErrorMessage, setCropTreatmentBeetHarvestingErrorMessage] = useState('');
  const [cropTreatmentBeetMachineHarvestingErrorMessage, setCropTreatmentBeetMachineHarvestingErrorMessage] = useState('');
  const [cropTreatmentRootVegetableHarvestingErrorMessage, setCropTreatmentRootVegetableHarvestingErrorMessage] = useState('');
  const [cropTreatmentRootVegetableMachineHarvestingErrorMessage, setCropTreatmentRootVegetableMachineHarvestingErrorMessage] = useState('');
  const [cropTreatmentRootVegetableManualHarvestingErrorMessage, setCropTreatmentRootVegetableManualHarvestingErrorMessage] = useState('');
  const [cropTreatmentFruitManualHarvestingErrorMessage, setCropTreatmentFruitManualHarvestingErrorMessage] = useState('');

  const [manureFreshErrorMessage, setManureFreshErrorMessage] = useState('');
  const [manureDryErrorMessage, setManureDryErrorMessage] = useState('');
  const [manureFermentedErrorMessage, setManureFermentedErrorMessage] = useState('');
  const [manureErrorMessage, setManureErrorMessage] = useState('');
  const [fertilizerNitrogenErrorMessage, setFertilizerNitrogenErrorMessage] = useState('');
  const [fertilizerPhosphorusErrorMessage, setFertilizerPhosphorusErrorMessage] = useState('');
  const [fertilizerPotassiumErrorMessage, setFertilizerPotassiumErrorMessage] = useState('');
  const [fertilizerCalciumErrorMessage, setFertilizerCalciumErrorMessage] = useState('');
  const [fertilizerMagnesiumErrorMessage, setFertilizerMagnesiumErrorMessage] = useState('');
  const [fertilizerSulfurErrorMessage, setFertilizerSulfurErrorMessage] = useState('');
  const [fertilizerMicronutrientErrorMessage, setFertilizerMicronutrientErrorMessage] = useState('');
  const [sprayingPesticideErrorMessage, setSprayingPesticideErrorMessage] = useState('');
  const [sprayingBioStimulatorErrorMessage, setSprayingBioStimulatorErrorMessage] = useState('');
  const [sprayingFoliarErrorMessage, setSprayingFoliarErrorMessage] = useState('');
  const [transportYieldErrorMessage, setTransportYieldErrorMessage] = useState('');
  const [transportDistanceErrorMessage, setTransportDistanceErrorMessage] = useState('');
  const [transportStorageWeightErrorMessage, setTransportStorageWeightErrorMessage] = useState('');
  const [transportColdStorageWeightErrorMessage, setTransportColdStorageWeightErrorMessage] = useState('');
  const [transportColdStorageTimeErrorMessage, setTransportColdStorageTimeErrorMessage] = useState('');

  function createNumberValidation(min, max, invalidNumberMsg) {
    return Yup.string()
      .required(msg['USER.VALIDATION.REQUIRED'])
      .matches(/^[0-9.,]*$/, 'Tylko liczby')
      .test('is-valid-number', msg['USER.VALIDATION_MORE_THAN'] + ` ${min}`, value => {
        if (value.trim() === '') {
          return false;
        }
        const numberValue = Number(value);
        return !Number.isNaN(numberValue) && numberValue >= min;
      })
      .test('is-valid-number', msg['USER.VALIDATION_LESS_THAN'] + ` ${max}`, value => {
        const numberValue = Number(value);
        return !Number.isNaN(numberValue) && numberValue <= max;
      })
  }
  function createNumberValidationTwo(min, max, invalidNumberMsg) {
    return Yup.string()
      .required(msg['USER.VALIDATION.REQUIRED'])
      .matches(/^[0-9.,]*$/, 'Tylko liczby')
      .test('is-valid-number', msg['USER.VALIDATION_MORE_THAN'] + ` ${min}`, value => {
        if (value.trim() === '') {
          return false;
        }
        const numberValue = Number(value);
        return !Number.isNaN(numberValue) && numberValue > min;
      })
      .test('is-valid-number', msg['USER.VALIDATION_LESS_THAN'] + ` ${max}`, value => {
        const numberValue = Number(value);
        return !Number.isNaN(numberValue) && numberValue <= max;
      })
  }

  const validationSchema = Yup.object().shape({
    groundAreaInHa: createNumberValidationTwo(0, 10000),
    groundPh: createNumberValidationTwo(3.4, 8),
    groundHumusPercentageInSoil: createNumberValidationTwo(.4, 8),
    cultivationCropYield: createNumberValidationTwo(0, 10000),
    cultivationCropResidues: createNumberValidation(0, 100),
    cultivationCropSeedWeight: createNumberValidationTwo(0, 1000000),
    cultivationInterCropSeedWeight: createNumberValidationTwo(0, 100),

    cropTreatmentPloughing: createNumberValidation(0, 100),
    cropTreatmentTilling: createNumberValidation(0, 100),
    cropTreatmentCultivating: createNumberValidation(0, 100),
    cropTreatmentDiscing: createNumberValidation(0, 100),
    cropTreatmentHarrowing: createNumberValidation(0, 100),
    cropTreatmentMulching: createNumberValidation(0, 100),
    cropTreatmentMachineCultivation: createNumberValidation(0, 100),
    cropTreatmentManureSpreading: createNumberValidation(0, 100),
    cropTreatmentFertilization: createNumberValidation(0, 100),
    cropTreatmentSeeding: createNumberValidation(0, 100),
    cropTreatmentMachineSeeding: createNumberValidation(0, 100),
    cropTreatmentPlanting: createNumberValidation(0, 100),
    cropTreatmentCovering: createNumberValidation(0, 100),
    cropTreatmentSpraying: createNumberValidation(0, 100),
    cropTreatmentMachineMowing: createNumberValidation(0, 100),
    cropTreatmentHarvesterMowing: createNumberValidation(0, 100),
    cropTreatmentRaking: createNumberValidation(0, 100),
    cropTreatmentPressing: createNumberValidation(0, 100),
    cropTreatmentStrawHarvesting: createNumberValidation(0, 100),
    cropTreatmentCerealHarvesting: createNumberValidation(0, 100),
    cropTreatmentPotatoHarvesting: createNumberValidation(0, 100),
    cropTreatmentPotatoMachineHarvesting: createNumberValidation(0, 100),
    cropTreatmentBeetHarvesting: createNumberValidation(0, 100),
    cropTreatmentBeetMachineHarvesting: createNumberValidation(0, 100),
    cropTreatmentRootVegetableHarvesting: createNumberValidation(0, 100),
    cropTreatmentRootVegetableMachineHarvesting: createNumberValidation(0, 100),
    cropTreatmentVegetableManualHarvesting: createNumberValidation(0, 100),
    cropTreatmentFruitManualHarvesting: createNumberValidation(0, 100),

    manureFresh: createNumberValidation(0, 1000),
    manureDry: createNumberValidation(0, 1000),
    manureFermented: createNumberValidation(0, 1000),
    manure: createNumberValidation(0, 1000),

    fertilizerNitrogen: createNumberValidation(0, 100000),
    fertilizerPhosphorus: createNumberValidation(0, 100000),
    fertilizerPotassium: createNumberValidation(0, 100000),
    fertilizerCalcium: createNumberValidation(0, 100000),
    fertilizerMagnesium: createNumberValidation(0, 100000),
    fertilizerSulfur: createNumberValidation(0, 100000),
    fertilizerMicronutrient: createNumberValidation(0, 100000),
    sprayingPesticide: createNumberValidation(0, 100000),
    sprayingBioStimulator: createNumberValidation(0, 100000),
    sprayingFoliar: createNumberValidation(0, 100000),

    transportYield: createNumberValidation(0, 100),
    transportDistance: createNumberValidationTwo(0, 1000000),
    transportStorageWeight: createNumberValidation(0, 10000),
    transportColdStorageWeight: createNumberValidation(0, 10000),
    transportColdStorageTime: createNumberValidation(0, 1000),
  })


  const updateErrorHook = (fieldName, fieldValue, errorMessageHook) => {
    fieldValue = fieldValue.replace(',', '.');
    validationSchema
        .validateAt(fieldName, { [fieldName]: fieldValue })
        .then(() => {
          errorMessageHook("");
        })
        .catch((error) => {
          errorMessageHook(error.message);
        });
  }

  const cultivations = [
    ['Cultivation.WinterRapeseed', 'Rzepak ozimy', 'Winter rapessed'],
    ['Cultivation.WinterRye', 'Żyto ozime', 'Winter rye'],
    ['Cultivation.Soy', 'Soja', 'Souybean'],
    ['Cultivation.WhiteBeet', 'Burak cukrowy', 'White beet'],
    ['Cultivation.WinterWheat', 'Pszenica ozima', 'Winter wheat'],
    ['Cultivation.SpringWheat', 'Pszenica jara', 'Spring wheat'],
    ['Cultivation.Potato', 'Ziemniak', 'Potato'],
    ['Cultivation.WinterBarley', 'Jęczmień ozimy', 'Winter barley'],
    ['Cultivation.Barley', 'Jęczmień jary', 'Spring barley'],
    ['Cultivation.Oat', 'Owies', 'Oat'],
    ['Cultivation.WinterTriticale', 'Przenżyto ozime', 'Winter triticale'],
    ['Cultivation.Triticale', 'Przenżyto jare', 'Spring triticale'],
    ['Cultivation.Sunflower', 'Słonecznik', 'Sunflower'],
    ['Cultivation.Corn', 'Kukurydza', 'Corn'],
    ['Cultivation.Bobik', 'Bobik', 'Bean'],
    ['Cultivation.BroadBean', 'Bób', 'Broad bean'],
    ['Cultivation.Pea', 'Groch', 'Pea'],
    ['Cultivation.BlueLupine', 'Łubin wąskolistny', 'Blue lupine'],
    ['Cultivation.Buckwheat', 'Gryka', 'Buckwheat'],
    ['Cultivation.WhiteMustard', 'Gorczyca biała', 'White Mustard'],
    ['Cultivation.AppleTree', 'Jabłoń', 'Apple'],
    ['Cultivation.Pear', 'Grusza', 'Pear'],
    ['Cultivation.PlumTree', 'Śliwa', 'Plum'],
    ['Cultivation.Cherry', 'Wiśnia', 'Cherry'],
    ['Cultivation.SweetCherry', 'Czereśnia', 'Sweet cherry'],
    ['Cultivation.Strawberry', 'Truskawka', 'Strawberry'],
    ['Cultivation.Blueberry', 'Borówka amerykańska', 'Blueberry'],
    ['Cultivation.Raspberry', 'Malina', 'Raspberry'],
    ['Cultivation.Peach', 'Brzoskwinia', 'Peach'],
    ['Cultivation.Broccoli', 'Brokół', 'Broccoli'],
    ['Cultivation.Cauliflower', 'Kalafior', 'Cauliflower'],
    ['Cultivation.Carrot', 'Marchew', 'Carrot'],
    ['Cultivation.EdiblePea', 'Groch siewny jadalny', 'EdiblePea'],
    ['Cultivation.Sweetcorn', 'Kukurydza słodka', 'Sweetcorn'],
    ['Cultivation.Beans', 'Fasola', 'Beans'],
    ['Cultivation.Onion', 'Cebula', 'Onion'],
    ['Cultivation.Garlic', 'Czosnek', 'Garlic'],
    ['Cultivation.Lettuce', 'Sałata', 'Lettuce'],
    ['Cultivation.Tomato', 'Pomidor', 'Tomato'],
    ['Cultivation.Cabbage', 'Kapusta', 'Cabbage'],
    ['Cultivation.ChineseCabbage', 'Kapusta pekińska', 'Chinese cabbage'],
    ['Cultivation.Cucumber', 'Ogórek', 'Cucumber'],
    ['Cultivation.Pumpkin', 'Dynia', 'Pumpkin'],
    ['Cultivation.Parsley', 'Pietruszka', 'Parsley'],
    ['Cultivation.Beetroot', 'Burak ćwikłowy', 'Beetroot'],
    ['Cultivation.Leek', 'Por', 'Leek'],

    // ['Cultivation.Maize', 'Kukurydza na silos', 'Maize'],
    // Pszenica ozima mieszańcowa/Hybrid winter wheat

    // ['Cultivation.FodderBeet', 'Burak pastewny', 'Fodder beet'],
    // ['Cultivation.RedClover', 'Koniczyna czerwona', 'Red clover'],
    // ['Cultivation.Lucerne', 'Lucerna', 'Lucerne'],
    // ['Cultivation.Kohlrabi', 'Kalarepa', 'Kohlrabi'],
    // ['Cultivation.BrusselsSprouts', 'Kapusta brukselska', 'Brussels sprouts'],
    // ['Cultivation.CabbageWhite', 'Kapusta głowiasta biała', 'Cabbage white'],
    // ['Cultivation.CabbageRed', 'Kapusta głowiasta czerwona', 'Cabbage red'],
    // ['Cultivation.Pepper', 'Papryka', 'Pepper'],
    // ['Cultivation.Parsnip', 'Pasternak', 'Parsnip'],
    // ['Cultivation.Radish', 'Rzodkiewka', 'Radish'],
    // ['Cultivation.Celery', 'Seler', 'Celery'],
    // ['Cultivation.Asparagus', 'Szparagi', 'Asparagus'],
    // ['Cultivation.Spinach', 'Szpinak', 'Spinach']
  ]

  const intercrops = [
    ['Cultivation.Bobik', 'Bobik', 'Bean'],
    // ['Cultivation.Oat', 'Owies', 'Oat'],
    // ['Cultivation.Pea', 'Groch', 'Pea'],
    ['Cultivation.PeaFodderFieldPeas', 'Groch siewny pastewny (peluszka)', 'Pea Fodder Field Peas'],
    // ['Cultivation.RedClover', 'Koniczyna czerwona', 'Red Clover'],
    ['Cultivation.Serradella', 'Seradela', 'Serradella'],
    // ['Cultivation.Shamrock', 'Koniczyna biała', 'Shamrock'],
    ['Cultivation.Sunflower', 'Słonecznik', 'Sunflower'],
    ['Cultivation.VetchAndHairyVetch', 'Wyka kosmata i siewna', 'Vetch And Hairy Vetch'],
    // ['Cultivation.WhiteLupine', 'Łubin biały', 'White Lupine'],
    ['Cultivation.WhiteMustard', 'Gorczyca biała', 'White Mustard'],
    ['Cultivation.WinterRye', 'Żyto ozime', 'Winter Rye'],
    ['Cultivation.Phacelia', 'Facelia błękitna', 'Phacelia'],
    ['Cultivation.YellowLupine', 'Łubin żółty', 'Yellow Lupine'],
    // ['Cultivation.BlueLupine', 'Łubin wąskolistny', 'Blue Lupine']
    
    ['Cultivation.Buckwheat', 'Gryka zwyczajna', 'Buckwheat'],
    ['Cultivation.Cabbage', 'Kapusta', 'Cabbage'],
    ['Cultivation.WinterRapeseed', 'Rzepak ozimy', 'WinterRapeseed'],
    ['Cultivation.Radish', 'Rzodkiewka', 'Radish'],
  ]

  const soilCat = [
    ['VeryLight', 'Bardzo lekka', 'Very light'],
    ['Light', 'Lekka', 'Light'],
    ['Average', 'Średnia', 'Average'],
    ['Heavy', 'Ciężka', 'Heavy']
  ]

  const climateTypes = [
    ['TemperateContinental', 'Umiarkowany kontynentalny', 'Temperate continental'],
    ['TemperateOceanic', 'Umiarkowany oceaniczny', 'Temperate oceanic'],
    ['Subtropical', 'Subtropikalny', 'Subtropical'],
    ['Tropical', 'Tropikalny', 'Tropical']
  ]

  const groundSoilTypes = [
    ['Mineral', 'Mineralna', 'Mineral'],
    ['Organic', 'Organiczna', 'Organic']
  ]

  const cropResiduesUtilization = [
    ['Ploughing', 'Orka', 'Ploughing'],
    ['Mulching', 'Mulczowanie', 'Mulching'],
    ['NoPloughing', 'System bezorkowy', 'No ploughing']
  ]

  const zboza = [
    'Cultivation.WinterRapeseed',
    'Cultivation.WinterRye',
    'Cultivation.Soy',
    'Cultivation.WinterWheat',
    'Cultivation.SpringWheat',
    'Cultivation.WinterBarley',
    'Cultivation.Barley',
    'Cultivation.Oat',
    'Cultivation.WinterTriticale',
    'Cultivation.Triticale',
    'Cultivation.Sunflower',
    'Cultivation.Corn',
    'Cultivation.BlueLupine',
    'Cultivation.Buckwheat',
    'Cultivation.WhiteMustard',
    'Cultivation.Sweetcorn'
  ]
  const ziemniaki = [
    'Cultivation.Potato'
  ]
  const buraki = [
    'Cultivation.Beetroot',
    'Cultivation.WhiteBeet'
  ]
  const warzywaLisciaste = [
    'Cultivation.Bobik',
    'Cultivation.BroadBean',
    'Cultivation.Pea',
    'Cultivation.Broccoli',
    'Cultivation.Cauliflower',
    'Cultivation.EdiblePea',
    'Cultivation.Beans',
    'Cultivation.Lettuce',
    'Cultivation.Cabbage',
    'Cultivation.ChineseCabbage',
    'Cultivation.Leek',
  ]
  const warzywaOwocowe = [
    'Cultivation.Tomato',
    'Cultivation.Cucumber',
    'Cultivation.Pumpkin',
  ]
  const drzewaOwocowe = [
    'Cultivation.AppleTree',
    'Cultivation.Pear',
    'Cultivation.PlumTree',
    'Cultivation.Cherry',
    'Cultivation.SweetCherry',
    'Cultivation.Peach',
  ]
  const owoceJagodowe = [
    'Cultivation.Strawberry',
    'Cultivation.Blueberry',
    'Cultivation.Raspberry',
  ]
  const warzywaKorzeniowe = [
    'Cultivation.Carrot',
    'Cultivation.Onion',
    'Cultivation.Garlic',
    'Cultivation.Parsley'
  ]

  const [results, setResults] = useState(null);
  const [groundAreaInHa, setGroundAreaInHa] = useState('0');
  const [cultivationCrop, setCultivationCrop] = useState(cultivations[0][0]);
  const [groundClimateType, setGroundClimateType] = useState(climateTypes[0][0]);
  const [groundSoilType, setGroundSoilType] = useState(groundSoilTypes[0][0]);
  const [groundArgonomicCategory, setGroundArgonomicCategory] = useState(soilCat[0][0]);
  const [groundPh, setGroundPh] = useState('6.5');
  const [groundHumusPercentageInSoil, setGroundHumusPercentageInSoil] = useState('0');
  const [cultivationCropYield, setCultivationCropYield] = useState('0');
  // const [cultivationCropWaterContent, setCultivationCropWaterContent] = useState('0');
  const [cultivationCropSeedWeight, setCultivationCropSeedWeight] = useState('0');
  const [cultivationCropResidues, setCultivationCropResidues] = useState('0');
  const [cultivationCropResiduesUtilization, setCultivationCropResiduesUtilization] = useState(cropResiduesUtilization[0][0]);
  const [isCultivationInterCropExists, setIsCultivationInterCropExists] = useState(false);
  const [cultivationInterCrop, setCultivationInterCrop] = useState(cultivations[0][0]);
  const [cultivationInterCropSeedWeight, setCultivationInterCropSeedWeight] = useState('0');
  const [cultivationInterCropResiduesUtilization, setCultivationInterCropResiduesUtilization] = useState(false);
  
  const [cropTreatmentPloughing, setCropTreatmentPloughing] = useState('0');
  const [cropTreatmentTilling, setCropTreatmentTilling] = useState('0');
  const [cropTreatmentCultivating, setCropTreatmentCultivating] = useState('0');
  const [cropTreatmentDiscing, setCropTreatmentDiscing] = useState('0');
  const [cropTreatmentHarrowing, setCropTreatmentHarrowing] = useState('0');
  const [cropTreatmentMulching, setCropTreatmentMulching] = useState('0');
  const [cropTreatmentMachineCultivation, setCropTreatmentMachineCultivation] = useState('0');
  const [cropTreatmentManureSpreading, setCropTreatmentManureSpreading] = useState('0');
  const [cropTreatmentFertilization, setCropTreatmentFertilization] = useState('0');
  const [cropTreatmentSeeding, setCropTreatmentSeeding] = useState('0');
  const [cropTreatmentMachineSeeding, setCropTreatmentMachineSeeding] = useState('0');
  const [cropTreatmentPlanting, setCropTreatmentPlanting] = useState('0');
  const [cropTreatmentCovering, setCropTreatmentCovering] = useState('0');
  const [cropTreatmentSpraying, setCropTreatmentSpraying] = useState('0');
  const [cropTreatmentMachineMowing, setCropTreatmentMachineMowing] = useState('0');
  const [cropTreatmentHarvesterMowing, setCropTreatmentHarvesterMowing] = useState('0');
  const [cropTreatmentRaking, setCropTreatmentRaking] = useState('0');
  const [cropTreatmentPressing, setCropTreatmentPressing] = useState('0');
  const [cropTreatmentStrawHarvesting, setCropTreatmentStrawHarvesting] = useState('0');
  const [cropTreatmentCerealHarvesting, setCropTreatmentCerealHarvesting] = useState('0');
  const [cropTreatmentPotatoHarvesting, setCropTreatmentPotatoHarvesting] = useState('0');
  const [cropTreatmentPotatoMachineHarvesting, setCropTreatmentPotatoMachineHarvesting] = useState('0');
  const [cropTreatmentBeetHarvesting, setCropTreatmentBeetHarvesting] = useState('0');
  const [cropTreatmentBeetMachineHarvesting, setCropTreatmentBeetMachineHarvesting] = useState('0');
  const [cropTreatmentRootVegetableHarvesting, setCropTreatmentRootVegetableHarvesting] = useState('0');
  const [cropTreatmentRootVegetableMachineHarvesting, setCropTreatmentRootVegetableMachineHarvesting] = useState('0');
  const [cropTreatmentVegetableManualHarvesting, setCropTreatmentVegetableManualHarvesting] = useState('0');
  const [cropTreatmentFruitManualHarvesting, setCropTreatmentFruitManualHarvesting] = useState('0');
  const [manureFresh, setManureFresh] = useState('0');
  const [manureDry, setManureDry] = useState('0');
  const [manureFermented, setManureFermented] = useState('0');
  const [manure, setManure] = useState('0');
  const [fertilizerNitrogen, setFertilizerNitrogen] = useState('0');
  const [fertilizerPhosphorus, setFertilizerPhosphorus] = useState('0');
  const [fertilizerPotassium, setFertilizerPotassium] = useState('0');
  const [fertilizerCalcium, setFertilizerCalcium] = useState('0');
  const [fertilizerMagnesium, setFertilizerMagnesium] = useState('0');
  const [fertilizerSulfur, setFertilizerSulfur] = useState('0');
  const [fertilizerMicronutrient, setFertilizerMicronutrient] = useState('0');
  const [sprayingPesticide, setSprayingPesticide] = useState('0');
  const [sprayingBioStimulator, setSprayingBioStimulator] = useState('0');
  const [sprayingFoliar, setSprayingFoliar] = useState('0');
  const [isSprayingNitrogenFixation, setIsSprayingNitrogenFixation] = useState(false);
  const [transportYield, setTransportYield] = useState('100');
  const [transportDistance, setTransportDistance] = useState('0');
  const [transportStorageWeight, setTransportStorageWeight] = useState('0');
  const [transportColdStorageWeight, setTransportColdStorageWeight] = useState('0');
  const [transportColdStorageTime, setTransportColdStorageTime] = useState('0');
  

  const calculate = async() => {
    setResults(null);
    const res = await axiosConnection.post('', {

      groundAreaInHa: groundAreaInHa.replace(',','.'),
      cultivationCrop,
      groundClimateType,
      groundSoilType,
      groundArgonomicCategory,
      groundPh: groundPh.replace(',','.'),
      groundHumusPercentageInSoil: groundHumusPercentageInSoil.replace(',','.'),
      cultivationCropYield: cultivationCropYield.replace(',','.'),
      // cultivationCropWaterContent: cultivationCropWaterContent.replace(',','.'),
      cultivationCropSeedWeight: cultivationCropSeedWeight.replace(',','.'),
      cultivationCropResidues: cultivationCropResidues.replace(',','.'),
      cultivationCropResiduesUtilization,
      isCultivationInterCropExists,
      cultivationInterCropSeedWeight: cultivationInterCropSeedWeight.replace(',','.'),
      cultivationInterCropResiduesUtilization,
      cropTreatmentPloughing,
      cropTreatmentTilling,
      cropTreatmentCultivating,
      cropTreatmentDiscing,
      cropTreatmentHarrowing,
      cropTreatmentMulching,
      cropTreatmentMachineCultivation,
      cropTreatmentManureSpreading,
      cropTreatmentFertilization,
      cropTreatmentSeeding,
      cropTreatmentMachineSeeding,
      cropTreatmentPlanting,
      cropTreatmentCovering,
      cropTreatmentSpraying,
      cropTreatmentMachineMowing,
      cropTreatmentHarvesterMowing,
      cropTreatmentRaking,
      cropTreatmentPressing,
      cropTreatmentStrawHarvesting,
      cropTreatmentCerealHarvesting,
      cropTreatmentPotatoHarvesting,
      cropTreatmentPotatoMachineHarvesting,
      cropTreatmentBeetHarvesting,
      cropTreatmentBeetMachineHarvesting,
      cropTreatmentRootVegetableHarvesting,
      cropTreatmentRootVegetableMachineHarvesting,
      cropTreatmentVegetableManualHarvesting,
      cropTreatmentFruitManualHarvesting,
      manureFresh: manureFresh.replace(',','.'),
      manureDry: manureDry.replace(',','.'),
      manureFermented: manureFermented.replace(',','.'),
      manure: manure.replace(',','.'),
      fertilizerNitrogen: fertilizerNitrogen.replace(',','.'),
      fertilizerPhosphorus: fertilizerPhosphorus.replace(',','.'),
      fertilizerPotassium: fertilizerPotassium.replace(',','.'),
      fertilizerCalcium: fertilizerCalcium.replace(',','.'),
      fertilizerMagnesium: fertilizerMagnesium.replace(',','.'),
      fertilizerSulfur: fertilizerSulfur.replace(',','.'),
      fertilizerMicronutrient: fertilizerMicronutrient.replace(',','.'),
      sprayingPesticide: sprayingPesticide.replace(',','.'),
      sprayingBioStimulator: sprayingBioStimulator.replace(',','.'),
      sprayingFoliar: sprayingFoliar.replace(',','.'),
      isSprayingNitrogenFixation,
      transportYield: transportYield.replace(',','.'),
      transportDistance: transportDistance.replace(',','.'),
      transportStorageWeight: transportStorageWeight.replace(',','.'),
      transportColdStorageWeight: transportColdStorageWeight.replace(',','.'),
      transportColdStorageTime: transportColdStorageTime.replace(',','.'),
      cultivationInterCrop
      
    }).catch(function(error) {
      if (error.response.status === 500) 
      {
        toast.error(`Nieprawidłowo wypełnione pola`);
        updateErrorHook('groundAreaInHa', groundAreaInHa, setGroundAreaInHaErrorMessage)
        updateErrorHook('groundPh', groundPh, setGroundPhErrorMessage)
        updateErrorHook('groundHumusPercentageInSoil', groundHumusPercentageInSoil, setGroundHumusPercentageInSoilErrorMessage)
        updateErrorHook('cultivationCropYield', cultivationCropYield, setCultivationCropYieldErrorMessage)
        updateErrorHook('cultivationCropResidues', cultivationCropResidues, setCultivationCropResiduesErrorMessage)
        updateErrorHook('cultivationCropSeedWeight', cultivationCropSeedWeight, setCultivationCropSeedWeightErrorMessage)
        if (isCultivationInterCropExists) {
          updateErrorHook('cultivationInterCropSeedWeight', cultivationInterCropSeedWeight, setCultivationInterCropSeedWeightErrorMessage) 
        }
        updateErrorHook('cropTreatmentPloughing', cropTreatmentPloughing, setCropTreatmentPloughingErrorMessage);
        updateErrorHook('cropTreatmentTilling', cropTreatmentTilling, setCropTreatmentTillingErrorMessage);
        updateErrorHook('cropTreatmentCultivating', cropTreatmentCultivating, setCropTreatmentCultivatingErrorMessage);
        updateErrorHook('cropTreatmentDiscing', cropTreatmentDiscing, setCropTreatmentDiscingErrorMessage);
        updateErrorHook('cropTreatmentHarrowing', cropTreatmentHarrowing, setCropTreatmentHarrowingErrorMessage);
        updateErrorHook('cropTreatmentMulching', cropTreatmentMulching, setCropTreatmentMulchingErrorMessage);
        updateErrorHook('cropTreatmentMachineCultivation', cropTreatmentMachineCultivation, setCropTreatmentMachineCultivationErrorMessage);
        updateErrorHook('cropTreatmentManureSpreading', cropTreatmentManureSpreading, setCropTreatmentManureSpreadingErrorMessage);
        updateErrorHook('cropTreatmentFertilization', cropTreatmentFertilization, setCropTreatmentFertilizationErrorMessage);
        updateErrorHook('cropTreatmentSeeding', cropTreatmentSeeding, setCropTreatmentSeedingErrorMessage);
        updateErrorHook('cropTreatmentMachineSeeding', cropTreatmentMachineSeeding, setCropTreatmentMachineSeedingErrorMessage);
        updateErrorHook('cropTreatmentPlanting', cropTreatmentPlanting, setCropTreatmentPlantingErrorMessage);
        updateErrorHook('cropTreatmentCovering', cropTreatmentCovering, setCropTreatmentCoveringErrorMessage);
        updateErrorHook('cropTreatmentSpraying', cropTreatmentSpraying, setCropTreatmentSprayingErrorMessage);
        updateErrorHook('cropTreatmentMachineMowing', cropTreatmentMachineMowing, setCropTreatmentMachineMowingErrorMessage);
        updateErrorHook('cropTreatmentHarvesterMowing', cropTreatmentHarvesterMowing, setCropTreatmentHarvesterMowingErrorMessage);
        updateErrorHook('cropTreatmentRaking', cropTreatmentRaking, setCropTreatmentRakingErrorMessage);
        updateErrorHook('cropTreatmentPressing', cropTreatmentPressing, setCropTreatmentPressingErrorMessage);
        updateErrorHook('cropTreatmentStrawHarvesting', cropTreatmentStrawHarvesting, setCropTreatmentStrawHarvestingErrorMessage);
        updateErrorHook('cropTreatmentCerealHarvesting', cropTreatmentCerealHarvesting, setCropTreatmentCerealHarvestingErrorMessage);
        updateErrorHook('cropTreatmentPotatoHarvesting', cropTreatmentPotatoHarvesting, setCropTreatmentPotatoHarvestingErrorMessage);
        updateErrorHook('cropTreatmentPotatoMachineHarvesting', cropTreatmentPotatoMachineHarvesting, setCropTreatmentPotatoMachineHarvestingErrorMessage);
        updateErrorHook('cropTreatmentBeetHarvesting', cropTreatmentBeetHarvesting, setCropTreatmentBeetHarvestingErrorMessage);
        updateErrorHook('cropTreatmentBeetMachineHarvesting', cropTreatmentBeetMachineHarvesting, setCropTreatmentBeetMachineHarvestingErrorMessage);
        updateErrorHook('cropTreatmentRootVegetableHarvesting', cropTreatmentRootVegetableHarvesting, setCropTreatmentRootVegetableHarvestingErrorMessage);
        updateErrorHook('cropTreatmentRootVegetableMachineHarvesting', cropTreatmentRootVegetableMachineHarvesting, setCropTreatmentRootVegetableMachineHarvestingErrorMessage);
        updateErrorHook('cropTreatmentVegetableManualHarvesting', cropTreatmentVegetableManualHarvesting, setCropTreatmentRootVegetableManualHarvestingErrorMessage);
        updateErrorHook('cropTreatmentFruitManualHarvesting', cropTreatmentFruitManualHarvesting, setCropTreatmentFruitManualHarvestingErrorMessage);
        updateErrorHook('manureFresh', manureFresh, setManureFreshErrorMessage);
        updateErrorHook('manureDry', manureDry, setManureDryErrorMessage);
        updateErrorHook('manureFermented', manureFermented, setManureFermentedErrorMessage);
        updateErrorHook('manure', manure, setManureErrorMessage);
        updateErrorHook('fertilizerNitrogen', fertilizerNitrogen, setFertilizerNitrogenErrorMessage);
        updateErrorHook('fertilizerPhosphorus', fertilizerPhosphorus, setFertilizerPhosphorusErrorMessage);
        updateErrorHook('fertilizerPotassium', fertilizerPotassium, setFertilizerPotassiumErrorMessage);
        updateErrorHook('fertilizerCalcium', fertilizerCalcium, setFertilizerCalciumErrorMessage);
        updateErrorHook('fertilizerMagnesium', fertilizerMagnesium, setFertilizerMagnesiumErrorMessage);
        updateErrorHook('fertilizerSulfur', fertilizerSulfur, setFertilizerSulfurErrorMessage);
        updateErrorHook('fertilizerMicronutrient', fertilizerMicronutrient, setFertilizerMicronutrientErrorMessage);
        updateErrorHook('sprayingPesticide', sprayingPesticide, setSprayingPesticideErrorMessage);
        updateErrorHook('sprayingBioStimulator', sprayingBioStimulator, setSprayingBioStimulatorErrorMessage);
        updateErrorHook('sprayingFoliar', sprayingFoliar, setSprayingFoliarErrorMessage);
        updateErrorHook('transportYield', transportYield, setTransportYieldErrorMessage);
        updateErrorHook('transportDistance', transportDistance, setTransportDistanceErrorMessage);
        updateErrorHook('transportStorageWeight', transportStorageWeight, setTransportStorageWeightErrorMessage);
        updateErrorHook('transportColdStorageWeight', transportColdStorageWeight, setTransportColdStorageWeightErrorMessage);
        updateErrorHook('transportColdStorageTime', transportColdStorageTime, setTransportColdStorageTimeErrorMessage);
      }
    });
    if (res?.data)
    setResults(res.data);
  }

  const [disPloughing, setDisPloughing] = useState('inline')
  const [disTilling, setDisTilling] = useState('inline')
  const [disTreatmentCultivating, setDisTreatmentCultivating] = useState('inline')
  const [disDiscing, setDisDiscing] = useState('inline')
  const [disHarrowing, setDisHarrowing] = useState('inline')
  const [disMulching, setDisMulching] = useState('inline')
  const [disMachineCultivation, setDisMachineCultivation] = useState('inline')
  const [disSpreading, setDisSpreading] = useState('inline')
  const [disFertilization, setDisFertilization] = useState('inline')
  const [disSeeding, setDisSeeding] = useState('inline')
  const [disMachineSeeding, setDisMachineSeeding] = useState('inline')
  const [disPlanting, setDisPlanting] = useState('inline')
  const [disCovering, setDisCovering] = useState('inline')
  const [disSpraying, setDisSpraying] = useState('inline')
  const [disMachineMowing, setDisMachineMowing] = useState('inline')
  const [disHarvesterMowing, setDisHarvesterMowing] = useState('inline')
  const [disRaking, setDisRaking] = useState('inline')
  const [disPressing, setDisPressing] = useState('inline')
  const [disStrawHarvesting, setDisStrawHarvesting] = useState('inline')
  const [disCerealHarvesting, setDisCerealHarvesting] = useState('inline')
  const [disPotatoHarvesting, setDisPotatoHarvesting] = useState('inline')
  const [disPotatoMachineHarvesting, setDisPotatoMachineHarvesting] = useState('inline')
  const [disBeetHarvesting, setDisBeetHarvesting] = useState('inline')
  const [disBeetMachineHarvesting, setDisBeetMachineHarvesting] = useState('inline')
  const [disRootVegetableHarvesting, setDisRootVegetableHarvesting] = useState('inline')
  const [disRootMachineVegetableHarvesting, setDisRootMachineVegetableHarvesting] = useState('inline')
  const [disRootVegetableManualHarvesting, setDisRootVegetableManualHarvesting] = useState('inline')
  const [disRootFruitManualHarvesting, setDisRootFruitManualHarvesting] = useState('inline')

  useEffect(() => {
    setDisPloughing('none')
    setDisTilling('none')
    setDisTreatmentCultivating('none')
    setDisDiscing('none')
    setDisHarrowing('none')
    setDisMulching('none')
    setDisMachineCultivation('none')
    setDisSpreading('none')
    setDisFertilization('none')
    setDisSeeding('none')
    setDisMachineSeeding('none')
    setDisPlanting('none')
    setDisCovering('none')
    setDisSpraying('none')
    setDisMachineMowing('none')
    setDisHarvesterMowing('none')
    setDisRaking('none')
    setDisPressing('none')
    setDisStrawHarvesting('none')
    setDisCerealHarvesting('none')
    setDisPotatoHarvesting('none')
    setDisPotatoMachineHarvesting('none')
    setDisBeetHarvesting('none')
    setDisBeetMachineHarvesting('none')
    setDisRootVegetableHarvesting('none')
    setDisRootMachineVegetableHarvesting('none')
    setDisRootVegetableManualHarvesting('none')
    setDisRootFruitManualHarvesting('none')

    if (drzewaOwocowe.includes(cultivationCrop)) {
      setDisMulching('inline')
      setDisMachineCultivation('inline')
      setDisSpreading('inline')
      setDisFertilization('inline')
    }
    else {
      setDisPloughing('inline')
      setDisTilling('inline')
      setDisTreatmentCultivating('inline')
      setDisDiscing('inline')
      setDisHarrowing('inline')
      setDisMulching('inline')
      setDisMachineCultivation('inline')
      setDisSpreading('inline')
      setDisFertilization('inline')
    }
    if ((!ziemniaki.includes(cultivationCrop)) && 
    (!drzewaOwocowe.includes(cultivationCrop)) && 
    (!owoceJagodowe.includes(cultivationCrop))) {
      setDisSeeding('inline')
      setDisMachineSeeding('inline')
    }
    if ((ziemniaki.includes(cultivationCrop)) || 
    (warzywaOwocowe.includes(cultivationCrop)) || 
    (buraki.includes(cultivationCrop))) {
      setDisPlanting('inline')
      setDisCovering('inline')
    }
    if (warzywaLisciaste.includes(cultivationCrop)) {
      setDisPlanting('inline')
    }
    if (owoceJagodowe.includes(cultivationCrop)) {
      setDisCovering('inline')
    }
    setDisSpraying('inline')
    if ((!ziemniaki.includes(cultivationCrop)) && 
    (!buraki.includes(cultivationCrop))) {
      setDisMachineMowing('inline')
    }
    if (zboza.includes(cultivationCrop)) {
      setDisPressing('inline')
      setDisStrawHarvesting('inline')
      setDisCerealHarvesting('inline')
    }
    if (ziemniaki.includes(cultivationCrop)) {
      setDisPotatoHarvesting('inline')
      setDisPotatoMachineHarvesting('inline')
    }
    if (buraki.includes(cultivationCrop)) {
      setDisBeetHarvesting('inline')
      setDisBeetMachineHarvesting('inline')
    }
    if (warzywaKorzeniowe.includes(cultivationCrop)) {
      setDisRootVegetableHarvesting('inline')
    }
    if ((!ziemniaki.includes(cultivationCrop)) && 
    (!zboza.includes(cultivationCrop)) && 
    (!buraki.includes(cultivationCrop))) {
      setDisRootMachineVegetableHarvesting('inline')
    }
    if ((warzywaKorzeniowe.includes(cultivationCrop)) || 
    (warzywaLisciaste.includes(cultivationCrop)) || 
    (buraki.includes(cultivationCrop)) || 
    (warzywaOwocowe.includes(cultivationCrop))) {
      setDisRootVegetableManualHarvesting('inline')
    }
    if ((drzewaOwocowe.includes(cultivationCrop)) || 
    (owoceJagodowe.includes(cultivationCrop))) {
      setDisRootFruitManualHarvesting('inline')
    }
  }, [cultivationCrop]);

  return (
    <Card>
      <CardHeader title={msg["ASIDE.MENU.EMISSION_CALCULATOR"]}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToLocations}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            {msg.MENU_RETURN}
          </button>
          {`  `}

        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div>
          {msg.CARBON_EMISSION_DESCRIPTION} 
          <a href={msg.CARBON_EMISSION_PDF} target="_blank">
            {msg.CALCULATORS_HERE}
          </a>
        </div>
        <br />
        <div className="mt-5 text-center">
          <h6 className="text-left">{msg.GROUND}</h6><hr/>
          <div className="row mb-5">
            <div className="col-md-6 text-left">
                <label>{msg.GROUND_AREA} *</label>
                <input type="text" className="form-control" value={groundAreaInHa} onChange={e=> setGroundAreaInHa(e.target.value)} onBlur={e => {updateErrorHook('groundAreaInHa', e.target.value, setGroundAreaInHaErrorMessage)}} />
                <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{groundAreaInHaErrorMessage}</div>
            </div>
            <div className="col-md-6 text-left">
              <label>{msg.CLIMATE} *</label>
              <select className="form-control" value={groundClimateType} onChange={e=>setGroundClimateType(e.target.value)}>
                {
                  climateTypes.map((c, key) => {
                    return (<option key={key} value={c[0]}>{c[lang+1]}</option>
                  )})
                }
              </select>
            </div>
            <div className="col-md-6 text-left mt-5">
              <label>{msg.SOIL_TYPE} *</label>
              <select className="form-control" value={groundSoilType} onChange={e=>setGroundSoilType(e.target.value)}>
                {
                  groundSoilTypes.map((c, key) => {
                    return (<option key={key} value={c[0]}>{c[lang+1]}</option>
                  )})
                }
              </select>
            </div>
            <div className="col-md-6 text-left mt-5">
                <label>{msg.AGRONOMIC_CATEGORY} *</label>
                <select className="form-control" value={groundArgonomicCategory} onChange={e=>setGroundArgonomicCategory(e.target.value)}>
                {
                  soilCat.map((m, key) => {
                    return (<option key={key} value={m[0]}>{m[lang+1]}</option>
                  )})
                }
              </select>
            </div>
            <div className="col-md-6 text-left mt-5">
              <label>{msg.GROUND_PH} *</label>
              <input type="text" className="form-control" value={groundPh} onChange={e=> setGroundPh(e.target.value)} onBlur={e => {updateErrorHook('groundPh', e.target.value, setGroundPhErrorMessage)}}/>
              <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{groundPhErrorMessage}</div>
            </div>
            <div className="col-md-6 text-left mt-5">
              <label>{msg.PERC_HUMUS_IN_SOIL} *</label>
              <input type="text" className="form-control" value={groundHumusPercentageInSoil} onChange={e=> setGroundHumusPercentageInSoil(e.target.value)} onBlur={e => {updateErrorHook('groundHumusPercentageInSoil', e.target.value, setGroundHumusPercentageInSoilErrorMessage)}}/>
              <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{groundHumusPercentageInSoilErrorMessage}</div>
            </div>
          </div>
          <div className="pt-3 mt-5">
            <h6 className="text-left mt-5">{msg.MAIN_CROP}</h6><hr/>
          </div>
        <div className="row mb-5">
            <div className="col-md-6 text-left">
              <label>{msg.CULTIVATION} *</label>
              <select className="form-control" value={cultivationCrop} onChange={e=>setCultivationCrop(e.target.value)}>
                {
                  cultivations
                  .slice()
                  .sort((a, b) => a[lang + 1].localeCompare(b[lang + 1]))
                  .map((c, key) => (
                    <option key={key} value={c[0]}>{c[lang + 1]}</option>
                  ))
                }
              </select>
            </div>
            <div className="col-md-6 text-left">
                <label>{msg.YIELD} *</label>
                <input type="text" className="form-control" value={cultivationCropYield} onChange={e=> setCultivationCropYield(e.target.value)} onBlur={e => {updateErrorHook('cultivationCropYield', e.target.value, setCultivationCropYieldErrorMessage)}} />
                <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cultivationCropYieldErrorMessage}</div>
            </div>
            {/* <div className="col-md-6 text-left mt-5">
                <label>{msg.CROP_WATER_CONTENT}</label>
                <input type="text" className="form-control" value={cultivationCropWaterContent} onChange={e=> setCultivationCropWaterContent(e.target.value)} />
            </div> */}
            {!drzewaOwocowe.includes(cultivationCrop) &&
             !owoceJagodowe.includes(cultivationCrop) && 
              <div className="col-md-6 text-left mt-5">
                <label>{msg.CROP_SEED_WIEGHT} *</label>
                <input type="text" className="form-control" value={cultivationCropSeedWeight} onChange={e=> setCultivationCropSeedWeight(e.target.value)} onBlur={e => {updateErrorHook('cultivationCropSeedWeight', e.target.value, setCultivationCropSeedWeightErrorMessage)}}/>
                <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cultivationCropSeedWeightErrorMessage}</div>
              </div>
            }
            <div className="col-md-6 text-left mt-5">
                <label>{msg.CROP_RESIDUES} *</label>
                <input type="text" className="form-control" value={cultivationCropResidues} onChange={e=> setCultivationCropResidues(e.target.value)} onBlur={e => {updateErrorHook('cultivationCropResidues', e.target.value, setCultivationCropResiduesErrorMessage)}}/>
                <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cultivationCropResiduesErrorMessage}</div>
            </div>
            <div className="col-md-6 text-left mt-5">
                <label>{msg.CROP_RESIDUES_UTILIZATION} *</label>
                <select className="form-control" value={cultivationCropResiduesUtilization} onChange={e=>setCultivationCropResiduesUtilization(e.target.value)}>
                {
                  cropResiduesUtilization.map((m, key) => {
                    return (<option key={key} value={m[0]}>{m[lang+1]}</option>
                  )})
                }
                </select>
            </div>
        </div>
        <div className="pt-3 mt-5">
          <h6 className="text-left mt-5">{msg.INTERCROP}</h6><hr/>
        </div>
        <div className="row mb-5">

            <div className="col-md-6 text-left">
            <label>{msg.INTERCROP_EXISTS} *</label>
                <select className="form-control" value={isCultivationInterCropExists} onChange={e=>setIsCultivationInterCropExists(e.target.value)}>
                  <option value={true}>{msg.YES}</option>
                  <option value={false}>{msg.NO}</option>
              </select>
            </div>

            {isCultivationInterCropExists == "true" && <>
            <div className="col-md-6 text-left">
              <label>{msg.INTERCROP} *</label>
              <select className="form-control" value={cultivationInterCrop} onChange={e=>setCultivationInterCrop(e.target.value)}>
                {
                  intercrops
                  .slice()
                  .sort((a, b) => a[lang + 1].localeCompare(b[lang + 1])).map((c, key) => {
                    return (<option key={key} value={c[0]}>{c[lang+1]}</option>
                  )})
                }
              </select>
            </div>

            <div className="col-md-6 text-left mt-5">
                <label>{msg.CROP_SEED_WIEGHT} *</label>
                <input type="text" className="form-control" value={cultivationInterCropSeedWeight} onChange={e=> setCultivationInterCropSeedWeight(e.target.value)} onBlur={e => {updateErrorHook('cultivationInterCropSeedWeight', e.target.value, setCultivationInterCropSeedWeightErrorMessage)}}/>
                <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cultivationInterCropSeedWeightErrorMessage}</div>
            </div>

            <div className="col-md-6 text-left mt-5">
                <label>{msg.CROP_RESIDUES_UTILIZATION} *</label>
                <select className="form-control" value={cultivationInterCropResiduesUtilization} onChange={e=>setCultivationInterCropResiduesUtilization(e.target.value)}>
                {
                  cropResiduesUtilization.map((m, key) => {
                    return (<option key={key} value={m[0]}>{m[lang+1]}</option>
                  )})
                }
              </select>
            </div>
            </>
          }
        </div>
        <div className="pt-3 mt-5">
          <h6 className="text-left mt-5">{msg.GROUND_TREATMENTS}</h6><hr/>
        </div>
        <div className="row mb-5">
          <div style={{display: `${disPloughing}`}} className="col-md-6 text-left mt-5">
              <label>{msg.CROP_TREATMENT_PLOUGHING}</label>
              <input type="text" className="form-control" value={cropTreatmentPloughing} onChange={e=> setCropTreatmentPloughing(e.target.value)} onBlur={e => {updateErrorHook('cropTreatmentPloughing', e.target.value, setCropTreatmentPloughingErrorMessage)}}/>
              <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cropTreatmentPloughingErrorMessage}</div>
          </div>
          <div style={{display: `${disTilling}`}} className="col-md-6 text-left mt-5">
              <label>{msg.CROP_TREATMENT_TILLING}</label>
              <input type="text" className="form-control" value={cropTreatmentTilling} onChange={e=> setCropTreatmentTilling(e.target.value)} onBlur={e => {updateErrorHook('cropTreatmentTilling', e.target.value, setCropTreatmentTillingErrorMessage)}}/>
              <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cropTreatmentTillingErrorMessage}</div>
          </div>

          <div style={{display: `${disTreatmentCultivating}`}} className="col-md-6 text-left mt-5">
              <label>{msg.CROP_TREATMENT_CULTIVATING}</label>
              <input type="text" className="form-control" value={cropTreatmentCultivating} onChange={e=> setCropTreatmentCultivating(e.target.value)} onBlur={e => {updateErrorHook('cropTreatmentCultivating', e.target.value, setCropTreatmentCultivatingErrorMessage)}}/>
              <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cropTreatmentCultivatingErrorMessage}</div>
          </div>
          <div style={{display: `${disDiscing}`}} className="col-md-6 text-left mt-5">
              <label>{msg.CROP_TREATMENT_DISCING}</label>
              <input type="text" className="form-control" value={cropTreatmentDiscing} onChange={e=> setCropTreatmentDiscing(e.target.value)} onBlur={e => {updateErrorHook('cropTreatmentDiscing', e.target.value, setCropTreatmentDiscingErrorMessage)}}/>
              <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cropTreatmentDiscingErrorMessage}</div>
          </div>

          <div style={{display: `${disHarrowing}`}} className="col-md-6 text-left mt-5">
              <label>{msg.CROP_TREATMENT_HARROWING}</label>
              <input type="text" className="form-control" value={cropTreatmentHarrowing} onChange={e=> setCropTreatmentHarrowing(e.target.value)} onBlur={e => {updateErrorHook('cropTreatmentHarrowing', e.target.value, setCropTreatmentHarrowingErrorMessage)}}/>
              <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cropTreatmentHarrowingErrorMessage}</div>
          </div>
          <div style={{display: `${disMulching}`}} className="col-md-6 text-left mt-5">
              <label>{msg.CROP_TREATMENT_MULCHING}</label>
              <input type="text" className="form-control" value={cropTreatmentMulching} onChange={e=> setCropTreatmentMulching(e.target.value)} onBlur={e => {updateErrorHook('cropTreatmentMulching', e.target.value, setCropTreatmentMulchingErrorMessage)}}/>
              <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cropTreatmentMulchingErrorMessage}</div>
          </div>

          <div style={{display: `${disMachineCultivation}`}} className="col-md-6 text-left mt-5">
              <label>{msg.CROP_TREATMENT_MACHINE_CULTIVATION}</label>
              <input type="text" className="form-control" value={cropTreatmentMachineCultivation} onChange={e=> setCropTreatmentMachineCultivation(e.target.value)} onBlur={e => {updateErrorHook('cropTreatmentMachineCultivation', e.target.value, setCropTreatmentMachineCultivationErrorMessage)}}/>
              <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cropTreatmentMachineCultivationErrorMessage}</div>
          </div>
          <div style={{display: `${disSpreading}`}} className="col-md-6 text-left mt-5">
              <label>{msg.CROP_TREATMENT_MANURE_SPREADING}</label>
              <input type="text" className="form-control" value={cropTreatmentManureSpreading} onChange={e=> setCropTreatmentManureSpreading(e.target.value)} onBlur={e => {updateErrorHook('cropTreatmentManureSpreading', e.target.value, setCropTreatmentManureSpreadingErrorMessage)}}/>
              <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cropTreatmentManureSpreadingErrorMessage}</div>
          </div>

          <div style={{display: `${disFertilization}`}} className="col-md-6 text-left mt-5">
              <label>{msg.CROP_TREATMENT_FERTILIZATION}</label>
              <input type="text" className="form-control" value={cropTreatmentFertilization} onChange={e=> setCropTreatmentFertilization(e.target.value)} onBlur={e => {updateErrorHook('cropTreatmentFertilization', e.target.value, setCropTreatmentFertilizationErrorMessage)}}/>
              <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cropTreatmentFertilizationErrorMessage}</div>
          </div>
          <div style={{display: `${disSeeding}`}} className="col-md-6 text-left mt-5">
              <label>{msg.CROP_TREATMENT_SEEDING}</label>
              <input type="text" className="form-control" value={cropTreatmentSeeding} onChange={e=> setCropTreatmentSeeding(e.target.value)} onBlur={e => {updateErrorHook('cropTreatmentSeeding', e.target.value, setCropTreatmentSeedingErrorMessage)}}/>
              <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cropTreatmentSeedingErrorMessage}</div>
          </div>

          <div style={{display: `${disMachineSeeding}`}} className="col-md-6 text-left mt-5">
              <label>{msg.CROP_TREATMENT_MACHINE_SEEDING}</label>
              <input type="text" className="form-control" value={cropTreatmentMachineSeeding} onChange={e=> setCropTreatmentMachineSeeding(e.target.value)} onBlur={e => {updateErrorHook('cropTreatmentMachineSeeding', e.target.value, setCropTreatmentMachineSeedingErrorMessage)}}/>
              <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cropTreatmentMachineSeedingErrorMessage}</div>
          </div>
          <div style={{display: `${disPlanting}`}} className="col-md-6 text-left mt-5">
              <label>{msg.CROP_TREATMENT_PLANTING}</label>
              <input type="text" className="form-control" value={cropTreatmentPlanting} onChange={e=> setCropTreatmentPlanting(e.target.value)} onBlur={e => {updateErrorHook('cropTreatmentPlanting', e.target.value, setCropTreatmentPlantingErrorMessage)}}/>
              <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cropTreatmentPlantingErrorMessage}</div>
          </div>

          <div style={{display: `${disCovering}`}} className="col-md-6 text-left mt-5">
            <label>{msg.CROP_TREATMENT_COVERING}</label>
            <input type="text" className="form-control" value={cropTreatmentCovering} onChange={e=> setCropTreatmentCovering(e.target.value)} onBlur={e => {updateErrorHook('cropTreatmentCovering', e.target.value, setCropTreatmentCoveringErrorMessage)}}/>
            <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cropTreatmentCoveringErrorMessage}</div>
          </div>

          <div style={{display: `${disSpraying}`}} className="col-md-6 text-left mt-5">
            <label>{msg.CROP_TREATMENT_SPRAYING}</label>
            <input type="text" className="form-control" value={cropTreatmentSpraying} onChange={e=> setCropTreatmentSpraying(e.target.value)} onBlur={e => {updateErrorHook('cropTreatmentSpraying', e.target.value, setCropTreatmentSprayingErrorMessage)}}/>
            <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cropTreatmentSprayingErrorMessage}</div>
          </div>

          <div style={{display: `${disMachineMowing}`}} className="col-md-6 text-left mt-5">
            <label>{msg.CROP_TREATMENT_MACHINE_MOWING}</label>
            <input type="text" className="form-control" value={cropTreatmentMachineMowing} onChange={e=> setCropTreatmentMachineMowing(e.target.value)} onBlur={e => {updateErrorHook('cropTreatmentMachineMowing', e.target.value, setCropTreatmentMachineMowingErrorMessage)}}/>
            <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cropTreatmentMachineMowingErrorMessage}</div>
          </div>

          <div style={{display: `${disHarvesterMowing}`}} className="col-md-6 text-left mt-5">
            <label>{msg.CROP_TREATMENT_HARVESTER_MOWING}</label>
            <input type="text" className="form-control" value={cropTreatmentHarvesterMowing} onChange={e=> setCropTreatmentHarvesterMowing(e.target.value)} onBlur={e => {updateErrorHook('cropTreatmentHarvesterMowing', e.target.value, setCropTreatmentHarvesterMowingErrorMessage)}}/>
            <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cropTreatmentHarvesterMowingErrorMessage}</div>
          </div>

          <div style={{display: `${disRaking}`}} className="col-md-6 text-left mt-5">
            <label>{msg.CROP_TREATMENT_RAKING}</label>
            <input type="text" className="form-control" value={cropTreatmentRaking} onChange={e=> setCropTreatmentRaking(e.target.value)} onBlur={e => {updateErrorHook('cropTreatmentRaking', e.target.value, setCropTreatmentRakingErrorMessage)}}/>
            <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cropTreatmentRakingErrorMessage}</div>
          </div>

          <div style={{display: `${disPressing}`}} className="col-md-6 text-left mt-5">
            <label>{msg.CROP_TREATMENT_PRESSING}</label>
            <input type="text" className="form-control" value={cropTreatmentPressing} onChange={e=> setCropTreatmentPressing(e.target.value)} onBlur={e => {updateErrorHook('cropTreatmentPressing', e.target.value, setCropTreatmentPressingErrorMessage)}}/>
            <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cropTreatmentPressingErrorMessage}</div>
          </div>

          <div style={{display: `${disStrawHarvesting}`}} className="col-md-6 text-left mt-5">
            <label>{msg.CROP_TREATMENT_STRAW_HARVESTING}</label>
            <input type="text" className="form-control" value={cropTreatmentStrawHarvesting} onChange={e=> setCropTreatmentStrawHarvesting(e.target.value)} onBlur={e => {updateErrorHook('cropTreatmentStrawHarvesting', e.target.value, setCropTreatmentStrawHarvestingErrorMessage)}}/>
            <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cropTreatmentStrawHarvestingErrorMessage}</div>
          </div>

          <div style={{display: `${disCerealHarvesting}`}} className="col-md-6 text-left mt-5">
            <label>{msg.CROP_TREATMENT_CEREAL_HARVESTING}</label>
            <input type="text" className="form-control" value={cropTreatmentCerealHarvesting} onChange={e=> setCropTreatmentCerealHarvesting(e.target.value)} onBlur={e => {updateErrorHook('cropTreatmentCerealHarvesting', e.target.value, setCropTreatmentCerealHarvestingErrorMessage)}}/>
            <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cropTreatmentCerealHarvestingErrorMessage}</div>
          </div>

          <div style={{display: `${disPotatoHarvesting}`}} className="col-md-6 text-left mt-5">
            <label>{msg.CROP_TREATMENT_POTATO_HARVESTING}</label>
            <input type="text" className="form-control" value={cropTreatmentPotatoHarvesting} onChange={e=> setCropTreatmentPotatoHarvesting(e.target.value)} onBlur={e => {updateErrorHook('cropTreatmentPotatoHarvesting', e.target.value, setCropTreatmentPotatoHarvestingErrorMessage)}}/>
            <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cropTreatmentPotatoHarvestingErrorMessage}</div>
          </div>

          <div style={{display: `${disPotatoMachineHarvesting}`}} className="col-md-6 text-left mt-5">
            <label>{msg.CROP_TREATMENT_POTATO_MACHINE_HARVESTING}</label>
            <input type="text" className="form-control" value={cropTreatmentPotatoMachineHarvesting} onChange={e=> setCropTreatmentPotatoMachineHarvesting(e.target.value)} onBlur={e => {updateErrorHook('cropTreatmentPotatoMachineHarvesting', e.target.value, setCropTreatmentPotatoMachineHarvestingErrorMessage)}}/>
            <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cropTreatmentPotatoMachineHarvestingErrorMessage}</div>
          </div>

          <div style={{display: `${disBeetHarvesting}`}} className="col-md-6 text-left mt-5">
            <label>{msg.CROP_TREATMENT_BEET_HARVESTING}</label>
            <input type="text" className="form-control" value={cropTreatmentBeetHarvesting} onChange={e=> setCropTreatmentBeetHarvesting(e.target.value)} onBlur={e => {updateErrorHook('cropTreatmentBeetHarvesting', e.target.value, setCropTreatmentBeetHarvestingErrorMessage)}}/>
            <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cropTreatmentBeetHarvestingErrorMessage}</div>
          </div>

          <div style={{display: `${disBeetMachineHarvesting}`}} className="col-md-6 text-left mt-5">
            <label>{msg.CROP_TREATMENT_BEET_MACHINE_HARVESTING}</label>
            <input type="text" className="form-control" value={cropTreatmentBeetMachineHarvesting} onChange={e=> setCropTreatmentBeetMachineHarvesting(e.target.value)} onBlur={e => {updateErrorHook('cropTreatmentBeetMachineHarvesting', e.target.value, setCropTreatmentBeetMachineHarvestingErrorMessage)}}/>
            <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cropTreatmentBeetMachineHarvestingErrorMessage}</div>
          </div>

          <div style={{display: `${disRootVegetableHarvesting}`}} className="col-md-6 text-left mt-5">
            <label>{msg.CROP_TREATMENT_ROOT_VEGETABLE_HARVESTING}</label>
            <input type="text" className="form-control" value={cropTreatmentRootVegetableHarvesting} onChange={e=> setCropTreatmentRootVegetableHarvesting(e.target.value)} onBlur={e => {updateErrorHook('cropTreatmentRootVegetableHarvesting', e.target.value, setCropTreatmentRootVegetableHarvestingErrorMessage)}}/>
            <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cropTreatmentRootVegetableHarvestingErrorMessage}</div>
          </div>

          <div style={{display: `${disRootMachineVegetableHarvesting}`}} className="col-md-6 text-left mt-5">
            <label>{msg.CROP_TREATMENT_ROOT_VEGETABLE_MACHINE_HARVESTING}</label>
            <input type="text" className="form-control" value={cropTreatmentRootVegetableMachineHarvesting} onChange={e=> setCropTreatmentRootVegetableMachineHarvesting(e.target.value)} onBlur={e => {updateErrorHook('cropTreatmentRootVegetableMachineHarvesting', e.target.value, setCropTreatmentRootVegetableMachineHarvestingErrorMessage)}}/>
            <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cropTreatmentRootVegetableMachineHarvestingErrorMessage}</div>
          </div>

          <div style={{display: `${disRootVegetableManualHarvesting}`}} className="col-md-6 text-left mt-5">
            <label>{msg.CROP_TREATMENT_ROOT_VEGETABLE_MANUAL_HARVESTING}</label>
            <input type="text" className="form-control" value={cropTreatmentVegetableManualHarvesting} onChange={e=> setCropTreatmentVegetableManualHarvesting(e.target.value)} onBlur={e => {updateErrorHook('cropTreatmentRootVegetableManualHarvesting', e.target.value, setCropTreatmentRootVegetableManualHarvestingErrorMessage)}}/>
            <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cropTreatmentRootVegetableManualHarvestingErrorMessage}</div>
          </div>
          <div style={{display: `${disRootFruitManualHarvesting}`}} className="col-md-6 text-left mt-5">
            <label>{msg.CROP_TREATMENT_ROOT_FRUIT_MANUAL_HARVESTING}</label>
            <input type="text" className="form-control" value={cropTreatmentFruitManualHarvesting} onChange={e=> setCropTreatmentFruitManualHarvesting(e.target.value)} onBlur={e => {updateErrorHook('cropTreatmentFruitManualHarvesting', e.target.value, setCropTreatmentFruitManualHarvestingErrorMessage)}}/>
            <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{cropTreatmentFruitManualHarvestingErrorMessage}</div>
          </div>
            </div>
            <div className="pt-3 mt-5">
              <h6 className="text-left mt-5">{msg.NATURAL_FERTILIZERS_USE}</h6><hr/>
            </div>
            <div className="row mb-5">
            <div className="col-md-6 text-left mt-5">
              <label>{msg.MANURE_FRESH} *</label>
              <input type="text" className="form-control" value={manureFresh} onChange={e=> setManureFresh(e.target.value)} onBlur={e => {updateErrorHook('manureFresh', e.target.value, setManureFreshErrorMessage)}}/>
              <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{manureFreshErrorMessage}</div>
            </div>
            <div className="col-md-6 text-left mt-5">
              <label>{msg.MANURE_DRY} *</label>
              <input type="text" className="form-control" value={manureDry} onChange={e=> setManureDry(e.target.value)} onBlur={e => {updateErrorHook('manureDry', e.target.value, setManureDryErrorMessage)}}/>
              <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{manureDryErrorMessage}</div>
            </div>
            <div className="col-md-6 text-left mt-5">
              <label>{msg.MANURE_FERMENTED} *</label>
              <input type="text" className="form-control" value={manureFermented} onChange={e=> setManureFermented(e.target.value)} onBlur={e => {updateErrorHook('manureFermented', e.target.value, setManureFermentedErrorMessage)}}/>
              <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{manureFermentedErrorMessage}</div>
            </div>
            <div className="col-md-6 text-left mt-5">
              <label>{msg.MANURE_USED} *</label>
              <input type="text" className="form-control" value={manure} onChange={e=> setManure(e.target.value)} onBlur={e => {updateErrorHook('manure', e.target.value, setManureErrorMessage)}}/>
              <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{manureErrorMessage}</div>
            </div>

            </div>
            <div className="pt-3 mt-5">
              <h6 className="text-left mt-5">{msg.MINERAL_FERTILIZERS_USE}</h6><hr/>
            </div>
            <div className="row mb-5">
            <div className="col-md-6 text-left mt-5">
              <label>{msg.FERTILIZER_NITROGEN} *</label>
              <input type="text" className="form-control" value={fertilizerNitrogen} onChange={e=> setFertilizerNitrogen(e.target.value)} onBlur={e => {updateErrorHook('fertilizerNitrogen', e.target.value, setFertilizerNitrogenErrorMessage)}}/>
              <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{fertilizerNitrogenErrorMessage}</div>
            </div>
            <div className="col-md-6 text-left mt-5">
              <label>{msg.FERTILIZER_PHOSPHORUS} *</label>
              <input type="text" className="form-control" value={fertilizerPhosphorus} onChange={e=> setFertilizerPhosphorus(e.target.value)} onBlur={e => {updateErrorHook('fertilizerPhosphorus', e.target.value, setFertilizerPhosphorusErrorMessage)}}/>
              <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{fertilizerPhosphorusErrorMessage}</div>
            </div>
            <div className="col-md-6 text-left mt-5">
              <label>{msg.FERTILIZER_POTASSIUM} *</label>
              <input type="text" className="form-control" value={fertilizerPotassium} onChange={e=> setFertilizerPotassium(e.target.value)} onBlur={e => {updateErrorHook('fertilizerPotassium', e.target.value, setFertilizerPotassiumErrorMessage)}}/>
              <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{fertilizerPotassiumErrorMessage}</div>
            </div>
            <div className="col-md-6 text-left mt-5">
              <label>{msg.FERTILIZER_CALCIUM} *</label>
              <input type="text" className="form-control" value={fertilizerCalcium} onChange={e=> setFertilizerCalcium(e.target.value)} onBlur={e => {updateErrorHook('fertilizerCalcium', e.target.value, setFertilizerCalciumErrorMessage)}}/>
              <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{fertilizerCalciumErrorMessage}</div>
            </div>
            <div className="col-md-6 text-left mt-5">
              <label>{msg.FERTILIZER_MAGNESIUM} *</label>
              <input type="text" className="form-control" value={fertilizerMagnesium} onChange={e=> setFertilizerMagnesium(e.target.value)} onBlur={e => {updateErrorHook('fertilizerMagnesium', e.target.value, setFertilizerMagnesiumErrorMessage)}}/>
              <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{fertilizerMagnesiumErrorMessage}</div>
            </div>
            <div className="col-md-6 text-left mt-5">
              <label>{msg.FERTILIZER_SULFUR} *</label>
              <input type="text" className="form-control" value={fertilizerSulfur} onChange={e=> setFertilizerSulfur(e.target.value)} onBlur={e => {updateErrorHook('fertilizerSulfur', e.target.value, setFertilizerSulfurErrorMessage)}}/>
              <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{fertilizerSulfurErrorMessage}</div>
            </div>
            <div className="col-md-6 text-left mt-5">
              <label>{msg.FERTILIZER_MICRONUTRIENT} *</label>
              <input type="text" className="form-control" value={fertilizerMicronutrient} onChange={e=> setFertilizerMicronutrient(e.target.value)} onBlur={e => {updateErrorHook('fertilizerMicronutrient', e.target.value, setFertilizerMicronutrientErrorMessage)}}/>
              <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{fertilizerMicronutrientErrorMessage}</div>
            </div>

            </div>
            <div className="pt-3 mt-5">
              <h6 className="text-left mt-5">{msg.SPRAYING_USE}</h6><hr/>
            </div>
            <div className="row mb-5">
            <div className="col-md-6 text-left mt-5">
              <label>{msg.SPRAYING_PESTICIDE} *</label>
              <input type="text" className="form-control" value={sprayingPesticide} onChange={e=> setSprayingPesticide(e.target.value)} onBlur={e => {updateErrorHook('sprayingPesticide', e.target.value, setSprayingPesticideErrorMessage)}}/>
              <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{sprayingPesticideErrorMessage}</div>
            </div>
            <div className="col-md-6 text-left mt-5">
                <label>{msg.SPRAYING_BIOSTIMULATOR} *</label>
                <input type="text" className="form-control" value={sprayingBioStimulator} onChange={e=> setSprayingBioStimulator(e.target.value)} onBlur={e => {updateErrorHook('sprayingBioStimulator', e.target.value, setSprayingBioStimulatorErrorMessage)}}/>
                <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{sprayingBioStimulatorErrorMessage}</div>
            </div>
            <div className="col-md-6 text-left mt-5">
                <label>{msg.SPRAYING_FOLIAR} *</label>
                <input type="text" className="form-control" value={sprayingFoliar} onChange={e=> setSprayingFoliar(e.target.value)} onBlur={e => {updateErrorHook('sprayingFoliar', e.target.value, setSprayingFoliarErrorMessage)}}/>
                <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{sprayingFoliarErrorMessage}</div>
            </div>
              <div className="col-md-6 text-left mt-5">
            <label>{msg.SPRAYING_NITROGEN_FIXATION} *</label>
                <select className="form-control" value={isSprayingNitrogenFixation} onChange={e=>setIsSprayingNitrogenFixation(e.target.value)}>
                  <option value={true}>{msg.YES}</option>
                  <option value={false}>{msg.NO}</option>
              </select>
            </div>
            </div>
            <div className="pt-3 mt-5">
              <h6 className="text-left mt-5">{msg.TRANSPORT}</h6><hr/>
            </div>
            <div className="row mb-5">
            <div className="col-md-6 text-left mt-5">
              <label>{msg.TRANSPORT_YIELD} *</label>
              <input type="text" className="form-control" value={transportYield} onChange={e=> setTransportYield(e.target.value)} onBlur={e => {updateErrorHook('transportYield', e.target.value, setTransportYieldErrorMessage)}}/>
              <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{transportYieldErrorMessage}</div>
            </div>
            <div className="col-md-6 text-left mt-5">
                <label>{msg.TRANSPORT_DISTANCE} *</label>
                <input type="text" className="form-control" value={transportDistance} onChange={e=> setTransportDistance(e.target.value)} onBlur={e => {updateErrorHook('transportDistance', e.target.value, setTransportDistanceErrorMessage)}}/>
                <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{transportDistanceErrorMessage}</div>
            </div>
            <div className="col-md-6 text-left mt-5">
                <label>{msg.TRANSPORT_STORAGE_WEIGHT} *</label>
                <input type="text" className="form-control" value={transportStorageWeight} onChange={e=> setTransportStorageWeight(e.target.value)} onBlur={e => {updateErrorHook('transportStorageWeight', e.target.value, setTransportStorageWeightErrorMessage)}}/>
                <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{transportStorageWeightErrorMessage}</div>
            </div>
            <div className="col-md-6 text-left mt-5">
                <label>{msg.TRANSPORT_COLD_STORAGE_WEIGHT} *</label>
                <input type="text" className="form-control" value={transportColdStorageWeight} onChange={e=> setTransportColdStorageWeight(e.target.value)} onBlur={e => {updateErrorHook('transportColdStorageWeight', e.target.value, setTransportColdStorageWeightErrorMessage)}}/>
                <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{transportColdStorageWeightErrorMessage}</div>
            </div>
            {transportColdStorageWeight > 0 && <div className="col-md-6 text-left mt-5">
                <label>{msg.TRANSPORT_COLD_STORAGE_TIME} *</label>
                <input type="text" className="form-control" value={transportColdStorageTime} onChange={e=> setTransportColdStorageTime(e.target.value)} onBlur={e => {updateErrorHook('transportColdStorageTime', e.target.value, setTransportColdStorageTimeErrorMessage)}}/>
                <div style={{color: 'red', position: 'relative' ,top: '5px', left: '3px', marginBottom: '10px'}}>{transportColdStorageTimeErrorMessage}</div>
            </div>}
            </div>

            <div className="row">
              <div className="col-md-12 mt-5">
                <input type="button" value={msg.CALCULATORS_BUTTON} className="btn btn-primary" onClick={() => calculate()} />
              </div>
            </div>
            {results &&  <>
              <div className="pt-3 mt-5">
                <h6 className="text-left mt-5">{msg.CO2_BALANCE}</h6><hr/>
              </div>
              <div className="row mb-5">
                <div className="col-md-6 text-left mt-3">
                  <strong>{msg.BALANCE}</strong>
                </div>
                <div className="col-md-6 text-left mt-3">
                  {results.carbonBilans > 0 && <strong>+</strong>}
                  <strong>{results.carbonBilans.toFixed(2)}</strong> [t CO2/ha]
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-6 text-left mt-3">
                  <strong>{msg.CARBON_TOTAL}</strong>
                </div>
                <div className="col-md-6 text-left mt-3">
                  <strong>+{results.carbonTotal.toFixed(2)}</strong> [t CO2/ha]
                </div>
              </div>
              <div style={{paddingLeft: "15px"}}>
                <div className="row mb-5">
                  <div className="col-md-6 text-left mt-3">
                    <strong>{msg.CARBON_FROM_CROP_YIELD}</strong>
                  </div>
                  <div className="col-md-6 text-left mt-3">
                    <strong>+{results.carbonFromCropYield.toFixed(2)}</strong> [t CO2/ha]
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-md-6 text-left mt-3">
                    <strong>{msg.CARBON_SOIL}</strong>
                  </div>
                  <div className="col-md-6 text-left mt-3">
                    <strong>+{results.carbonFromSoil.toFixed(2)}</strong> [t CO2/ha]
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-md-6 text-left mt-3">
                    <strong>{msg.CARBON_RESIDUES}</strong>
                  </div>
                  <div className="col-md-6 text-left mt-3">
                    <strong>+{results.carbonFromResidues.toFixed(2)}</strong> [t CO2/ha]
                  </div>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-6 text-left mt-3">
                  <strong>{msg.CARBON_EMISSION_TOTAL}</strong>
                </div>
                <div className="col-md-6 text-left mt-3">
                  <strong>-{results.carbonEmissionTotal.toFixed(2)}</strong> [t CO2/ha]
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-6 text-left mt-3">
                  <strong>{msg.HUMUS_INCREASE}</strong>
                </div>
                <div className="col-md-6 text-left mt-3">
                  <strong>+{results.humusIncrease.toFixed(2)}</strong> [t/ha]
                </div>
              </div>
              <div className="pt-3 mt-5">
                <h6 className="text-left mt-5">{msg.N_BALANCE}</h6><hr/>
              </div>
              <div className="row mb-5">
                <div className="col-md-6 text-left mt-3">
                  <strong>{msg.BALANCE}</strong>
                </div>
                <div className="col-md-6 text-left mt-3">
                  {results.nitrogenBilans > 0 && <strong>+</strong>}
                  <strong>{results.nitrogenBilans.toFixed(2)}</strong> {msg.N_VALUES}
                </div>
              </div>
              <div style={{paddingLeft: "15px"}}>
                <div className="row mb-5">
                  <div className="col-md-6 text-left mt-3">
                    <strong>{msg.NITROGEN_FERTILIZER}</strong>
                  </div>
                  <div className="col-md-6 text-left mt-3">
                    <strong>+{results.nitrogenFromFertilizer.toFixed(2)}</strong> {msg.N_VALUES}
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-md-6 text-left mt-3">
                    <strong>{msg.NITROGEN_SOIL}</strong>
                  </div>
                  <div className="col-md-6 text-left mt-3">
                    <strong>+{results.nitrogenFormSoil.toFixed(2)}</strong> {msg.N_VALUES}
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-md-6 text-left mt-3">
                    <strong>{msg.NITROGEN_FROM_CROP}</strong>
                  </div>
                  <div className="col-md-6 text-left mt-3">
                    <strong>+{results.nitrogenFromCrop.toFixed(2)}</strong> {msg.N_VALUES}
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-md-6 text-left mt-3">
                    <strong>{msg.NITROGEN_FROM_RAIN}</strong>
                  </div>
                  <div className="col-md-6 text-left mt-3">
                    <strong>+{results.nitrogenFromRain.toFixed(2)}</strong> {msg.N_VALUES}
                  </div>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-6 text-left mt-3">
                  <strong>{msg.NITROGEN_IN_CROP}</strong>
                </div>
                <div className="col-md-6 text-left mt-3">
                  <strong>-{results.nitrogenInCrop.toFixed(2)}</strong> {msg.KG_HA_YEAR}
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-6 text-left mt-3">
                  <strong>{msg.NITROGEN_IN_RESIDUES}</strong>
                </div>
                <div className="col-md-6 text-left mt-3">
                  <strong>-{results.nitrogenInResidues.toFixed(2)}</strong> {msg.KG_HA_YEAR}
                </div>
              </div>
              <div className="pt-3 mt-5">
                <h6 className="text-left mt-5">{msg.CULTIVATION_INF}</h6><hr/>
              </div>
              <div className="row mb-5">
                <div className="col-md-6 text-left mt-3">
                  <strong>{msg.CROP_TOTAL_WEIGHT}</strong>
                </div>
                <div className="col-md-6 text-left mt-3">
                  <strong>{results.cropTotalWeight.toFixed(2)}</strong> [t/ha]
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-6 text-left mt-3">
                  <strong>{msg.CROP_WEIGHT}</strong>
                </div>
                <div className="col-md-6 text-left mt-3">
                  <strong>{results.cropWeight.toFixed(2)}</strong> [t/ha]
                </div>
              </div>
              {/* <div className="row mb-5">
                <div className="col-md-6 text-left mt-3">
                  <strong>{msg.WATER_CONTENT_IN_CROP}</strong>
                </div>
                <div className="col-md-6 text-left mt-3">
                  <strong>{results.cropWaterContent.toFixed(2)}</strong> [%]
                </div>
              </div> */}
              <div className="row mb-5">
                <div className="col-md-6 text-left mt-3">
                  <strong>{msg.CROP_DRY_WEIGHT}</strong>
                </div>
                <div className="col-md-6 text-left mt-3">
                  <strong>{results.cropDryWeight.toFixed(2)}</strong> [t/ha]
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-6 text-left mt-3">
                  <strong>{msg.RESIDUES_WEIGHT}</strong>
                </div>
                <div className="col-md-6 text-left mt-3">
                  <strong>{results.residuesWeight.toFixed(2)}</strong> [t/ha]
                </div>
              </div>
              {/* <div className="row mb-5">
                <div className="col-md-6 text-left mt-3">
                  <strong>{msg.RESIDUES_WATER_CONTENT}</strong>
                </div>
                <div className="col-md-6 text-left mt-3">
                  <strong>{results.residuesWaterContent.toFixed(2)}</strong> [%]
                </div>
              </div> */}
              <div className="row mb-5">
                <div className="col-md-6 text-left mt-3">
                  <strong>{msg.RESIDUES_DRY_WEIGHT}</strong>
                </div>
                <div className="col-md-6 text-left mt-3">
                  <strong>{results.residuesDryWeight.toFixed(2)}</strong> [t/ha]
                </div>
              </div>
              </>
            }
        </div>
      </CardBody>
    </Card>
  );
}
