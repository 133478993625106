import { combineReducers } from "redux";
import * as auth from "../app/modules/Auth/_redux/authRedux";
import { subscriptionsSlice } from "../app/modules/Data/subscriptions/subscriptionsSlice";
import { affiliateSlice } from "../app/modules/Data/subscriptions/affiliateSlice";


export const rootReducer = combineReducers({
  auth: auth.reducer,
  subscriptions: subscriptionsSlice.reducer,
  affiliate: affiliateSlice.reducer
});