import { MsalAuthProvider, LoginType } from "react-aad-msal";
const tenant = "farmsmartuserdev.onmicrosoft.com";
const signInPolicy = "B2C_1_FarmSmartDEV";
const applicationID = "09354d56-898b-48cc-90c9-4c1021d6f764";
const tenantSubdomain = tenant.split(".")[0];
const instance = `https://${tenantSubdomain}.b2clogin.com/`;
const signInAuthority = `${instance}${tenant}/${signInPolicy}`;
// Msal Configurations
const signInConfig = {
  auth: {
    authority: signInAuthority,
    clientId: applicationID,
    redirectUri: window.location.origin,
    knownAuthorities: ['farmsmartuserdev.b2clogin.com'],
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true
  }
};
// Authentication Parameters
const authenticationParameters = {
  scopes: [
    "https://farmsmartuserdev.onmicrosoft.com/4e54b0bd-7acd-4b41-8068-f40c34f42821/framsmartuserdev_api_scope",
  ]
};
// Options
const options = {
  loginType: LoginType.Redirect,
};
export const signInAuthProvider = new MsalAuthProvider(
  signInConfig,
  null,
  options
);