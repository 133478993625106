/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import * as actions from "./subscriptionsActions";
import { shallowEqual, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useSubheader } from "../../../../_metronic/layout";
import { useIntl, FormattedHTMLMessage } from "react-intl";
import { useLang } from "../../../../_metronic/i18n/Metronici18n";
import { Button, Navbar } from "react-bootstrap";
import { DateTime, Interval } from 'luxon';
import DatePicker, { registerLocale } from "react-datepicker";
import timezoneHelper from '../../Shared/Helpers/timezoneHelper';
import { toast } from 'react-toastify';
import InsectOccurrencesPercentBarChart from "../../Shared/Charts/insectOccurencesPercentBarChart";
import { Alert } from "react-bootstrap";
import languages from "../../Shared/Enums/languages";
import FastNavigationBar from "../../Shared/Components/fastNavigationBar";

export default function LocationCultivationInsectOccurrencesPage({
  history,
  match: {
    params: { stationId, cultivationId },
  }
}) {
  const subheader = useSubheader();
  const dispatch = useDispatch();

  const langName = useLang();
  const intl = useIntl();
  const { messages: msg } = intl;
  const lang = languages[langName];

  const [title, setTitle] = useState(msg.LOADING_DATA);
  const [dateTo, setDateTo] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [stationName, setStationName] = useState('');

  registerLocale('currentDatePickerLang', {
    localize: {
      month: n => msg.MONTHS[n],
      day: n => msg.DAYS[n]
    },
    options: {
      weekStartsOn: 1
      /* Monday */
      ,
      firstWeekContainsDate: 2
    },
    formatLong: {}
  });

  const { stationCultivationInsectOccurrences } = useSelector(
    (state) => ({
      actionsLoading: state.subscriptions.actionsLoading,
      stationCultivationInsectOccurrences: state.subscriptions.stationCultivationInsectOccurrences
    }),
    shallowEqual
  );

  useEffect(() => {
    if (stationCultivationInsectOccurrences) {
      setStationName(stationCultivationInsectOccurrences.stationName);
    }
  }, [stationCultivationInsectOccurrences]);

  useEffect(() => {
    let _title = msg.SUBSCRIPTION_INSECT_OCCURRENCES;

    if (stationCultivationInsectOccurrences) {
      setTitle(`${_title} - ${stationCultivationInsectOccurrences.cultivationNames[lang]} - ${stationName}`);
    }

    subheader.setTitle(_title);
  }, [stationCultivationInsectOccurrences, []]);

  useEffect(async () => {
    let dateFrom = DateTime.local().minus({ days: 7 });
    let newDateFrom = timezoneHelper.toCosmosUTC(dateFrom);
    localStorage.setItem('dateFrom', newDateFrom.dateString);
    setDateFrom(new Date(newDateFrom.dateString));

    let dateTo = DateTime.local();
    let newDateTo = timezoneHelper.toCosmosUTC(dateTo);
    localStorage.setItem('dateTo', newDateTo.dateString);
    setDateTo(new Date(newDateTo.dateString));
    fetchInsectOccurrencesInfo()
  }, []);

  const backToStationCultivationsInsects = () => {
    history.push(`/locations/${stationId}/insectOccurrences/cultivations`);
  };

  const setDateFromToStorage = dateFrom => {
    setDateFrom(dateFrom);
    localStorage.setItem('dateFrom', dateFrom.toJSON());
  }

  const setDateToToStorage = dateTo => {
    setDateTo(dateTo);
    localStorage.setItem('dateTo', dateTo.toJSON());
  
  }

  const fetchInsectOccurrencesInfo = async () => {
    let storageDateFrom = localStorage.getItem('dateFrom');
    let storageDateTo =  localStorage.getItem('dateTo');

    let days = Interval.fromDateTimes(new Date(storageDateFrom.substring(0, 10)), new Date(storageDateTo.substring(0, 10)));

    if (days.length("days") <= 183) {
     dispatch(actions.fetchStationCultivationInsectOccurences(stationId, cultivationId, storageDateFrom, storageDateTo));
    } else {
      toast.error(msg.TOO_LARGE_DATE_RANGE);
    }
   
  }

  return (
    <Card>
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToStationCultivationsInsects}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            {msg.MENU_RETURN}
          </button>
          {`  `}
          <FastNavigationBar stationId={stationId} section="Occurrences" history={history} />
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
      <ul style={{fontSize: '16px'}} className="mb-5">
          <FormattedHTMLMessage id="INSECTS_OCCURRENCES_INFO" />
      </ul>
      <Navbar className="bg-light pt-5 pb-5">
          <DatePicker
            locale="currentDatePickerLang"
            selected={dateFrom}
            dateFormat="yyyy-MM-dd"
            onChange={dateFrom => setDateFromToStorage(dateFrom)}
            className="form-control mr-5"
            popperPlacement="bottom-start"
            onKeyDown={(event) => {
              event.preventDefault();
            }}
          />

          <DatePicker
            locale="currentDatePickerLang"
            selected={dateTo}
            dateFormat="yyyy-MM-dd"
            onChange={dateTo => setDateToToStorage(dateTo)}
            className="form-control ml-2"
            popperPlacement="bottom-start"
            onKeyDown={(event) => {
              event.preventDefault();
            }}
          />

          <Button className="btn-primary btn-sm ml-4" onClick={() => fetchInsectOccurrencesInfo()}>
            {'OK'}
          </Button>
          {
            <div style={{ width: '100%', textAlign: 'right' }} className="text-right">
            </div>}
        </Navbar>
        <div className="mt-5 text-center">
        {!stationCultivationInsectOccurrences && <Alert variant="warning" className="text-center" style={{ fontSize: 14 }}>{msg.LOADING_DATA}</Alert>}
            {stationCultivationInsectOccurrences?.insects && stationCultivationInsectOccurrences.insects.map(insect => {
              let occurrences = stationCultivationInsectOccurrences.insectOccurrences.filter(insectOccurrence => insectOccurrence.insectId == insect.id);     
              let { insectOccurrencesPrevious } = stationCultivationInsectOccurrences;
              if (!occurrences?.length >0) return;
              return <>
              {/* <InsectOccurrencesBarChart occurrences={occurrences} occurrencesPrevious={insectOccurrencesPrevious} insect={insect} key={insect.id} cultivationName={stationCultivationInsectOccurrences.cultivationNames[lang]} stationName={stationCultivationInsectOccurrences.stationName} /> */}
              <InsectOccurrencesPercentBarChart occurrences={occurrences} occurrencesPrevious={insectOccurrencesPrevious} insect={insect} key={insect.id} cultivationName={stationCultivationInsectOccurrences.cultivationNames[lang]} stationName={stationCultivationInsectOccurrences.stationName} /></>
            })}
            {stationCultivationInsectOccurrences && !stationCultivationInsectOccurrences?.insects && <Alert variant="warning" className="text-center" style={{ fontSize: 14 }}>{msg.NO_INSECT_MONITORING_DATA}</Alert>}
        </div>
      </CardBody>
    </Card>
  );
}
