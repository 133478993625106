import React, { useEffect, useState, useRef } from "react";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import * as usersActions from "../../ECommerce/_redux/customers/customersActions";
import * as subscriptionsActions from "./subscriptionsActions";
import { EditUserForm } from "./userSettingsForm";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useSubheader } from "../../../../_metronic/layout";
import { toast } from 'react-toastify';
import { useIntl } from "react-intl";
import DatePicker, { registerLocale } from "react-datepicker";
import timezoneHelper from '../../Shared/Helpers/timezoneHelper';
import { Navbar, Button } from "react-bootstrap";
import  {DateTime} from 'luxon'

export default function AgentAffiliatePage({
  history,
  match: {
    params: { id },
  }
}) {

  registerLocale('currentDatePickerLang', {
    localize: {
      month: n => msg.MONTHS[n],
      day: n => msg.DAYS[n]
    },
    options: {
      weekStartsOn: 1
      /* Monday */
      ,
      firstWeekContainsDate: 2
    },
    formatLong: {}
  });

  const dispatch = useDispatch();
  const intl = useIntl();
  const { messages: msg } = intl;
  const [title, setTitle] = useState(msg["AGENT_INFO"]);
  const year =  new Date().getFullYear();
  const month = `${new Date().getMonth()<10?'0':''}${new Date().getMonth()+1}`;
  const day =  `${new Date().getDate()<10?'0':''}${new Date().getDate()}`;
  
  const { affiliateEarnings} = useSelector(
    (state) => ({
      affiliateEarnings: state.subscriptions.affiliateEarnings,
      error: state.subscriptions.error
    }),
    shallowEqual
  );

  const [dateFromString, setDateFromString] = useState(null);
  const [dateToString, setDateToString] = useState(null);

  const newDateFrom = timezoneHelper.toCosmosUTC(timezoneHelper.parseCosmosDateString(`${year}-01-01T00:00:00.000Z`, 'Europe/Warsaw'));
  const newDateTo = timezoneHelper.toCosmosUTC(timezoneHelper.parseCosmosDateString(`${year}-${'12-31'}T23:59:59.999`, 'Europe/Warsaw'));
  const [dateRangeFrom, setDateRangeFrom] = useState(new Date(newDateFrom.dateString));
  const [dateRangeTo, setDateRangeTo] = useState(new Date(newDateTo.dateString));
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (dateRangeFrom) {
      setDateFromString(DateTime.fromJSDate(dateRangeFrom, { zone: 'Europe/Warsaw' }).toFormat('yyyy-MM-dd'));
    }
  }, [dateRangeFrom]);

  useEffect(() => {
    if (dateRangeTo)
      setDateToString(DateTime.fromJSDate(dateRangeTo, { zone: 'Europe/Warsaw' }).toFormat('yyyy-MM-dd'));
  }, [dateRangeTo]);

  const fetchInfo = _ => {
    dispatch(subscriptionsActions.fetchAffiliateEarnings(dateFromString, dateToString)).then(setShow(true));
    
  }

  return (
    <Card>
      <CardHeader title={title}>
        <CardHeaderToolbar>
           <button
            type="button"
            onClick={()=> history.push(`/agentPanel`)}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            {msg["MENU_RETURN"]}
          </button>

        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
      <Navbar className="bg-light pt-5 pb-5">
          
          <DatePicker
            locale="currentDatePickerLang"
            selected={dateRangeFrom}
            dateFormat="yyyy-MM-dd"
            onChange={dateFrom => setDateRangeFrom(dateFrom)}
            className="form-control mr-5"
            popperPlacement="bottom-start"
            onKeyDown={(event) => {
              event.preventDefault();
            }}
          />

          <DatePicker
            locale="currentDatePickerLang"
            selected={dateRangeTo}
            dateFormat="yyyy-MM-dd"
            onChange={dateTo => setDateRangeTo(dateTo)}
            className="form-control ml-2"
            popperPlacement="bottom-start"
            onKeyDown={(event) => {
              event.preventDefault();
            }}
          />

          <Button className="btn-primary btn-sm ml-4" onClick={() => {fetchInfo()}}>
            {'OK'}
          </Button>
        </Navbar>
        <div className="mt-5">
            {show && 
              <div>
                Okres: <strong>{affiliateEarnings?.dateFrom}</strong> do <strong>{affiliateEarnings?.dateTo}</strong><br/>
                Wartość wykupionych subskrypcji brutto: <strong>{affiliateEarnings?.sum} PLN</strong> (zarobki Partnera wedle ustalonej stawki %).<br/><br/>
                Liczba wszystkich użytkowników poleconych od początku: <strong>{affiliateEarnings?.usersCount}</strong>.
              </div>
            }
        </div>
      </CardBody>
    </Card>
  );
}
