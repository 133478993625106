import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "./subscriptionsActions";
import { shallowEqual, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useSubheader } from "../../../../_metronic/layout";
import { useIntl, FormattedHTMLMessage } from "react-intl";
import { useLang } from "../../../../_metronic/i18n/Metronici18n";
import languages from "../../Shared/Enums/languages";
import { Button, Navbar, Badge } from "react-bootstrap";
import DiseaseLineChart from '../../Shared/Charts/diseaseLineChart';
import DiseaseLineMultiChart from "../../Shared/Charts/diseaseLineMultiChart";
import DatePicker, { registerLocale } from "react-datepicker";
import { DateTime, Interval } from 'luxon';
import { toast } from 'react-toastify';
import timezoneHelper from '../../Shared/Helpers/timezoneHelper';
import { Alert } from "react-bootstrap";
import FastNavigationBar from "../../Shared/Components/fastNavigationBar";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

export default function StationCultivationDiseasesPage({
  history,
  match: {
    params: { stationId, cultivationId },
  }
}) {
  const subheader = useSubheader();
  const dispatch = useDispatch();

  const langName = useLang();
  const intl = useIntl();
  const lang = languages[langName];
  const { messages: msg } = intl;

  const [title, setTitle] = useState(msg.LOADING_DATA);
  const [monitoredDiseases, setMonitoredDiseases] = useState('-');
  registerLocale('currentDatePickerLang', {
    localize: {
      month: n => msg.MONTHS[n],
      day: n => msg.DAYS[n]
    },
    options: {
      weekStartsOn: 1,
      firstWeekContainsDate: 2
    },
    formatLong: {}
  });

  const { stationAvailableDiseaseModels, stationCultivationInfo, userSubscriptions } = useSelector(
    (state) => ({
      actionsLoading: state.subscriptions.actionsLoading,
      userSubscriptions: state.subscriptions.userSubscriptions,
      stationAvailableDiseaseModels: state.subscriptions.stationAvailableDiseaseModels,
      stationCultivationInfo: state.subscriptions.stationCultivationInfo,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (userSubscriptions) {
    dispatch(actions.fetchSubscriptions());
  }}, [dispatch]);
  
  useEffect(() => {
      fetchInfo(stationId, cultivationId);
  }, []);

  const fetchInfo = async (stationId, cultivationId) => {
    dispatch(actions.fetchStationCultivationInfo(stationId, cultivationId));
  }

  const [diseasesDetails, setDiseasesDetails] = useState('');

  useEffect(() => {
    if (stationCultivationInfo)
    {
      let cultMonitoredDiseases = [];
      for (const diseases of stationCultivationInfo.monitoredDiseases) {
        let diseaseEntity = diseases.names[lang];
        if (diseases?.dates && diseases.dates?.length>0)
        {
          let ranges = [];
          let diseaseDates = [...diseases.dates];
          diseaseDates.sort(( a, b ) => {
            if ( a.dateFrom < b.dateFrom ){
              return -1;
            }
            if ( a.dateFrom > b.dateFrom ){
              return 1;
            }
            return 0;
          })
          for (const dateEnt of diseaseDates)
          {
            let dateFromConverted = '';
            let dateToConverted = '';
            let df = dateEnt.dateFrom.split('-');
            let dt = dateEnt.dateTo.split('-');
            dateFromConverted = `${parseInt(df[1])} ${msg.MONTHS_SHORT[parseInt(df[0])-1].toLowerCase()}`;
            dateToConverted = `${parseInt(dt[1])} ${msg.MONTHS_SHORT[parseInt(dt[0])-1].toLowerCase()}`;
            ranges.push(`${dateFromConverted}-${dateToConverted}`);
          }

          diseaseEntity = `${diseaseEntity} (${ranges.join(', ')})`;
        }

        cultMonitoredDiseases.push(diseaseEntity);
      }
      let diseasesToSet = cultMonitoredDiseases.length > 0 ? cultMonitoredDiseases : [];
      
      setMonitoredDiseases(diseasesToSet);
      setDiseasesDetails(`${msg.MONITORED_DISEASES}: ${diseasesToSet.join(', ')}.`);

    }
  }, [stationCultivationInfo]);


  const [stationName, setStationName] = useState('');
  const [cultivationName, setCultivationName] = useState('');
  const [stationDiseaseModels, setStationDiseaseModels] = useState([]);
  const [selectedStationName, setSelectedStationName] = useState('');
  const [selectedCultivationName, setSelectedCultivationName] = useState('');
  const [stationMultiCharts, setStationMultiCharts] = useState([]);
  const [stationMultiChartsData, setStationMultiChartsData] = useState([]);

  useEffect(() => {
    let _title = msg.SUBSCRIPTION_STATION_AVAILABLE_CULTIVATIONS_DISEASES;

    if (stationAvailableDiseaseModels) {
      setStationName(stationAvailableDiseaseModels.stationName);
      setCultivationName(stationAvailableDiseaseModels.cultivation[lang]);
      setStationDiseaseModels(stationAvailableDiseaseModels.stationDiseasesList);
      if (stationAvailableDiseaseModels?.multiCharts) setStationMultiCharts(stationAvailableDiseaseModels.multiCharts);
      if (stationAvailableDiseaseModels?.multiChartsData) setStationMultiChartsData(stationAvailableDiseaseModels.multiChartsData);
      setTitle(`${_title} - ${cultivationName} - ${stationName}`);
    }

    subheader.setTitle(_title);

  }, [stationAvailableDiseaseModels, []]);

  useEffect(async () => {
    // let storageDateFrom = localStorage.getItem('dateFrom');

    // if (storageDateFrom) {
    //   setDateFrom(new Date(storageDateFrom));
    // } else {
    let dateFrom = DateTime.local().minus({ days: 3 });
    let newDateFrom = timezoneHelper.toCosmosUTC(dateFrom);
    localStorage.setItem('dateFrom', newDateFrom.dateString);
    setDateFrom(new Date(newDateFrom.dateString));
    // }

    // let storageDateTo = localStorage.getItem('dateTo');
    // if (storageDateTo) {
    //   setDateTo(new Date(storageDateTo));
    // } else {
    let dateTo = DateTime.local();
    let newDateTo = timezoneHelper.toCosmosUTC(dateTo);
    localStorage.setItem('dateTo', newDateTo.dateString);
    setDateTo(new Date(newDateTo.dateString));
    // }
    await fetchDiseaseInfo();
  }, []);

  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const setDateFromToStorage = dateFrom => {
    setDateFrom(dateFrom);
    localStorage.setItem('dateFrom', dateFrom.toJSON());
  }

  const setDateToToStorage = dateTo => {
    setDateTo(dateTo);
    localStorage.setItem('dateTo', dateTo.toJSON());
  }

  const backToLocations = () => {
    history.push(`/locations/${stationId}/diseases/cultivations`);
  };

  let fetchDiseaseInfo = async () => {
    let storageDateFrom = localStorage.getItem('dateFrom');
    let storageDateTo = localStorage.getItem('dateTo');

    let days = Interval.fromDateTimes(new Date(storageDateFrom.substring(0, 10)), new Date(storageDateTo.substring(0, 10)));

    if (days.length("days") <= 183) {
      dispatch(actions.fetchStationAvailableDiseaseModels(stationId, cultivationId, storageDateFrom, storageDateTo));
    } else {
      toast.error(msg.TOO_LARGE_DATE_RANGE);
    }
  }

  return (
    <Card>
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToLocations}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            {msg.MENU_RETURN}
          </button>
          {`  `}
          <FastNavigationBar stationId={stationId} section="Diseases" history={history} />
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ul style={{fontSize: '16px'}} className="mb-5">
          <FormattedHTMLMessage id="DISEASE_MODELS_INFO" />
          <li>{diseasesDetails}</li>
          <FormattedHTMLMessage id="DISEASE_MODELS_INFO_2" />
        </ul>
        <Navbar className="bg-light pt-5 pb-5">
          <DatePicker
            locale="currentDatePickerLang"
            selected={dateFrom}
            dateFormat="yyyy-MM-dd"
            onChange={dateFrom => setDateFromToStorage(dateFrom)}
            className="form-control mr-5"
            popperPlacement="bottom-start"
            onKeyDown={(event) => {
              event.preventDefault();
            }}
          />

          <DatePicker
            locale="currentDatePickerLang"
            selected={dateTo}
            dateFormat="yyyy-MM-dd"
            onChange={dateTo => setDateToToStorage(dateTo)}
            className="form-control ml-2"
            popperPlacement="bottom-start"
            onKeyDown={(event) => {
              event.preventDefault();
            }}
          />

          <Button className="btn-primary btn-sm ml-4" onClick={() => fetchDiseaseInfo()}>
            {'OK'}
          </Button>
          {/* <div style={{position: 'absolute', right: 15}}>
            <Button className="btn-sm float-right" onClick={() => fetchInfo(stationId, cultivationId)}> <InfoOutlinedIcon /></Button>
          </div> */}
        </Navbar>


        <div className="mt-5">
          {!stationAvailableDiseaseModels && <Alert variant="warning" className="text-center" style={{ fontSize: 14 }}>{msg.LOADING_DATA}</Alert>}
          {stationDiseaseModels && stationDiseaseModels.map((stationDiseaseModel, key) => {

            return (<DiseaseLineChart key={key} diseaseData={stationDiseaseModel.diseaseData} diseaseModelResultSettings={stationDiseaseModel.chartSettings} diseaseNames={stationDiseaseModel.diseaseNames} stationName={stationName} cultivationName={cultivationName} predictionData={stationDiseaseModel.predictionData || []} predictionTitle={msg.PREDICTION} />);


          })}
          {stationMultiCharts.length>0&&stationMultiCharts.map((multiChart, key) => {
              return (<DiseaseLineMultiChart key={key} chartSettings={multiChart} chartData={stationMultiChartsData} stationName={stationName} cultivationName={cultivationName} />);
          })}
          
        </div>
      </CardBody>
    </Card >
  );
}
