/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import * as actions from "./subscriptionsActions";
import { shallowEqual, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useSubheader } from "../../../../_metronic/layout";
import { useIntl } from "react-intl";
import { useLang } from "../../../../_metronic/i18n/Metronici18n";
import FastNavigationBar from "../../Shared/Components/fastNavigationBar";

export default function SubscriptionForecastPage({
  history,
  match: {
    params: { stationId },
  }
}) {
  const subheader = useSubheader();
  const dispatch = useDispatch();

  const langName = useLang();
  const intl = useIntl();
  const { messages: msg } = intl;

  const [title, setTitle] = useState(msg.LOADING_DATA);

  useEffect(() => {
    dispatch(actions.fetchStationForecast(stationId, langName));
  }, [dispatch]);

  const { stationForecast } = useSelector(
    (state) => ({
      actionsLoading: state.subscriptions.actionsLoading,
      stationForecast: state.subscriptions.stationForecast
    }),
    shallowEqual
  );

  const [forecastImage, setForecastImage] = useState(null);
  const [stationName, setStationName] = useState('');

  useEffect(() => {
    if (stationForecast) {
      setForecastImage(stationForecast.forecastImage);
      setStationName(stationForecast.stationName);
    }
  }, [stationForecast]);

  useEffect(() => {
    let _title = msg.SUBSCRIPTION_FORECAST;

    if (stationForecast) {
      setTitle(`${_title} ${stationName}`);
    }

    subheader.setTitle(_title);
  }, [stationForecast, []]);

  const backToLocations = () => {
    history.push(`/locations`);
  };

  return (
    <Card>
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToLocations}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            {msg.MENU_RETURN}
          </button>
          {`  `}
          <FastNavigationBar stationId={stationId} section="Forecast" history={history} />
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5 text-center">
          {forecastImage &&
            <img src={`data:image/png;base64,${forecastImage}`} className="img-fluid" />
          }
        </div>
      </CardBody>
    </Card>
  );
}
