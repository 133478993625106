import React, { useEffect, useState, useRef } from "react";
import { Line, Chart } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import _ from "lodash";
import timezoneHelper from '../Helpers/timezoneHelper';
import { useLang } from "../../../../_metronic/i18n/Metronici18n";
import languages from "../Enums/languages";
import { Badge } from "react-bootstrap";
import ExportAsJPG from '../Data/exportAsJPG';
import 'chartjs-adapter-moment';

export default function DiseaseLineChart({
    diseaseData,
    diseaseModelResultSettings,
    diseaseNames,
    stationName,
    cultivationName,
    predictionData,
    predictionTitle
}) {

    function getTintedColor(color, v) {
        if (color.length >6) { color= color.substring(1,color.length)}
        var rgb = parseInt(color, 16); 
        var r = Math.abs(((rgb >> 16) & 0xFF)+v); if (r>255) r=r-(r-255);
        var g = Math.abs(((rgb >> 8) & 0xFF)+v); if (g>255) g=g-(g-255);
        var b = Math.abs((rgb & 0xFF)+v); if (b>255) b=b-(b-255);
        r = Number(r < 0 || isNaN(r)) ? 0 : ((r > 255) ? 255 : r).toString(16); 
        if (r.length == 1) r = '0' + r;
        g = Number(g < 0 || isNaN(g)) ? 0 : ((g > 255) ? 255 : g).toString(16); 
        if (g.length == 1) g = '0' + g;
        b = Number(b < 0 || isNaN(b)) ? 0 : ((b > 255) ? 255 : b).toString(16); 
        if (b.length == 1) b = '0' + b;
        return "#" + r + g + b;
    } 

    const toggleXAxesDisplayPlugin = {
        id: 'toggleXAxesDisplayPlugin',
        resize: function(chartInstance) {
            if (window.innerWidth > 1200) {
                chartInstance.config.options.aspectRatio = 2;
                chartInstance.config.options.scales.x.ticks.font.size = 11;
            } else
            if (window.innerWidth > 800) {
                chartInstance.config.options.aspectRatio = 1.4;
                if (window.innerWidth > window.innerHeight) chartInstance.config.options.aspectRatio = 1.5;
                chartInstance.config.options.scales.x.ticks.font.size = 11;
            } else 
            if (window.innerWidth > 500) {
                chartInstance.config.options.aspectRatio = 1;
                if (window.innerWidth > window.innerHeight) chartInstance.config.options.aspectRatio = 1.5;
                chartInstance.config.options.scales.x.ticks.font.size = 11;
            } else 
            if (window.innerWidth > 400) {
                chartInstance.config.options.aspectRatio = 0.8;
                chartInstance.config.options.scales.x.ticks.font.size = 10;
            } else {
                chartInstance.config.options.aspectRatio = 0.6;
                chartInstance.config.options.scales.x.ticks.font.size = 8;
            }

            chartInstance.update();
        }
    }

    const backgroundPlugin = {
        id: 'backgroundPlugin',
        beforeDraw: function (chart) {
            var ctx = chart.canvas.getContext('2d');
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
        }
    }

    Chart.register([annotationPlugin, backgroundPlugin, toggleXAxesDisplayPlugin]);
    const langName = useLang();
    const lang = languages[langName];
    const chartRef = useRef(null);

    useEffect(() => {
        if (diseaseModelResultSettings) {
            let { levels } = diseaseModelResultSettings;

            let lastDate;

            if (diseaseData.length > 0) {
                let labels = [];
                let chartDataArray = [];
                diseaseData.map(data => {
                    lastDate = timezoneHelper.parseCosmosDateString(data.date, data.dateTimeZone);
                    let date = lastDate;
                    let { modelValue } = data;

                    if (modelValue !== null) {
                        labels.push(timezoneHelper.toDateTimeString(date));
                        if (diseaseModelResultSettings.recalculate) {
                            chartDataArray.push(Math.round((parseInt(modelValue) / (diseaseModelResultSettings.maxVal - diseaseModelResultSettings.minVal)) * 100));
                        } else {
                            chartDataArray.push(modelValue);
                        }
                    }
                });

                setLabels(labels);
                setDiseaseChartData(chartDataArray);
            } 

            setChartLabel(`${diseaseModelResultSettings.names[lang]} [${diseaseModelResultSettings.unit}]`);
            setLineColor(diseaseModelResultSettings.lineColor);
            setLineHeight(diseaseModelResultSettings.lineHeight);
            setPointRadius(diseaseModelResultSettings.pointRadius);            

            const annotationModel = {
                drawTime: "beforeDatasetsDraw",
                display: true,
                type: "box",
                yMin: null,
                yMax: null,
                backgroundColor: "",
                borderColor: "",
                borderWidth: 0
            }

            let createdAnnotations = {};
            let maxKey;

            levels.map((level, key) => {
                let newAnnotation = { ...annotationModel };

                if (diseaseModelResultSettings.recalculate) {
                    newAnnotation.yMin = Math.round((level.minVal-0) / (diseaseModelResultSettings.maxVal - diseaseModelResultSettings.minVal) * 100);
                    newAnnotation.yMax = Math.round((level.maxVal-0) / (diseaseModelResultSettings.maxVal - diseaseModelResultSettings.minVal) * 100);
                } else {
                    newAnnotation.yMin = (level.minVal-0);
                    newAnnotation.yMax = (level.maxVal-0);
                }
                if (newAnnotation.yMin == 0) newAnnotation.yMin = -1;
                if (newAnnotation.yMax == 100) newAnnotation.yMax = 101;
                newAnnotation.backgroundColor = `${level.color}`;
                newAnnotation.borderColor = `${level.color}`;
                
                createdAnnotations[`box${key + 1}`] = { ...newAnnotation };
                maxKey = key;
            });
            
            if (predictionData.length>0)
            levels.map((level, key) => {
                let newAnnotation = { ...annotationModel };

                if (diseaseModelResultSettings.recalculate) {
                    newAnnotation.yMin = Math.round((level.minVal-0) / (diseaseModelResultSettings.maxVal - diseaseModelResultSettings.minVal) * 100);
                    newAnnotation.yMax = Math.round((level.maxVal-0) / (diseaseModelResultSettings.maxVal - diseaseModelResultSettings.minVal) * 100);
                    
                } else {
                    newAnnotation.yMin = (level.minVal-0);
                    newAnnotation.yMax = (level.maxVal-0);
                }
                console.log(newAnnotation.yMax);
                if (newAnnotation.yMin == 0) newAnnotation.yMin = -1;
                if (newAnnotation.yMax == 100) newAnnotation.yMax = 101;
                newAnnotation.backgroundColor = getTintedColor(level.color, 40);
                newAnnotation.borderColor = getTintedColor(level.color, 40);
                newAnnotation.xMin = predictionData[0].x;
                
                createdAnnotations[`box${key + 2 + maxKey}`] = { ...newAnnotation };
            });

            const optionsModel = {
                scales: {
                    y: {
                        ticks: {
                            stepSize: 1,
                            max: 100,
                            min: 0,
                            beginAtZero: true,
                            callback: function(val, index) {
                                if (val < 0 ) return '0';
                                if (val > 100) return '100';
                                return val;
                            }
                        }
                    },
                    x: {
                        ticks: {
                            font: {
                                size: 11,
                            }
                        },
                        type: 'time',
                        time: {
                            displayFormats: {
                                hour: 'YY-MM-DD HH:00'
                            },
                            unit: 'hour',
                            tooltipFormat: 'YYYY-MM-DD HH:00' 
                        }
                      }
                },
                plugins: {
                    datalabels: {
                        color: "black",
                        labels: {
                            title: {
                                font: {
                                    weight: "bold"
                                }
                            }
                        }
                    },
                    annotation: {
                        annotations: {
                        }
                    },
                    title: {
                        display: true,
                        text: Array(diseaseNames[lang], [], `${cultivationName}, ${stationName}`),
                        font: {
                            family: "Poppins, Helvetica",
                            size: 18,
                            weight: 444,
                        }
                    }
                }
            };
            var lineAnnotation;

            if (predictionData.length>0)
            {
             lineAnnotation = {
                        type: 'line',
                        borderColor: `${diseaseModelResultSettings.lineColor}99`,
                        xMin: predictionData[0].x,
                        xMax: predictionData[0].x,
                        borderWidth: 1,
                        borderDash: [4,4],
                        label: {
                            enabled: true,
                            content: `${predictionTitle} ->`,
                            backgroundColor: "#00000066"
                        }
                }
            }

            let annotationsToAdd = predictionData.length > 0 ? {...createdAnnotations, lineAnnotation} : {...createdAnnotations};

            optionsModel.plugins.annotation.annotations = annotationsToAdd;

            setChartOptions(optionsModel);

            setTimeout(() => {
                setOptionsArePrepared(true);
            }, 100);

        }

    }, [diseaseData]);

    const [labels, setLabels] = useState([]);
    const [diseaseChartData, setDiseaseChartData] = useState([]);
    const [chartLabel, setChartLabel] = useState('');
    const [optionsArePrepared, setOptionsArePrepared] = useState(false);
    const [chartOptions, setChartOptions] = useState();
    const [lineColor, setLineColor] = useState('#FF0000');
    const [lineHeight, setLineHeight] = useState(2);
    const [pointRadius, setPointRadius] = useState(2);

    const data = (canvas, cenas) => {
        let dataSet = {
            label: chartLabel,
            data: diseaseChartData,
            backgroundColor: `${lineColor}`,
            borderColor: `${lineColor}ff`,
            borderWidth: 0,
            pointRadius: 0,
            borderWidth: lineHeight,
            fill: false,
            pointStyle: 'circle',
            pointRadius: pointRadius,
            tension: 0.2
        }
        var predictions = [];

        if (predictionData) {
            predictions = {
                label: `${predictionTitle} [%]`,
                data: predictionData,
                backgroundColor: `${lineColor}ff`,
                borderColor: `${lineColor}bb`,
                borderWidth: 1,
                borderDash: [4,4],
                borderWidth: lineHeight,
                fill: false,
                pointStyle: false,
                pointRadius: 0,
                tension: 0.2
            }
        }
        let dataSets = predictionData.length>0 ? [dataSet, predictions] : [dataSet];
        const chartData = {
            labels: [...labels],
            datasets: dataSets
        };

        return chartData;
    };

    return (
        <div className="container p-5">
            <div className="mt-5 mb-5 text-center col-sm-12 col-md-12 col-lg-9 mx-auto">
                <h4>
                    {optionsArePrepared && <ExportAsJPG fileName={diseaseNames[lang]} chartRef={chartRef} />}
                </h4>
                {optionsArePrepared && <Line ref={chartRef} data={data} options={chartOptions} />}
                <div className="mt-3">{diseaseModelResultSettings && diseaseModelResultSettings.levels.map((level, key) => {
                    return <Badge key={key} className="mr-2" style={{ backgroundColor: level.color }}>{level.names[lang]}</Badge>
                })}</div>
                
            </div>
            <hr />
        </div>
    );
}