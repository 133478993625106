import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik"
import { toast } from 'react-toastify';
import { Checkbox } from "@material-ui/core";
import { useIntl } from "react-intl";
import * as Yup from "yup";

export function EditUserForm({
  btnRef,
  updateUser,
  userSettings
}) {
  const intl = useIntl();
  const { messages: msg } = intl;

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, msg["USER.VALIDATION.MIN3"])
      .max(20, msg["USER.VALIDATION.MAX20"])
      .matches(/^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ]+$/ , msg["USER.VALIDATION.LETTERS"]),
    surname: Yup.string()
      .min(3, msg["USER.VALIDATION.MIN3"])
      .max(30, msg["USER.VALIDATION.MAX30"])
      .matches(/^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ]+$/ , msg["USER.VALIDATION.LETTERS"]),
    phone: Yup.string()
      .min(9, msg["USER.VALIDATION.9DIG"])
      .max(9, msg["USER.VALIDATION.9DIG"])
      .matches(/^[0-9]+$/ , msg["USER.VALIDATION.DIGITS"]),
    nip: Yup.string()
      .min(10, msg["USER.VALIDATION.10DIG"])
      .max(10, msg["USER.VALIDATION.10DIG"])
      .matches(/^[0-9]+$/ , msg["USER.VALIDATION.DIGITS"]),
    companyName: Yup.string()
      .min(3, msg["USER.VALIDATION.MIN3"])
      .max(30, msg["USER.VALIDATION.MAX30"])
      .matches(/^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ 0-9]+$/ , msg["USER.VALIDATION.LETTERS"]),
    street: Yup.string()
      .min(3, msg["USER.VALIDATION.MIN3"])
      .max(30, msg["USER.VALIDATION.MAX30"])
      .matches(/^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ 0-9]+$/ , msg["USER.VALIDATION.LETTERS"]),
    number: Yup.string()
      .min(1, msg["USER.VALIDATION.1DIG"])
      .max(9, msg["USER.VALIDATION.9DIG"])
      .matches(/^[a-zA-Z0-9]+$/ , msg["USER.VALIDATION.DIGANDLET"]),
    zipcode: Yup.string()
      .matches(/^[0-9]{2}-[0-9]{3}$/ , msg["USER.VALIDATION.ZIPCODE"]),
    city: Yup.string()
      .min(3, msg["USER.VALIDATION.MIN3"])
      .max(30, msg["USER.VALIDATION.MAX30"])
      .matches(/^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ]+$/ , msg["USER.VALIDATION.LETTERS"]),
  });

  const submitForm = (formSubmited, isFormValid) => {
    if(isFormValid) { 
      try {
        updateUser(userSettings, formSubmited)
        toast.success(msg['USER.VALIDATION.SAVED'])
      } catch (error) {
        toast.error(msg['USER.VALIDATION.ERROR'])
      }
    }
  }

  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    if (userSettings) {
      setInitialValues({
        name: userSettings.name,
        surname: userSettings.surname ,
        phone: userSettings.phone,
        nip: userSettings.nip,
        companyName: userSettings.companyName,
        street: userSettings.street,
        number: userSettings.number,
        zipcode: userSettings.invoiceData?.invoiceNip || userSettings.zipcode,
        city: userSettings.city,
        emailsEnabled: userSettings.hasOwnProperty('emailsEnabled') ? userSettings.emailsEnabled : true
      });
    }
  }, [userSettings]);

  return (
    <>
      {userSettings && <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitForm}
        enableReinitialize={true}
      >
          {({ values }) => {
            return (
              <Form className="form form-label-right">
              <button
                type="submit" style={{ display: "none" }}
                ref={btnRef}
              ></button>
              <h5 className="mb-5">{msg["USER.SETTINGS.PERSONAL_DATA"]}</h5>
              <div className="row mt-5">
                <div className="col-3 mr-3">
                  <label>{msg["USER.SETTINGS.NAME"]}</label>
                  <div>
                    <Field className="form-control" type="text" name = "name"/>
                    <span className="error"><ErrorMessage name="name" /></span>
                  </div>
                </div>
                <div className="col-3">
                  <label>{msg["USER.SETTINGS.SURNAME"]}</label>
                  <div>
                    <Field className="form-control" type="text" name = "surname"/>
                    <span className="error"><ErrorMessage name="surname" /></span>
                  </div>
                </div>
                <div className="col-2">
                  <label>{msg["USER.SETTINGS.PHONE"]}</label>
                  <div>
                    <Field className="form-control" type="tel" name = "phone"/>
                    <span className="error"><ErrorMessage name="phone" /></span>
                  </div>
                </div>
              </div>
              <div className="row mt-5 mb-5">
                <div className="col-3 mr-3 ml-1">
                  <label>{msg["USER.SETTINGS.NIP"]}</label>
                  <div>
                    <Field className="form-control" type="text" name = "nip"/>
                    <span className="error"><ErrorMessage name="nip" /></span>
                  </div>
                </div>
                <div className="col-3 mr-3 ml-1">
                  <label>{msg["USER.SETTINGS.COMPANY_NAME"]}</label>
                  <div>
                    <Field className="form-control" type="text" name = "companyName" />
                    <span className="error"><ErrorMessage name="companyName" /></span>
                  </div>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12"><h5 className="mt-5 mb-4">{msg["USER.SETTINGS.ADDRESS_DATA"]}</h5></div>
                <div className="col-3">
                  <label>{msg["USER.SETTINGS.STREET"]}</label>
                  <div>
                    <Field className="form-control" type="text" name = "street" />
                    <span className="error"><ErrorMessage name="street" /></span>
                  </div>
                </div>
                <div className="col-3">
                  <label>{msg["USER.SETTINGS.NUMBER"]}</label>
                  <div>
                    <Field className="form-control" type="text" name = "number" />
                    <span className="error"><ErrorMessage name="number" /></span>
                  </div>
                </div>
                <div className="col-3">
                  <label>{msg["USER.SETTINGS.ZIP_CODE"]}</label>
                  <div>
                    <Field className="form-control" type="text" name = "zipcode" />
                    <span className="error"><ErrorMessage name="zipcode" /></span>
                  </div>
                </div>
                <div className="col-3">
                  <label>{msg["USER.SETTINGS.CITY"]}</label>
                  <div>
                    <Field className="form-control" type="text" name = "city" />
                    <span className="error"><ErrorMessage name="city" /></span>
                  </div>
                </div>
              </div>
              <div className="row mt-5">  
                <div className="col-12">          
                  <hr className="mt-5" />
                  <label style={{cursor: 'pointer'}}><Field
                    // as={Checkbox}
                    checked={values.emailsEnabled || values.emailsEnabled == undefined}
                    name='emailsEnabled'
                    color="primary"
                    type="checkbox"
                    className="mr-2"
                  />
                  {msg["USER.SETTINGS.RECEIVE_EMAIL"]}</label>
                </div>
              </div>
            </Form>
            )
          }
        }
      </Formik>}
    </>
  );
}