import React, { useEffect, useState, useRef } from "react";
import { Line, Chart } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import _ from "lodash";
import { useLang } from "../../../../_metronic/i18n/Metronici18n";
import languages from "../Enums/languages";
import { Badge } from "react-bootstrap";
import ExportAsJPG from '../Data/exportAsJPG';
import ExportInsectModelsAsXLS from "../Data/exportInsectModelsAsXLS";

export default function InsectModelChart({
    insectModel,
    insectData,
    stationName,
    cultivationName,
    chartSettings,
    dateToString,
    dateFromString,
    avgTemp,
    minTemp
}) {

    const toggleXAxesDisplayPlugin = {
        id: 'toggleXAxesDisplayPlugin',
        resize: function(chartInstance) {
            if (window.innerWidth > 1200) {
                chartInstance.config.options.aspectRatio = 2;
                chartInstance.config.options.scales.x.ticks.font.size = 11;
            } else
            if (window.innerWidth > 800) {
                chartInstance.config.options.aspectRatio = 1.4;
                if (window.innerWidth > window.innerHeight) chartInstance.config.options.aspectRatio = 1.5;
                chartInstance.config.options.scales.x.ticks.font.size = 11;
            } else 
            if (window.innerWidth > 500) {
                chartInstance.config.options.aspectRatio = 1;
                if (window.innerWidth > window.innerHeight) chartInstance.config.options.aspectRatio = 1.5;
                chartInstance.config.options.scales.x.ticks.font.size = 11;
            } else 
            if (window.innerWidth > 400) {
                chartInstance.config.options.aspectRatio = 0.8;
                chartInstance.config.options.scales.x.ticks.font.size = 10;
            } else {
                chartInstance.config.options.aspectRatio = 0.6;
                chartInstance.config.options.scales.x.ticks.font.size = 8;
            }

            chartInstance.update();
        }
    }

    const backgroundPlugin = {
        id: 'backgroundPlugin',
        beforeDraw: function (chart) {
            var ctx = chart.canvas.getContext('2d');
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
        }
    }

    Chart.register([annotationPlugin, backgroundPlugin, toggleXAxesDisplayPlugin]);

    const langName = useLang();
    const lang = languages[langName];
    const chartRef = useRef(null);

    useEffect(() => {
        let chartMinVal;
        let chartMaxVal;
        
        if (chartSettings) {

            let { levels, ranges } = chartSettings;

            chartMinVal = 0;
            chartMaxVal = 100;

            if (insectData?.stadiumData?.length > 0) {
                let labels = [];
                let chartDataArray = [];
                let {adults} = insectData;

                // insectData.stadiumData.map(data => {
                //         let { countValue, stadium, day } = data;
                //         if (day.substr(5) >= '04-01')
                //         {
                //             let divider = adults[stadium-1]-0;
                //             labels.push(day);
                //             let dataToPush = Math.round((parseInt(countValue) / divider) * 100);
                //             chartDataArray.push(dataToPush>100 ? 100 : dataToPush);
                //         }
                // });
                let previousValue = null;
                insectData?.stadiumData.map(data => {
                    let { countValue, stadium, day } = data;
                        
                    // if (countValue != null && previousValue == null) previousValue = countValue;
                    // if (countValue == null && previousValue != null) countValue = previousValue;

                        if (day.substr(5) >= '03-01' && (dateFromString === null || (dateFromString && day.substr(5)>=dateFromString.substr(5))) && (dateToString === null || (dateToString && day.substr(5)<=dateToString.substr(5))))
                        {
                            let finalValue = null;
                            let minValue = null;
                            let maxValue = null;
                            ranges.map((riskValues, key) => {
                                riskValues.map(riskValue => {
                                    let {min, max} = riskValue;
                                    if (minValue == null || minValue > parseInt(min)) minValue = parseInt(min);
                                    if (maxValue == null || maxValue < parseInt(max)) maxValue = parseInt(max);
                                });
                            });
                            
                            ranges.map((riskValues, key) => {
                                riskValues.map(riskValue => {
                                    let {min, max} = riskValue;
                                    
                                    if (parseInt(countValue) >= min && parseInt(countValue)<=max)
                                    {
                                        finalValue=(key*25);

                                        if (minTemp != '' && minTemp != undefined) {

                                        let findDayAvg = avgTemp.find(temp => temp.day == day.substr(0,10));
                                            if (findDayAvg) {
                                                // console.log(`${findDayAvg.air} - ${minTemp}`)
                                                if ((findDayAvg.air-0) < (minTemp-0))
                                                {
                                                    finalValue = finalValue - 25;
                                                    if (finalValue < 0) finalValue = 0;
                                                }
                                            }
                                        } 
                                        return;
                                    }
                                });
                            });

                            if (finalValue == null)
                            {
                                if (countValue < minValue) finalValue = 0;
                                if (countValue > maxValue) finalValue = 100;
                            }

                           
                            labels.push(day);
                            chartDataArray.push(finalValue);
                        }
                });
                 

                setLabels(labels);
                setDiseaseChartData(chartDataArray);
            }


            setChartLabel(`${insectModel.names[lang]} [%]`);
            setLineColor(chartSettings.lineColor);
            setLineHeight(chartSettings.lineHeight);
            setPointRadius(chartSettings.pointRadius);
            setMinValue(chartMinVal);
            setMaxValue(chartMaxVal);

            const annotationModel = {
                drawTime: "beforeDatasetsDraw",
                display: true,
                type: "box",
                yMin: null,
                yMax: null,
                backgroundColor: "",
                borderColor: "",
                borderWidth: 0
            }

            let createdAnnotations = {};

            levels.map((level, key) => {
                let newAnnotation = { ...annotationModel };
                newAnnotation.yMin = parseInt(level.minVal);
                newAnnotation.yMax = parseInt(level.maxVal);
                newAnnotation.backgroundColor = `${level.color}`;
                newAnnotation.borderColor = `${level.color}`;
                if (newAnnotation.yMin == 0) newAnnotation.yMin = -1;
                if (newAnnotation.yMax == 100) newAnnotation.yMax = 101;
                createdAnnotations[`box${key + 1}`] = { ...newAnnotation };
            });

            const optionsModel = {
                scales: {
                    y: {
                
                        ticks: {
                            stepSize: 1,
                            max: 100,
                            min: 0,
                            beginAtZero: true,
                            callback: function(val, index) {
                                if (val < 0 ) return '0';
                                if (val > 100) return '100';
                                return val;
                            }
                        }
                    },
                    x: {
                        ticks: {
                            font: {
                                size: 11,
                            }
                        }
                      }
                },
                plugins: {
                    datalabels: {
                        color: "black",
                        labels: {
                            title: {
                                font: {
                                    weight: "bold"
                                }
                            }
                        }
                    },
                    annotation: {
                        annotations: {
                        }
                    },
                    title: {
                        display: true,
                        text: Array(insectModel.names[lang], [], `${cultivationName}, ${stationName}`),
                        font: {
                            family: "Poppins, Helvetica",
                            size: 18,
                            weight: 444,
                        }
                    }
                }
            };

            optionsModel.plugins.annotation.annotations = { ...createdAnnotations };

            setChartOptions(optionsModel);

            setTimeout(() => {
                setOptionsArePrepared(true);
            }, 100);
        }
    }, [chartSettings,stationName]);

    const [labels, setLabels] = useState([]);
    const [diseaseChartData, setDiseaseChartData] = useState([]);
    const [chartLabel, setChartLabel] = useState('');
    const [maxValue, setMaxValue] = useState(100);
    const [minValue, setMinValue] = useState(0);
    const [optionsArePrepared, setOptionsArePrepared] = useState(false);
    const [chartOptions, setChartOptions] = useState();
    const [lineColor, setLineColor] = useState('#FF0000');
    const [lineHeight, setLineHeight] = useState(2);
    const [pointRadius, setPointRadius] = useState(2);

    const data = (canvas, cenas) => {
        const chartData = {
            labels: labels,
            datasets: [
                {
                    label: chartLabel,
                    data: diseaseChartData,
                    backgroundColor: `${lineColor}`,
                    borderColor: `${lineColor}ff`,
                    borderWidth: 0,
                    pointRadius: 0,
                    borderWidth: lineHeight,
                    fill: false,
                    pointStyle: 'circle',
                    pointRadius: pointRadius,
                    tension: 0.2
                }
            ]
        };

        return chartData;
    };

    return (<>
        {chartSettings &&
        <div className="container p-5">
            <div className="mt-5 mb-5 text-center col-sm-12 col-md-12 col-lg-9 mx-auto">
                <h4>
                    {optionsArePrepared && <><ExportAsJPG  className="pull-right float-right" fileName={insectModel.names[lang]} chartRef={chartRef} /> <ExportInsectModelsAsXLS className="pull-right float-right" insect={insectModel.names[lang]} cultivationName={cultivationName} stationName={stationName} labels={labels} diseaseChartData={diseaseChartData} /></>}
                </h4>
                {optionsArePrepared && <Line ref={chartRef} data={data} options={chartOptions} />}
                <div className="mt-3">{chartSettings && chartSettings.levels.map((level, key) => {
                    return <Badge key={key} className="mr-2" style={{ backgroundColor: level.color }}>{level.names[lang]}</Badge>
                })}</div>
            </div>
            <hr />
        </div>}
        </>
    );
}