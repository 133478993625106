import React, { useEffect, useState, useRef } from "react";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import * as usersActions from "../../ECommerce/_redux/customers/customersActions";
import * as subscriptionsActions from "./subscriptionsActions";
import { EditUserForm } from "./userSettingsForm";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useSubheader } from "../../../../_metronic/layout";
import { toast } from 'react-toastify';
import { useIntl } from "react-intl";

export default function EditUserPage({
  history,
  match: {
    params: { id },
  }
}) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { messages: msg } = intl;
  const [title, setTitle] = useState(msg["DROPDOWN.MENU.SETTINGS"]);

  useEffect(() => {
    dispatch(subscriptionsActions.fetchUserSettings());
  }, [dispatch]);
  
  const { userSettings} = useSelector(
    (state) => ({
      userSettings: state.subscriptions.userSettings,
      userUpdated: state.subscriptions.userUpdated,
      error: state.subscriptions.error
    }),
    shallowEqual
  );

  const btnRef = useRef();

  const saveChanges = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const backToMain = _ => {
    history.push('/locations');
  }

  const updateUser = (user, formSubmited) => {
    dispatch(usersActions.updateCustomer(user, formSubmited));
  };

  return (
    <Card>
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={()=>backToMain()}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            {msg["MENU_RETURN"]}
          </button>
          {`  `}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveChanges}
          >
            {msg["USER.SETTINGS.SAVE_ACCOUNT_CHANGES"]}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          <EditUserForm
            btnRef={btnRef}
            userSettings={userSettings}
            updateUser={updateUser}
          />
        </div>
      </CardBody>
    </Card>
  );
}
