import axios from "axios";

export const SUBSCRIPTIONS_URL = "affiliate";

export function getCompanyUsers() {
  return axios.get(`${SUBSCRIPTIONS_URL}/companyUsers`);
}

export function getAgentUserSubscriptions(userId) {
  return axios.get(`${SUBSCRIPTIONS_URL}/getAgentUserSubscriptions/${userId}`);
}

export function postAgentUserSubscription(userId, selectedStation, selectedCultivation, monthsCount) {
  return axios.post(`${SUBSCRIPTIONS_URL}/getAgentUserSubscriptions/${userId}`, {selectedStation, selectedCultivation, monthsCount});
}