import React, { useEffect, useState, useRef } from "react";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import * as afActions from "./affiliateActions";
import { sortCaret } from "../../../../_metronic/_helpers";
import BootstrapTable from 'react-bootstrap-table-next';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useSubheader } from "../../../../_metronic/layout";
import { toast } from 'react-toastify';
import { useIntl } from "react-intl";
import DatePicker, { registerLocale } from "react-datepicker";
import timezoneHelper from '../../Shared/Helpers/timezoneHelper';
import { Navbar, Button } from "react-bootstrap";
import  {DateTime} from 'luxon';
import {Badge} from 'react-bootstrap';
import timeZoneHelper from  "../../Shared/Helpers/timezoneHelper";
import axios from 'axios';
import LocationOn from '@material-ui/icons/LocationOn';
import googleMap from "../../Shared/PublicKeys/googleMapKey";
import GoogleMapReact from 'google-map-react';


export default function AgentPanelPageSubscriptionsNew({
  history,
  match: {
    params: { userId },
  }
}) {

  var marker;
  var lines = [];

  registerLocale('currentDatePickerLang', {
    localize: {
      month: n => msg.MONTHS[n],
      day: n => msg.DAYS[n]
    },
    options: {
      weekStartsOn: 1
      /* Monday */
      ,
      firstWeekContainsDate: 2
    },
    formatLong: {}
  });

  const handleApiLoaded = async (mapEntity, mapsEntity) => {
    setMap(mapEntity);
    setMaps(mapsEntity);
  };

  const dispatch = useDispatch();
  const intl = useIntl();
  const { messages: msg } = intl;
  const [title, setTitle] = useState(msg["AGENT_USER_SUBSCRIPTIONS_LIST"]);
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [selectedCultivation, setCultivation] = useState(null);
  const [stations, setStations] = useState(null);
  const [cultivations, setCultivations] = useState(null);
  const [selectedStation, setSelectedStation] = useState(null);
  const [postCode, setPostCode] = useState('');
  const [mapSettings, setMapSettings] = useState({
    center: {
      lat: 52.133231,
      lng: 19.596804
    },
    zoom: 6
  });
  const [maxPostcodeOrCityLength, setMaxPostcodeOrCityLength] = useState(40);
  const [postCodeCoords, setPostcodeCoords] = useState({lat: null, lng: null});
  const [markerPos, setMarkerPos] = useState({lat: null, lng: null});
  const [monthsCount, setMonthsCount] = useState(12);


  useEffect(() => {
    const instance = axios.create({
      baseURL: 'https://api.admin.farmsmart.pl/getStationAvailableForUser'
    });

    instance('').then(res => {
      const {data} = res;
      setStations(data);
    });

    const instance2 = axios.create({
      baseURL: 'https://api.admin.farmsmart.pl/getCultivationsAvailableForUser'
    });

    instance2('').then(res => {
      const {data} = res;

      setCultivations(data.sort(compare));
    });
  }, []);

  function compare( a, b ) {
    if ( a.names[0] < b.names[0] ){
      return -1;
    }
    if ( a.names[0] > b.names[0] ){
      return 1;
    }
    return 0;
  }

  useEffect(()=>{
    if (!isNaN(postCode.charAt(0)) && !isNaN(postCode.charAt(1)) ) {
      setMaxPostcodeOrCityLength(6);
      if (postCode.length === 2) {
        setPostCode(`${postCode}-`);
      }
    }
    else {
      setMaxPostcodeOrCityLength(40);
    }
  }, [postCode]);

  const drawLines = (lat, lng) => {
    stations.map((location)=> {

      const distance = calculateDistance(location.latitude, location.longitude, lat, lng);
      if (distance <= 20)
      {
        var newline = new maps.Polyline({
          path: [
              new maps.LatLng(lat, lng), 
              new maps.LatLng(location.latitude, location.longitude)
          ],
          strokeColor: "#FF0000",
          strokeOpacity: 1.0,
          strokeWeight: 2,
          map: map
        });
        lines.push(newline);
      }
    
 });
  }

  const putMarker = (lat, lng) => {
    if (marker!=null)
        marker.setMap(null);
      marker = new maps.Marker({
        position: {lat, lng},
        map,
        draggable:true,
        labelInBackground: false,
        labelAnchor: new maps.Point(0, 40),
        animation: maps.Animation.DROP,
        label: {
          text: `${msg.DRAG_TO_SELECT_LOCATION}`,
          color: 'black',
          fontSize: '16px',      
          className: 'marker-position',
        },
      });
      setTimeout(() => {
        drawLines(lat, lng);
      }, 1000);
      marker.addListener('drag', e => {
        let {lat, lng} = marker.getPosition().toJSON();
        setPostcodeCoords({lat, lng});
        resetLines();
        drawLines(lat, lng);
      });

      marker.addListener('dragend', e => {
        let {lat, lng} = marker.getPosition().toJSON();
        if (map.getZoom() < 10)
        map.setZoom(10);
        map.setCenter({lat, lng});
        setMarkerPos({lat, lng});
      });
}

let Marker = ({location, markerColor, markerSize, entity}) => {
  let {name, latitude, longitude} = location;
  
  return <div lat={latitude} lng={longitude}><LocationOn onClick={()=>clickMarker(entity)} style={{color: markerColor, fontSize: `${markerSize}px`, marginTop: '-38px', marginLeft: '-16px'}} /> <h4>{name}</h4> </div>
}

const clickMarker = (location) => {
  setSelectedStation(location);
}

  const resetLines = _ => {
    if (lines.length > 0) {
      for (let loop=0; loop<lines.length; loop++) 
      {                           
        lines[loop].setMap(null);
      }
      lines = [];
    }
  }

  const calculateDistance = (lat1, lon1, lat2, lon2) => {    
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    }
    else {
      var radlat1 = Math.PI * lat1/180;
      var radlat2 = Math.PI * lat2/180;
      var theta = lon1-lon2;
      var radtheta = Math.PI * theta/180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180/Math.PI;
      dist = dist * 60 * 1.1515;
      dist = dist * 1.609344;
      return dist;
    }
    }

  const getPostcodeCoords = async (locationString) => {
    try {
      axios.post(`/getLocationCoords`,
        { locationString 
      }).then((res) => {
        // const {lat, lng} = res.data.results[0].geometry.location;
        const {lat, lng} = res.data;
        setPostcodeCoords({lat, lng});
        map.setCenter({lat, lng});

        if (postCode == '')
        map.setZoom(7);
        putMarker(lat, lng);
        
       resetLines();
      });
    } catch (e) {
    }
  }
  
  const { subscriptions, userSubscriptionCreated } = useSelector(
    (state) => ({
      subscriptions: state.affiliate.userSubscriptions,
      userSubscriptionCreated: state.affiliate.userSubscriptionCreated,
      error: state.subscriptions.error
    }),
    shallowEqual
  );

  useEffect(() => {
    if (userSubscriptionCreated) {
      alert(msg["AGENT_SUBSCRIPTION_CREATED"]);
    }
  }, [userSubscriptionCreated]);

  useEffect(() => {
    dispatch(afActions.fetchUserSubscriptions(userId));
  }, [dispatch]);

  useEffect(() => {
    setTitle(`${msg["AGENT_CREATING_SUBSCRIPTION"]} ${subscriptions.userEmail}`);
  }, [subscriptions]);

  const createSubscription = async ()  => {
    dispatch(afActions.createUserSubscription(userId, selectedStation.id, selectedCultivation, monthsCount));
  } 

  return (
    <Card>
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={()=>history.push(`/agentPanel/subscriptions/${userId}`)}
            className="btn btn-light mr-5"
          >
            <i className="fa fa-arrow-left"></i>
            {msg.MENU_RETURN}
          </button>

          <label>
            <input type="text" 
              className="form-control" 
              placeholder={msg.POST_CODE_OR_CITY} 
              style={{marginTop:7, width: 250}} 
              value={postCode} 
              maxLength={maxPostcodeOrCityLength}
              onChange={e=>{if (e.nativeEvent.data == '-') return false; if (e.nativeEvent.inputType === 'deleteContentBackward') setPostCode(''); else setPostCode(e.target.value)}}
              onKeyDown={e => {if (e.nativeEvent.key == 'Enter') {getPostcodeCoords(postCode); if (postCode!='') map.setZoom(10)}}}
            />
          </label> 
          <Button 
            style={{marginLeft: 10}} 
            onClick={()=>{getPostcodeCoords(postCode); if (postCode!='') map.setZoom(10)}} 
            >{msg.SHOW_NEAREST_LOCATIONS}
          </Button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div>
          <div className="row">
            <div className="mt-5 mb-5 col-3 pt-3">
            {`${selectedStation!==null?selectedStation?.name:msg["AGENT_WEATHER_STATION_NOT_SELECTED"]}`}
            </div>
            <div className="mt-5 mb-5 col-4">
                <select className="form-control" onChange={e => setCultivation(e.target.value)}>
                  <option value={null}>{msg["CULTIVATION_SELECT"]}</option>
                    {cultivations && cultivations.map(cultivation => (
                      <option value={cultivation.id} key={cultivation.id}>{cultivation.names[0]}</option>
                    ))}
                </select>
            </div>
            <div className="mt-5 mb-5 col-4">
              <select className="form-control" defaultValue={monthsCount} onChange={e => setMonthsCount(e.target.value)}>
                <option value={1} key={1}>{msg.SUBSCRIPTION_1_MONTH}</option>
                <option value={2} key={2}>{msg.SUBSCRIPTION_2_MONTH}</option>
                <option value={3} key={3}>{msg.SUBSCRIPTION_3_MONTH}</option>
                <option value={4} key={4}>{msg.SUBSCRIPTION_4_MONTH}</option>
                <option value={5} key={5}>{msg.SUBSCRIPTION_5_MONTH}</option>
                <option value={6} key={6}>{msg.SUBSCRIPTION_6_MONTH}</option>
                <option value={7} key={7}>{msg.SUBSCRIPTION_7_MONTH}</option>
                <option value={8} key={8}>{msg.SUBSCRIPTION_8_MONTH}</option>
                <option value={9} key={9}>{msg.SUBSCRIPTION_9_MONTH}</option>
                <option value={10} key={10}>{msg.SUBSCRIPTION_10_MONTH}</option>
                <option value={11} key={11}>{msg.SUBSCRIPTION_11_MONTH}</option>
                <option value={12} key={12}>{msg.SUBSCRIPTION_12_MONTH}</option>
              </select>
            </div>
            <div className="mt-3 mb-5 col-1 pt-3">
            <Button className="btn-primary btn-sm ml-4" disabled={!selectedCultivation||!selectedStation} onClick={() => {createSubscription()}}>
            {msg["AGENT_SUBSCRIPTION_CREATE"]}
          </Button>
            </div>
          </div>
          <div style={{width:"100%", height:"500px"}}>
        <GoogleMapReact
              bootstrapURLKeys={{key: googleMap.publicKey}}
              defaultCenter={mapSettings.center}
              defaultZoom={mapSettings.zoom}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
              options={{'dataMode': 'regions', 'region': 'PL'}}
            >
              {stations && stations.map((station, key) => {
                return <Marker key={key} id={station.id} lat={station.latitude} lng={station.longitude} location={station.name} markerColor={station.id!==selectedStation?.id?'green':'darkblue'} markerSize={28} entity={station} />;
              })}
            </GoogleMapReact>
            </div>
        </div>
      </CardBody>
    </Card>
  );
}
