import BootstrapTable from 'react-bootstrap-table-next';
import React, { useEffect, useState } from "react";
import * as subscriptionsActions from "./subscriptionsActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Button, Badge } from "react-bootstrap";
import { sortCaret } from "../../../../_metronic/_helpers";
import WbSunny from '@material-ui/icons/WbSunny';
import BarChartIcon from '@material-ui/icons/BarChart';
import { useIntl } from "react-intl";
import { useLang } from "../../../../_metronic/i18n/Metronici18n";
import languages from "../../Shared/Enums/languages";
import LocalFloristIcon from "@material-ui/icons/LocalFlorist";
import CookieConsent from "react-cookie-consent";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { toast } from 'react-toastify';
import axios from 'axios';

export default function UserSubscriptions({
  history
}) {

  const intl = useIntl();
  const { messages: msg } = intl;
  const langName = useLang();
  const lang = languages[langName];
  const [monitoredDiseases, setMonitoredDiseases] = useState('-');
  const [insectModels, setInsectModels] = useState('-');
  const [monitoredInsects, setMonitoredInsects] = useState('-');
  const [selectedStationName, setSelectedStationName] = useState('');
  const [selectedCultivationName, setSelectedCultivationName] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [alertText, setAlertText] = useState('-');

  const { userSubscriptions, stationCultivationInfo, error } = useSelector(
    (state) => ({
      userSubscriptions: state.subscriptions.userSubscriptions,
      stationCultivationInfo: state.subscriptions.stationCultivationInfo,
      error: state.subscriptions.error
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(subscriptionsActions.fetchSubscriptions());
  }, [dispatch]);

  const fetchInfo = async (stationId, cultivationId) => {
    dispatch(subscriptionsActions.fetchStationCultivationInfo(stationId, cultivationId));
    setSelectedStationName(userSubscriptions.stations.find(station => station.id == stationId)?.name);
    setSelectedCultivationName(userSubscriptions.cultivations.find(cultivation => cultivation.id == cultivationId).names[lang]);
  }

  useEffect(() => {
    if (stationCultivationInfo && selectedStationName != '')
    {
      let cultMonitoredDiseases = [];
      for (const diseases of stationCultivationInfo.monitoredDiseases) {
        let diseaseEntity = diseases.names[lang];
        if (diseases?.dates && diseases.dates?.length>0)
        {
          let ranges = [];
          let diseaseDates = [...diseases.dates];
          diseaseDates.sort(( a, b ) => {
            if ( a.dateFrom < b.dateFrom ){
              return -1;
            }
            if ( a.dateFrom > b.dateFrom ){
              return 1;
            }
            return 0;
          })
          for (const dateEnt of diseaseDates)
          {
            let dateFromConverted = '';
            let dateToConverted = '';
            let df = dateEnt.dateFrom.split('-');
            let dt = dateEnt.dateTo.split('-');
            console.log(df, '-', dt);
            dateFromConverted = `${parseInt(df[1])} ${msg.MONTHS_SHORT[parseInt(df[0])-1].toLowerCase()}`;
            dateToConverted = `${parseInt(dt[1])} ${msg.MONTHS_SHORT[parseInt(dt[0])-1].toLowerCase()}`;
            ranges.push(`${dateFromConverted}-${dateToConverted}`);
          }

          diseaseEntity = `${diseaseEntity} (${ranges.join(', ')})`;
        }

        cultMonitoredDiseases.push(diseaseEntity);
      }
      let diseasesToSet = cultMonitoredDiseases.length > 0 ? cultMonitoredDiseases.join(', ') : '';
      setMonitoredDiseases(diseasesToSet);

      let cultInsectModels = [];
      for (const models of stationCultivationInfo.insectModels) {
        cultInsectModels.push(models[lang]);
      }
      let insectModelsToSet = cultInsectModels.length > 0 ? cultInsectModels.join(', ') : '-';
      setInsectModels(insectModelsToSet);

      let cultMonitoredInsects = [];
      for (const insect of stationCultivationInfo.monitoredInsects) {
        cultMonitoredInsects.push(insect[lang]);
      }
      let monitoredInsectsToSet = cultMonitoredInsects.length > 0 ? cultMonitoredInsects.join(', ') : '-';
      setMonitoredInsects(monitoredInsectsToSet);

      const Msg = ({ closeToast }) => (
        <div>
          <h6>{selectedStationName}: {selectedCultivationName}</h6>
          <strong>{msg.MONITORED_DISEASES}</strong>
          <div className="mb-2">{diseasesToSet}</div>
          
          <strong>{msg.INSECTS_MODELS}</strong>
          <div className="mb-2">{insectModelsToSet}</div>

          <strong>{msg.MONITORED_INSECTS}</strong>
          <div className="mb-2">{monitoredInsectsToSet}</div>
          
          <div style={{textAlign: 'center'}}><button className="btn btn-secondary btn-small" onClick={closeToast}>OK</button></div>
        </div>
      )

      toast.info(<Msg />, {position: "top-center",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      });
    }
  }, [stationCultivationInfo]);

  const columns = [
    {
      dataField: 'stationId',
      text: msg.SUBSCRIPTION_LOCATION,
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        <>
          {userSubscriptions.stations.find(station => station.id == row.stationId)?.name}
        </>
      )
    },
    {
      dataField: 'dateFrom',
      text: msg.DATE_FROM,
      sort: true,
      formatter: (cellContent, row) => (
        <>
          {row.startDate.substr(0,10)}
        </>
      )
    },
    {
      dataField: 'dateTo',
      text: msg.DATE_TO,
      sort: true,
      formatter: (cellContent, row) => (
        <>
         {row.expirationDate.substr(0,10)}
        </>
      )
    },
    {
      dataField: 'subscriptionContains',
      text: msg.SUBSCRIPTION_TYPES,
      sort: true,
      formatter: (cellContent, row) => {
        return (<>
          {row?.subscriptionForStationData && <Badge variant="success mr-2 mb-1 mt-1">{msg.SUBSCRIPTION_STATION_DATA}</Badge>}
          {row?.subscriptionForDisease && <Badge variant="danger mr-2 mb-1 mt-1">{msg.SUBSCRIPTION_DISEASES}</Badge>}
          {row?.subscriptionForInsects && <Badge variant="warning mr-2 mb-1 mt-1" style={{backgroundColor: '#6035b7', color: 'white'}}>{msg.SUBSCRIPTION_INSECT_MODELS}</Badge>}
          {row?.subscriptionForInsectOccurrences && <Badge variant="info mr-2 mb-1 mt-1">{msg.SUBSCRIPTION_INSECT_OCCURENCES}</Badge>}
          {row?.subscriptionForForecast && <Badge variant="primary mr-2 mb-1 mt-1">{msg.SUBSCRIPTION_FORECAST}</Badge>}
        </>
        )
      }
    },
    {
      dataField: 'cultivations',
      text: msg.CULTIVATION,
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <>
          {((row.subscriptionForDisease === true || row.subscriptionForInsects === true || row.subscriptionForInsectOccurrences === true) && !row.allCultivations) && <Button className="btn-sm" onClick={() => fetchInfo(row.stationId, row.cultivationId)}><Badge variant="outline-primary mr-2 mb-1 mt-1"> <InfoOutlinedIcon /> {userSubscriptions.cultivations.find(cultivation => cultivation.id == row.cultivationId)?.names[lang]}</Badge></Button>}
          {row.allCultivations && <Badge variant="secondary mr-2 mb-1 mt-1">{msg.CULTIVATIONS_ALL}</Badge>}
        </>
        )
      }
    }
  ];

  return (<>
    <Card>
      <CardHeader title={msg.SUBSCRIPTIONS_LIST}>
        <CardHeaderToolbar>
          <Button className="" onClick={()=>history.push('/subscriptions/orders')}>{msg.ORDERS_HISTORY}</Button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        
     
      
        {userSubscriptions && <BootstrapTable keyField='id' data={userSubscriptions === undefined ? [] : userSubscriptions.subscriptions} columns={columns}
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
        />}
        {userSubscriptions && userSubscriptions.subscriptions.length == 0 && <div className="text-center mb-5 mt-5">
          <h5>{msg.NO_SUBSCRIPTIONS}</h5>
        </div>}
      </CardBody>
    </Card></>
  )
}