/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Switch } from "react-router-dom";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { requestUser } from './modules/Auth/_redux/authRedux';
import axios from "axios";
import { AzureAD } from 'react-aad-msal';
import { signInAuthProvider } from './modules/Shared/authProvider'
import SignUpRoutesPage from "./SignUpRoutes";
import SignUpMapRoutesPage from "./SignUpMapRoutes";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";

function getUserByEmail(email) {
  return axios.get(`users/email/${email}`);
}

export function Routes({ store }) {
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      const { auth } = store.getState();
      if (auth.user?.email) {
        getUserByEmail(auth.user.email).then(response => {
          const data = response.data;
          dispatch(requestUser(data));
        }).catch(error => {
          error.clientMessage = "Can't find users";
          dispatch(requestUser(false));
        });
      }
    })();
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
      <Switch>
        <ContentRoute exact={true} from="/signup/Plantpress" render={_ => <SignUpRoutesPage companyName="Plantpress" />} />
        <ContentRoute exact={true} from="/signup/PodOslonami" render={_ => <SignUpRoutesPage companyName="PodOslonami" />} />
        <ContentRoute exact={true} from="/signup/FarmSmart" render={_ => <SignUpRoutesPage companyName="FarmSmart" />} />
        <ContentRoute exact={true} from="/signup/PZU" render={_ => <SignUpMapRoutesPage companyName="PZU" />} />
        <AzureAD provider={signInAuthProvider} forceLogin={true} reduxStore={store}>
          <Layout>
            <BasePage />
          </Layout>
        </AzureAD>  
      </Switch>
  );
}
