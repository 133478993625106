import React from 'react'
import { Button, Badge } from 'react-bootstrap';
import * as FileSaver from 'file-saver';
import Image from '@material-ui/icons/Image';
import GetApp from '@material-ui/icons/GetApp';

function dataURLtoFile(dataurl, fileName) {

    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
}

export default function ExportAsJPG({ fileName, chartRef }) {

    const fileExtension = '.jpg';

    const exportToJPG = async (fileName, chartRef) => {
        const chart = chartRef.current;
        const image = chart.toBase64Image('image/jpeg', 1);
        const imageBuffer = dataURLtoFile(image, fileName);
        FileSaver.saveAs(imageBuffer, `${fileName}${fileExtension}`);
    }

    return (
        <div className="pull-right text-right">
            <Badge role="button" variant="outlined" style={{ fontSize: 10, fontWeight: 333 }} className="btn-sm btn-light" onClick={(e) => exportToJPG(fileName, chartRef)}><GetApp /> jpg</Badge></div>
    )
}