const { DateTime } = require("luxon");

function parseCosmosDateString(dateString, timeZone) {
    var date = DateTime.fromISO(dateString.replace('Z', ''), { zone: timeZone })

    return date;
}

function toCosmosUTC(date) {
    var dateString = date.toFormat('yyyy-MM-dd') + 'T' + date.toFormat('HH:mm:ss.SSS') + 'Z';
    var timezone = date.zone.zoneName;

    return {
        dateString, timezone
    };
}

function toUTCSeconds(date) {
    var dateString = date.toFormat('yyyy-MM-dd') + 'T' + date.toFormat('HH:mm:ss.SSS') + 'Z';
    var utcDate = DateTime.fromISO(dateString, { zone: 'utc' });
    var secondsWithMilliseconds = utcDate.toSeconds();
    return Math.floor(secondsWithMilliseconds);
}

function toDateTimeString(date) {
    var dateTimeString = date.toFormat('yyyy-LL-dd T');

    return dateTimeString;
}

module.exports.parseCosmosDateString = parseCosmosDateString;
module.exports.toCosmosUTC = toCosmosUTC;
module.exports.toUTCSeconds = toUTCSeconds;
module.exports.toDateTimeString = toDateTimeString;