import { createSlice } from "@reduxjs/toolkit";

const initialAffiliateState = {
  listLoading: false,
  actionsLoading: false,
  companyUsers: [],
  userSubscriptions: [],
  userSubscriptionCreated: false
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const affiliateSlice = createSlice({
  name: "affiliate",
  initialState: initialAffiliateState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    companyUsersFetched: (state, action) => {
      const entities = action.payload;
      state.listLoading = false;
      state.error = null;
      state.companyUsers = entities;
    },
    userSubscriptionsFetched: (state, action) => {
      const entities = action.payload;
      state.listLoading = false;
      state.error = null;
      state.userSubscriptions = entities;
    },
    userSubscriptionCreated: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.userSubscriptionCreated = true;
    },
    userSubscriptionCreatedReset: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.userSubscriptionCreated = false;
    }
  }
});
