import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "./subscriptionsActions";
import { shallowEqual, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useSubheader } from "../../../../_metronic/layout";
import { useIntl } from "react-intl";
import { useLang } from "../../../../_metronic/i18n/Metronici18n";
import languages from "../../Shared/Enums/languages";
import { Button, Navbar } from "react-bootstrap";
import SensorLineChart from '../../Shared/Charts/sensorLineChart';
import SensorBarChart from '../../Shared/Charts/sensorBarChart';
import WindLineChart from "../../Shared/Charts/windLineChart";
import DatePicker, { registerLocale } from "react-datepicker";
import { DateTime, Interval } from 'luxon';
import { toast } from 'react-toastify';
import timezoneHelper from '../../Shared/Helpers/timezoneHelper';
import ExportAsXLS from '../../Shared/Data/exportAsXLS';
import { Alert } from "react-bootstrap";

export default function VirtualStationAvailableSensorsPage({
  history,
  match: {
    params: { virtualStationId },
  }
}) {
  const subheader = useSubheader();
  const dispatch = useDispatch();

  const langName = useLang();
  const intl = useIntl();
  const lang = languages[langName];
  const { messages: msg } = intl;

  const [title, setTitle] = useState(msg.LOADING_DATA);
  registerLocale('currentDatePickerLang', {
    localize: {
      month: n => msg.MONTHS[n],
      day: n => msg.DAYS[n]
    },
    options: {
      weekStartsOn: 1
      /* Monday */
      ,
      firstWeekContainsDate: 2
    },
    formatLong: {}
  });

  const { virtualStationAvailableSensors } = useSelector(
    (state) => ({
      actionsLoading: state.subscriptions.actionsLoading,
      virtualStationAvailableSensors: state.subscriptions.virtualStationAvailableSensors
    }),
    shallowEqual
  );

  const [stationName, setStationName] = useState('');
  const [stationSensorsList, setStationSensorsList] = useState([]);

  useEffect(() => {
    let _title = msg.SUBSCRIPTION_STATION_AVAILABLE_SENSORS;

    if (virtualStationAvailableSensors) {
      setStationName(virtualStationAvailableSensors.name);
      setStationSensorsList(virtualStationAvailableSensors.stationSensorsList);
      setTitle(`${_title} ${stationName}`);
    }

    subheader.setTitle(_title);
  }, [virtualStationAvailableSensors, []]);

  useEffect(() => {
    // let storageDateFrom = localStorage.getItem('dateFrom');

    // if (storageDateFrom) {
    //   setDateFrom(new Date(storageDateFrom));
    // } else {

    async function storeData() {
    let dateFrom = DateTime.local().minus({ days: 3 });
    let newDateFrom = timezoneHelper.toCosmosUTC(dateFrom);
    await localStorage.setItem('dateFrom', newDateFrom.dateString);
    setDateFrom(new Date(newDateFrom.dateString));
    // }

    // let storageDateTo = localStorage.getItem('dateTo');
    // if (storageDateTo) {
    //   setDateTo(new Date(storageDateTo));
    // } else {
    let dateTo = DateTime.local();
    let newDateTo = timezoneHelper.toCosmosUTC(dateTo);
    await localStorage.setItem('dateTo', newDateTo.dateString);
    setDateTo(new Date(newDateTo.dateString));
    // }
    await fetchDataInfo();
    }
    storeData();
  }, []);


  const backToLocations = () => {
    history.push(`/virtual`);
  };

  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const setDateFromToStorage = dateFrom => {
    setDateFrom(dateFrom);
    localStorage.setItem('dateFrom', dateFrom.toJSON());
  }

  const setDateToToStorage = dateTo => {
    setDateTo(dateTo);
    localStorage.setItem('dateTo', dateTo.toJSON());
  }

  let fetchDataInfo = async () => {
    let storageDateFrom = await localStorage.getItem('dateFrom');
    let storageDateTo = await localStorage.getItem('dateTo');

    let days = Interval.fromDateTimes(new Date(storageDateFrom.substring(0, 10)), new Date(storageDateTo.substring(0, 10)));

    if (days.length("days") <= 183) {
      await dispatch(actions.fetchVirtualStationAvailableSensors(virtualStationId, storageDateFrom, storageDateTo));
    } else {
      toast.error(msg.TOO_LARGE_DATE_RANGE);
    }
  }

  return (
    <Card>
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToLocations}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            {msg.MENU_RETURN}
          </button>
          {`  `}

        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Navbar className="bg-light pt-5 pb-5">
          <DatePicker
            locale="currentDatePickerLang"
            selected={dateFrom}
            dateFormat="yyyy-MM-dd"
            onChange={dateFrom => setDateFromToStorage(dateFrom)}
            className="form-control mr-5"
            popperPlacement="bottom-start"
            onKeyDown={(event) => {
              event.preventDefault();
            }}
          />

          <DatePicker
            locale="currentDatePickerLang"
            selected={dateTo}
            dateFormat="yyyy-MM-dd"
            onChange={dateTo => setDateToToStorage(dateTo)}
            className="form-control ml-2"
            popperPlacement="bottom-start"
            onKeyDown={(event) => {
              event.preventDefault();
            }}
          />

          <Button className="btn-primary btn-sm ml-4" onClick={() => fetchDataInfo()}>
            {'OK'}
          </Button>
          {stationSensorsList && stationSensorsList[0]?.sensorData?.length > 0 &&
            <div style={{ width: '100%', textAlign: 'right' }} className="text-right"><ExportAsXLS stationName={stationName} stationSensorsList={stationSensorsList} language={lang} />
            </div>}
        </Navbar>


        <div className="mt-5">
          {!virtualStationAvailableSensors && <Alert variant="warning" className="text-center" style={{ fontSize: 14 }}>{msg.LOADING_DATA}</Alert>}
          {stationSensorsList && stationSensorsList.map(stationSensor => {

            if (stationSensor.sensorData.length > 0) {

              if (stationSensor.sensorGroup.settings.chartType === 0) return (<SensorLineChart virtual={true} sensorGroup={stationSensor.sensorGroup} sensorData={stationSensor.sensorData} key={stationSensor.sensorGroup.id} stationName={stationName}/>);


              if (stationSensor.sensorGroup.settings.chartType === 1) return (<SensorBarChart virtual={true} sensorGroup={stationSensor.sensorGroup} sensorData={stationSensor.sensorData} key={stationSensor.sensorGroup.id} stationName={stationName} />);

              if (stationSensor.sensorGroup.settings.chartType === 2) {

                let windDataContainsDegrees = false;

                stationSensor.sensorData.map(dataRecord => {
                  if (dataRecord.values[0]?.deg != undefined) windDataContainsDegrees = true;
                });
                if (windDataContainsDegrees)
                  return (<WindLineChart sensorGroup={stationSensor.sensorGroup} sensorData={stationSensor.sensorData} key={stationSensor.sensorGroup.id} stationName={stationName} />); else return (<SensorLineChart sensorGroup={stationSensor.sensorGroup} sensorData={stationSensor.sensorData} key={stationSensor.sensorGroup.id} stationName={stationName} />);

              }


            }
          })}
        </div>
      </CardBody>
    </Card >
  );
}
