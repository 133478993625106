import React, { useEffect, useState, useRef } from "react";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import * as afActions from "./affiliateActions";
import { EditUserForm } from "./userSettingsForm";
import { sortCaret } from "../../../../_metronic/_helpers";
import BootstrapTable from 'react-bootstrap-table-next';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useSubheader } from "../../../../_metronic/layout";
import { toast } from 'react-toastify';
import { useIntl } from "react-intl";
import DatePicker, { registerLocale } from "react-datepicker";
import timezoneHelper from '../../Shared/Helpers/timezoneHelper';
import { Navbar, Button } from "react-bootstrap";
import  {DateTime} from 'luxon';
import axios from 'axios';

export default function AgentNewClientPage({
  history,
  match: {
    params: { id },
  }
}) {

  registerLocale('currentDatePickerLang', {
    localize: {
      month: n => msg.MONTHS[n],
      day: n => msg.DAYS[n]
    },
    options: {
      weekStartsOn: 1
      /* Monday */
      ,
      firstWeekContainsDate: 2
    },
    formatLong: {}
  });

  const dispatch = useDispatch();
  const intl = useIntl();
  const { messages: msg } = intl;
  const [title, setTitle] = useState(msg["AGENT_ACCOUNT_NEW_CLIENT"]);
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [userSurname, setUserSurname] = useState('');
  const [userMobile, setUserMobile] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [userPasswordConfirmation, setUserPasswordConfirmation] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [btnBlocked, setBtnBlocked] = useState(false);


  var errorTimeout;

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const createAccount = _ => {
    var err = false;
    setErrMsg('');

    if (!validateEmail(userEmail)) {
      setErrMsg('Nieprawidłowy adres email.');
      err = true;
    }

    if (!err && userName == '') {
      setErrMsg('Proszę podać imię.');
      err = true;
    }
    if (!err && userSurname == '') {
      setErrMsg('Proszę podać nazwisko.');
      err = true;
    }
    
    if (!err && userMobile == '') {
      setErrMsg('Proszę podać numer telefonu.');
      err = true;
    }

    if (!err && userPassword.length < 8) {
      setErrMsg('Hasło musi składać z co najmniej 8 znaków, zawierać duże litery, cyfry.');
      err = true;
    }

    if (!err && userPassword !== userPasswordConfirmation) 
    {
      setErrMsg('Hasła różnią się od siebie.');
      err = true;
    }

    if (err)
    {
      clearTimeout(errorTimeout);
      errorTimeout = setTimeout(() => {setErrMsg('')}, 5000);
    } else {
      postData();
    }
  }

  const postData = async () => {
    setBtnBlocked(true);
    try {
      await axios.post('createAccountByAgent', {userEmail, userPassword, userName, userSurname, userMobile}).then(function(response) {

        if (response.data?.err) {


          console.log(response.data);
          let decoded = JSON.parse(response.data.err);

          switch(decoded.message) {
            case 'The specified password does not comply with password complexity requirements. Please provide a different password.': alert('Hasło musi zawierać duże litery i cyfry.'); break;
            case 'Another object with the same value for property userPrincipalName already exists.': alert('Użytkownik z podanym adresem email już istnieje.'); break;
            case 'Another object with the same value for property proxyAddresses already exists.': alert('Proszę podać inny adres email.'); break;
            default: alert(decoded.message); break;
          }        
          setBtnBlocked(false);
        } else {
          alert('Konto zostało utworzone.');
          setBtnBlocked(false);
          setUserEmail('');
          setUserName('');
          setUserSurname('');
          setUserMobile('');
          setUserPassword('');
          setUserPasswordConfirmation('');
        }
      });
    } catch(e) {
      setBtnBlocked(false);
    }
  }

  return (
    <Card>
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={()=>history.push('/agentPanel')}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            {msg.MENU_RETURN}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
        <div style={{width: '70%', margin: '40px auto', textAlign: 'center'}}>
        {errMsg != '' && <div className="alert alert-danger" style={{width: '80%', margin: '40px auto', textAlign: 'center'}}>{errMsg}</div>}
              <input type="text" 
                className="form-control" 
                placeholder={`Email`} 
                style={{marginTop:15, width: '100%', borderColor: '#ccc'}} 
                value={userEmail} 
                onChange={e=>setUserEmail(e.target.value)}
              />
              <input type="text" 
                className="form-control" 
                placeholder={`Imię`} 
                style={{marginTop:15, width: '100%', borderColor: '#ccc'}} 
                value={userName} 
                onChange={e=>setUserName(e.target.value)}
              />
              <input type="text" 
                className="form-control" 
                placeholder={`Nazwisko`} 
                style={{marginTop:15, width: '100%', borderColor: '#ccc'}} 
                value={userSurname} 
                onChange={e=>setUserSurname(e.target.value)}
              />
              <input type="text" 
                className="form-control" 
                placeholder={`Numer telefonu`} 
                style={{marginTop:15, width: '100%', borderColor: '#ccc'}} 
                value={userMobile} 
                onChange={e=>setUserMobile(e.target.value)}
              />
              <input type="password" 
              className="form-control" 
              placeholder={`Hasło (min. 8 znaków)`} 
              style={{marginTop:15, width: '100%', borderColor: '#ccc'}} 
              value={userPassword} 
              onChange={e=>setUserPassword(e.target.value)}
             />
              <input type="password" 
                className="form-control mb-5" 
                placeholder={`Powtórz hasło`} 
                style={{marginTop:15, width: '100%', borderColor: '#ccc'}} 
                value={userPasswordConfirmation} 
                onChange={e=>setUserPasswordConfirmation(e.target.value)}
                />

              <Button 
                style={{marginLeft: 8, height: 42, width: '50%', marginBottom: '60px', marginTop: '30px'}} 
                className="btn"
                disabled={btnBlocked}
                onClick={()=>createAccount()}
                >
                Utwórz konto
              </Button> 
            </div>
        </div>
      </CardBody>
    </Card>
  );
}
