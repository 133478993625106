import BootstrapTable from 'react-bootstrap-table-next';
import React, { useEffect, useState } from "react";
import * as subscriptionsActions from "./subscriptionsActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Button, Badge } from "react-bootstrap";
import { sortCaret } from "../../../../_metronic/_helpers";
import WbSunny from '@material-ui/icons/WbSunny';
import BarChartIcon from '@material-ui/icons/BarChart';
import EmojiNature from '@material-ui/icons/EmojiNature';
import CenterFocusStrong from '@material-ui/icons/CenterFocusStrong';
import { useIntl } from "react-intl";
import { useLang } from "../../../../_metronic/i18n/Metronici18n";
import languages from "../../Shared/Enums/languages";
import LocalFloristIcon from "@material-ui/icons/LocalFlorist";
import CookieConsent from "react-cookie-consent";
import axios from 'axios';

export default function VirtualLocationsPage({
  history
}) {

  const intl = useIntl();
  const { messages: msg } = intl;
  const langName = useLang();
  const lang = languages[langName];

  const [postCodeCoords, setPostcodeCoords] = useState({lat: null, lng: null});
  const [postCode, setPostCode] = useState('');
  const [maxPostcodeOrCityLength, setMaxPostcodeOrCityLength] = useState(40);

  const getPostcodeCoords = async (locationString) => {
    try {
      axios.post(`/getLocationCoords`,
        { locationString 
      }).then((res) => {
        const {lat, lng} = res.data;
        setPostcodeCoords({lat, lng});
      });
    } catch (e) {
    }
  }

  const calculateDistance = (lat1, lon1, lat2, lon2) => {   
    if (lat2 == null || lon2 == null) return '-'; 
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    }
    else {
      var radlat1 = Math.PI * lat1/180;
      var radlat2 = Math.PI * lat2/180;
      var theta = lon1-lon2;
      var radtheta = Math.PI * theta/180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180/Math.PI;
      dist = dist * 60 * 1.1515;
      dist = dist * 1.609344;
      return Math.round(dist);
    }
    }

  useEffect(() => {
    if (!isNaN(postCode.charAt(0)) && !isNaN(postCode.charAt(1)) ) {
      setMaxPostcodeOrCityLength(6);
      if (postCode.length === 2) {
        setPostCode(`${postCode}-`);
      }
    }
    else {
      setMaxPostcodeOrCityLength(40);
    }
  }, [postCode]);

  let goToForecast = (stationId) => {
    history.push(`/virtual/${stationId}/forecast`)
  }

  let goToVirtualStationAvailableSensors = (id) => {
    history.push(`/virtual/${id}/sensors`)
  }

  let goToStationAvailableCultivationsForDiseases = (stationId) => {
    history.push(`/locations/${stationId}/diseases/cultivations`)
  }

  let goToStationCultivationDiseases = (stationId, cultivationId) => {
    history.push(`/locations/${stationId}/diseases/cultivation/${cultivationId}`)
  }

  let goToStationAvailableCultivationsForInsectOccurrences = (stationId) => {
    history.push(`/locations/${stationId}/insectOccurrences/cultivations`)
  }

  let goToStationCultivationInsectOccurrences = (stationId, cultivationId) => {
    history.push(`/locations/${stationId}/insectOccurrences/cultivation/${cultivationId}`)
  }

  let goToStationAvailableCultivationsForInsecModels = (stationId) => {
    history.push(`/locations/${stationId}/insectModels/cultivations`)
  }

  let goToStationCultivationInsectModels = (stationId, cultivationId) => {
    history.push(`/locations/${stationId}/insectModels/cultivation/${cultivationId}`)
  }

  const buy = _ => {
    history.push('/buy');
  }

  const { virtualStations, error } = useSelector(
    (state) => ({
      virtualStations: state.subscriptions.virtualStations,
      error: state.subscriptions.error
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(subscriptionsActions.resetVirtualStations());
    dispatch(subscriptionsActions.fetchAvailableVirtualStations());
  }, [dispatch]);

  useEffect(() => {
    console.log(virtualStations);
  }, [virtualStations]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(subscriptionsActions.fetchAvailableVirtualStations());
    }, 20000);
    return () => clearInterval(interval);
  }, []);

  const [filterString, setFilterString] = useState('');
  const [filteredEntities, setFilteredEntities] = useState([]);

  useEffect(() => {
    if (virtualStations)
    {
      let filtered = virtualStations.filter(s=>s.name.toLowerCase().includes(filterString.toLowerCase()));
      var entitiesToAdd = [];
      if (filtered.length>0)
      for (var i=0; i < filtered.length; i++) {
        let distance = calculateDistance(filtered[i].latitude, filtered[i].longitude, postCodeCoords.lat, postCodeCoords.lng);
        let entityToAdd = {...filtered[i], distance};
        entitiesToAdd.push(entityToAdd);
      }
    setFilteredEntities(entitiesToAdd);
    }
  }, [filterString, virtualStations, postCodeCoords]);

  const [columns, setColumns] = useState([
    {
      dataField: 'name',
      text: msg.SUBSCRIPTION_LOCATION,
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        <>
          {row.name}
        </>
      )
    },
    {
      dataField: 'distance',
      text: `${msg.DISTANCE_TO_STATION} [km]`,
      sortValue: (cell, row) => row.distance,
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        <>
          {row?.distance ? row.distance : '-' }
        </>
      ),
    },
    {
      dataField: 'latitude',
      text: 'Lokalizacja',
      formatter: (cellContent, row) => (
        <a target="blank" href={"https://www.google.pl/maps/@" + row.latitude + "," + row.longitude + ",17z"}>{row.latitude}, {row.longitude}</a>
      )
    },
    {
      dataField: 'stationData',
      text: msg.SUBSCRIPTION_STATION_DATA,
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        <>
          <Button key={`stationData${row.id}`} className="btn-success" onClick={() => goToVirtualStationAvailableSensors(row.id)}><BarChartIcon /></Button>
        </>
      )
    },
    // {
    //   dataField: 'fullAccessDisease',
    //   text: msg.SUBSCRIPTION_DISEASES,
    //   formatter: (cellContent, row) => {

    //     if (row.diseases) {
    //       if (row.allCultivationsDiseases != true) {
    //         if (row.cultivationsDiseases.length > 0) {
    //           return (
    //             <>
    //               {
    //                 row.cultivationsDiseases.length > 0 && row.cultivationsDiseases.map((cultivation, cultKey) => {
    //                   return <Badge key={`cultivationDisease${cultKey}-${row.stationId}`} variant="primary" className="mr-2 mb-1 mt-1" role="button" onClick={() => goToStationCultivationDiseases(row.stationId, cultivation.id)}><LocalFloristIcon className="mr-1" /> {cultivation.names[lang]}</Badge>
    //                 })
    //               }
    //             </>
    //           )
    //         } else return '-'
    //       } else {
    //         return <Badge key={`allDiseases${row.stationId}`} variant="primary" role="button" onClick={() => goToStationAvailableCultivationsForDiseases(row.stationId)}><LocalFloristIcon className="mr-2" />
    //           {msg.SUBSCRIPTION_ALL_CULTIVATIONS}</Badge>
    //       }
    //   } else return '-';
    //   },
    // },
    // {
    //   dataField: 'insectModels',
    //   text: msg.SUBSCRIPTION_INSECT_MODELS,
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: (cellContent, row) => {

    //      if (row.insectOccurrences) {
    //       if (row.allCultivationsInsects != true) {
    //           return (
    //             <>
    //               {
    //                 row.cultivationsInsects.length > 0 && row.cultivationsInsects.map((cultivation, cultKey) => {
    //                   let cultivationInsectModel = row.cultivations.find(c => c.id == cultivation.id);
    //                   if (cultivationInsectModel?.insectModels?.length == 0 || !cultivationInsectModel?.insectModels) return '-'; else
    //                   return <Badge key={`insectModel${cultKey}-${row.stationId}`} variant="info" className="btnInsectModels mr-2 mb-1 mt-1" style={{backgroundColor: '#6035b7'}} role="button" onClick={() => goToStationCultivationInsectModels(row.stationId, cultivation.id)}><EmojiNature className="mr-1" /> {cultivation.names[lang]}</Badge>
    //                 })
    //               }
    //               { row.cultivationsInsects.length == 0 && '-'}
    //             </>
    //           )
    //       } else {
    //         return <Badge key={`allInsectModels${row.stationId}`}  variant="info" role="button" style={{backgroundColor: '#6035b7'}} onClick={() => goToStationAvailableCultivationsForInsecModels(row.stationId)}><EmojiNature className="mr-2" />
    //           {msg.SUBSCRIPTION_ALL_CULTIVATIONS}</Badge>
    //       }
    //   } else return '-';
    //   }
    // },
    // {
    //   dataField: 'insectOccurrences',
    //   text: msg.SUBSCRIPTION_INSECT_OCCURENCES,
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: (cellContent, row) => {

    //      if (row.insectOccurrences) {
    //       if (row.allCultivationsInsectOccurrences != true) {
    //         if (row.cultivationsInsectOccurrences.length > 0) {
    //           return (
    //             <>
    //               {
    //                 row.cultivationsInsectOccurrences.length > 0 && row.cultivationsInsectOccurrences.map((cultivation, cultKey) => {
    //                   return <Badge key={`insectOccurence${cultKey}-${row.stationId}`} variant="info" className="mr-2 mb-1 mt-1" role="button" onClick={() => goToStationCultivationInsectOccurrences(row.stationId, cultivation.id)}><CenterFocusStrong className="mr-1" /> {cultivation.names[lang]}</Badge>
    //                 })
    //               }
    //             </>
    //           )
    //         } else return '-'
    //       } else {
    //         return <Badge key={`allInsectOccurrences${row.stationId}`}  variant="info" role="button" onClick={() => goToStationAvailableCultivationsForInsectOccurrences(row.stationId)}><CenterFocusStrong className="mr-2" />
    //           {msg.SUBSCRIPTION_ALL_CULTIVATIONS}</Badge>
    //       }
    //   } else return '-';
    //   }
    // },
    {
      dataField: 'forecast',
      text: msg.SUBSCRIPTION_FORECAST,
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        <>
          <Button key={`forecast-${row.id}`} className="btn-warning" onClick={() => goToForecast(row.id)}><WbSunny /></Button>
        </>
      )
    }
  ]);

  return (<>
          <CookieConsent
          location="middle"
          cookieName="TermsAcceptFarmSmart"
          style={{ background: "#1e1d2d", opacity: 0.8, height: '84px', lineHeight: '40px' }}
          buttonStyle={{ color: "#fff", backgroundColor: '#3599ff', fontSize: "13px" }}
          expires={365}
          buttonText={msg.ACCEPT_TERMS}
          overlay={true}
        >
          <div style={{fontSize: '15px', textAlign: 'center'}}>
            {msg.ACCEPT_TERMS_TEXT} <a target="_blank" href="https://farmsmart.pl/regulamin-portalu/">{msg.TERMS}</a>.
          </div>
        </CookieConsent>
    <Card>
      <CardHeader title={msg.VIRTUAL_LOCATIONS_LIST}>
        <div className="mt-2">
        <label className='mr-3'>
            <input type="text" 
              className="form-control" 
              placeholder={msg.STATION_SEARCH} 
              style={{marginTop:15, width: 250}} 
              value={filterString} 
              onChange={e=>setFilterString(e.target.value)}
              />
        </label> {`${msg.OR}`}
        <label className='ml-3'>
            <input type="text" 
              className="form-control" 
              placeholder={msg.POST_CODE_OR_CITY} 
              style={{marginTop:6, width: 250}} 
              value={postCode} 
              maxLength={maxPostcodeOrCityLength}
              onChange={e=>{if (e.nativeEvent.data == '-') return false; if (e.nativeEvent.inputType === 'deleteContentBackward') setPostCode(''); else setPostCode(e.target.value)}}
              onKeyDown={e => {if (e.nativeEvent.key == 'Enter') getPostcodeCoords(postCode)}}
              />
          </label> 
          <Button 
            style={{marginLeft: 8, height: 42, marginTop: -2}} 
            className="btn-sm"
            onClick={()=>{getPostcodeCoords(postCode)}} 
            >{msg.SHOW_NEAREST_LOCATIONS}
          </Button>
          </div>
      </CardHeader>
      <CardBody>
        {virtualStations && <>
        <BootstrapTable keyField='id' data={filteredEntities || []} columns={columns}
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
          sort={{dataField: 'distance', order: 'asc'}}
        />
        </>}
        {virtualStations && virtualStations.length == 0 && <div className="text-center mb-5 mt-5">
          <h5>{msg.NO_AVAILABLE_VIRTUAL_LOCATIONS}</h5>
          {/* <input className="btn btn-primary mt-5" type="button" onClick={_=>buy()} value={`${msg.BUY}`} /> */}
        </div>}
        {filteredEntities && filterString !== '' && filteredEntities.length===0 && <div className="text-center mb-5 mt-5">
          <h5>{msg.NO_SEARCH_RESULTS}</h5>
        </div>}
      </CardBody>
    </Card></>
  )
}