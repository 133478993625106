/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import LocationOn from '@material-ui/icons/LocationOn';
import GrainIcon from '@material-ui/icons/Grain';
import LandscapeIcon from '@material-ui/icons/Landscape';
import AndroidIcon from '@material-ui/icons/Android';
import AppleIcon from '@material-ui/icons/Apple';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import NigthsStayIcon from '@material-ui/icons/NightsStay';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ShoppingCartIcon from '@material-ui/icons/ShoppingBasketOutlined';
import { useIntl } from "react-intl";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const intl = useIntl();
  const { messages: msg } = intl;

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li className="menu-section ">
          <h4 className="menu-text">{msg["ASIDE.MENU.HEADER"]}</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/locations", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/locations">
            <span className="svg-icon menu-icon">
              <LocationOn />
            </span>
            <span className="menu-text">{msg["ASIDE.MENU.LOCATIONS"]}</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/buy", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/buy">
            <span className="svg-icon menu-icon">
              <ShoppingCartIcon />
            </span>
            <span className="menu-text">{msg["ASIDE.MENU.SUBSCRIPTIONS"]}</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/subscriptions", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/subscriptions">
            <span className="svg-icon menu-icon">
              <FormatListBulletedIcon />
            </span>
            <span className="menu-text">{msg["ASIDE.MENU.USER_SUBSCRIPTIONS"]}</span>
          </NavLink>
        </li>
        <li
          className="menu-item"
          aria-haspopup="true"
        >
          <a className="menu-link" target="_blank" href={`https://app.farmportal.eu/load-asl-data?tokenId=${sessionStorage.getItem('msal.09354d56-898b-48cc-90c9-4c1021d6f764.idtoken')}`}>
            <span className="svg-icon menu-icon">
              <AssignmentIcon />
            </span>
            <span className="menu-text">{msg["ASIDE.MENU.FIELD_MANAGER"]}</span>
          </a>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/calculator/fertilization", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/calculator/fertilization">
            <span className="svg-icon menu-icon">
              <GrainIcon />
            </span>
            <span className="menu-text">{msg["ASIDE.MENU.FERTILIZER_CALCULATOR"]}</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/calculator/emission", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/calculator/emission">
            <span className="svg-icon menu-icon">
              <LandscapeIcon />
            </span>
            <span className="menu-text">{msg["ASIDE.MENU.EMISSION_CALCULATOR"]}</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/virtual", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/virtual">
            <span className="svg-icon menu-icon">
              <NigthsStayIcon />
            </span>
            <span className="menu-text">{msg["ASIDE.MENU.VWS"]}</span>
          </NavLink>
        </li>
        <li className="menu-section ">
          <h4 className="menu-text">{msg["DOWNLOAD_APP"]}</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <li
          className={`menu-item`}
          aria-haspopup="true"
        >
          <a className="menu-link" target="_blank" href="https://play.google.com/store/apps/details?id=pl.farmsmart.app">
            <span className="svg-icon menu-icon">
              <AndroidIcon />
            </span>
            <span className="menu-text">{`Android`}</span>
          </a>
        </li>
        <li
          className={`menu-item`}
          aria-haspopup="true"
        >
          <a className="menu-link" target="_blank" href="https://apps.apple.com/us/app/farm-smart/id6446028316">
            <span className="svg-icon menu-icon">
              <AppleIcon />
            </span>
            <span className="menu-text">{`iOS`}</span>
          </a>
        </li>
        
      </ul>

    </>
  );
}
