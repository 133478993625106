import React, { useEffect, useState, useRef } from "react";
import { Line, Chart } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import _ from "lodash";
import timezoneHelper from '../Helpers/timezoneHelper';
import { useLang } from "../../../../_metronic/i18n/Metronici18n";
import languages from "../Enums/languages";
import { Badge } from "react-bootstrap";
import ExportAsJPG from '../Data/exportAsJPG';
import 'chartjs-adapter-moment';

export default function DiseaseLineMultiChart({
    chartSettings,
    chartData,
    stationName,
    cultivationName
}) {

    const toggleXAxesDisplayPlugin = {
        id: 'toggleXAxesDisplayPlugin',
        resize: function(chartInstance) {
            if (window.innerWidth > 1200) {
                chartInstance.config.options.aspectRatio = 2;
                chartInstance.config.options.scales.x.ticks.font.size = 11;
            } else
            if (window.innerWidth > 800) {
                chartInstance.config.options.aspectRatio = 1.4;
                if (window.innerWidth > window.innerHeight) chartInstance.config.options.aspectRatio = 1.5;
                chartInstance.config.options.scales.x.ticks.font.size = 11;
            } else 
            if (window.innerWidth > 500) {
                chartInstance.config.options.aspectRatio = 1;
                if (window.innerWidth > window.innerHeight) chartInstance.config.options.aspectRatio = 1.5;
                chartInstance.config.options.scales.x.ticks.font.size = 11;
            } else 
            if (window.innerWidth > 400) {
                chartInstance.config.options.aspectRatio = 0.8;
                chartInstance.config.options.scales.x.ticks.font.size = 10;
            } else {
                chartInstance.config.options.aspectRatio = 0.6;
                chartInstance.config.options.scales.x.ticks.font.size = 8;
            }

            chartInstance.update();
        }
    }

    const backgroundPlugin = {
        id: 'backgroundPlugin',
        beforeDraw: function (chart) {
            var ctx = chart.canvas.getContext('2d');
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
        }
    }

    Chart.register([annotationPlugin, backgroundPlugin, toggleXAxesDisplayPlugin]);
    const langName = useLang();
    const lang = languages[langName];
    const chartRef = useRef(null);

    var dataSets = [];
    var levels;
    var labelsArray = [];

    useEffect(() => {
        if (chartSettings) {
            let {minVal, maxVal, unit, pointRadius } = chartSettings;
            levels = chartSettings.levels;

            chartSettings.diseases.map((disease, key) => {
                disease.selectedResults.map((res, resKey) => {

                    let foundData = chartData.find(entity=> entity.modelId == disease.diseaseId && entity.resultKey == res.resultKey);

                    if (foundData) {
                    //find Data here
                        let data = [];

                        for (const dataEntity of foundData.data) {
                            let { modelValue } = dataEntity;

                            if (modelValue !== null) {
                                let date = timezoneHelper.parseCosmosDateString(dataEntity.date, 'Europe/Warsaw');
                                let x = timezoneHelper.toDateTimeString(date);
                                if (!labelsArray.includes(x))
                                    labelsArray.push(x);
                                let y;
                                if (res.recalculate) {
                                   y = Math.round((parseInt(modelValue) / (res.maxVal - res.minVal)) * 100);
                                } else {
                                   y = modelValue;
                                }

                                data.push({x,y});
                            }
                        }

                        let dataSet = {
                            id: key,
                            label: res.names[lang],
                            data,
                            backgroundColor: `${res.lineColor}`,
                            borderColor: `${res.lineColor}`,
                            borderWidth: 0,
                            pointRadius: 0,
                            borderWidth: res.lineHeight,
                            fill: false,
                            pointStyle: 'circle',
                            pointRadius: 1,
                            tension: 0.2
                        }

                        dataSets.push(dataSet);
                    }

                });
            });

            setLabels(labelsArray);
            setDiseaseChartData(dataSets);
            } 

            setChartLabel(`${chartSettings.names[lang]} [${chartSettings.unit}]`);
            // setLineColor(diseaseModelResultSettings.lineColor);
            // setLineHeight(diseaseModelResultSettings.lineHeight);
            // setPointRadius(diseaseModelResultSettings.pointRadius);            

            const annotationModel = {
                drawTime: "beforeDatasetsDraw",
                display: true,
                type: "box",
                yMin: null,
                yMax: null,
                backgroundColor: "",
                borderColor: "",
                borderWidth: 0
            }

            let createdAnnotations = {};
            let maxKey;

            levels.map((level, key) => {
                let newAnnotation = { ...annotationModel };
                    newAnnotation.yMin = (level.minVal-0);
                    newAnnotation.yMax = (level.maxVal-0);
                
                if (newAnnotation.yMin == 0) newAnnotation.yMin = -1;
                if (newAnnotation.yMax == 100) newAnnotation.yMax = 101;
                newAnnotation.backgroundColor = `${level.color}`;
                newAnnotation.borderColor = `${level.color}`;
                
                createdAnnotations[`box${key + 1}`] = { ...newAnnotation };
                maxKey = key;
            });

            const optionsModel = {
                scales: {
                    y: {
                        ticks: {
                            stepSize: 1,
                            max: 100,
                            min: 0,
                            beginAtZero: true,
                            callback: function(val, index) {
                                if (val < 0 ) return '0';
                                if (val > 100) return '100';
                                return val;
                            }
                        }
                    },
                    x: {
                        type: 'timeseries',
                        ticks: {
                            font: {
                                size: 11,
                            }
                        },
                        type: 'time',
                        time: {
                            displayFormats: {
                                hour: 'YY-MM-DD HH:00'
                            },
                            unit: 'hour',
                            tooltipFormat: 'YYYY-MM-DD HH:00' 
                        }
                      }
                },
                plugins: {
                    datalabels: {
                        color: "black",
                        labels: {
                            title: {
                                font: {
                                    weight: "bold"
                                }
                            }
                        }
                    },
                    annotation: {
                        annotations: {
                        }
                    },
                    title: {
                        display: true,
                        text: Array(chartSettings.names[lang],[], `${cultivationName}, ${stationName}`),
                        font: {
                            family: "Poppins, Helvetica",
                            size: 18,
                            weight: 444,
                        }
                    }
                }
            };

            let annotationsToAdd = {...createdAnnotations};

            optionsModel.plugins.annotation.annotations = annotationsToAdd;

            setChartOptions(optionsModel);

            setTimeout(() => {
                setOptionsArePrepared(true);
            }, 200);

        

    }, [chartData]);

    const [labels, setLabels] = useState([]);
    const [diseaseChartData, setDiseaseChartData] = useState([]);
    const [chartLabel, setChartLabel] = useState('');
    const [optionsArePrepared, setOptionsArePrepared] = useState(false);
    const [chartOptions, setChartOptions] = useState();
    const [lineColor, setLineColor] = useState('#FF0000');
    const [lineHeight, setLineHeight] = useState(2);
    const [pointRadius, setPointRadius] = useState(2);

    const data = (canvas, cenas) => {
        const chartData = {
            labels: [...labels],
            datasets: diseaseChartData
        };

        return chartData;
    };

    return (
        <div className="container p-5">
            <div className="mt-5 mb-5 text-center col-sm-12 col-md-12 col-lg-9 mx-auto">
                <h4>
                    {optionsArePrepared && <ExportAsJPG fileName={'Wykres'} chartRef={chartRef} />}
                </h4>
                {optionsArePrepared && <Line ref={chartRef} data={data} options={chartOptions} />}
                <div className="mt-3">{chartSettings && chartSettings.levels.map((level, key) => {
                    return <Badge key={key} className="mr-2" style={{ backgroundColor: level.color }}>{level.names[lang]}</Badge>
                })}</div>
                
            </div>
            <hr />
        </div>
    );
}