const languages = {
    pl: 0,
    en: 1,
    properties:
        [
            { name: "pl", id: 0, text: "Język polski" },
            { name: "en", id: 1, text: "English" }
        ]
};

module.exports = languages;