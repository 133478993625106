import axios from "axios";

export const SUBSCRIPTIONS_URL = "subscriptions";

export function pay(order, langName, invoiceData, discountCode) {
  return axios.post('pay', {order, langName, invoiceData, discountCode});
}

export function getUserSettings() {
  return axios.get('/users/desktopSettings');
}

export function getLocations() {
  return axios.get('locations');
}

export function getSubscriptions() {
  return axios.get('getSubscriptions');
}

export function getUserInvoiceInfo() {
  return axios.get('getUserInvoiceInfo');
}

export function getCultivationsList() {
  return axios.get('locations/getCultivationsList');
}

export function getUserCultivationsList() {
  return axios.get('locations/getUserCultivationsList');
}

export function getAllAvailableLocations() {
  return axios.get(`locations/available`);
}

export function getAffiliateEarnings(dateFrom, dateTo) {
  return axios.post(`getAffiliateEarnings`, {dateFrom, dateTo});
}

export function calculateOrder(order, discountCode) {
  return axios.post(`${SUBSCRIPTIONS_URL}/calculateOrder`, { order, discountCode });
}

export function getStationForecast(stationId, langName) {
  return axios.get(`${SUBSCRIPTIONS_URL}/forecast/${stationId}/${langName}`);
}

export function getVirtualStationForecast(stationId, langName) {
  return axios.get(`${SUBSCRIPTIONS_URL}/virtualStation/forecast/${stationId}/${langName}`);
}

export function getStationAvailableSensors(stationId, dateFrom, dateTo) {
  return axios.post(`${SUBSCRIPTIONS_URL}/stationAvailableSensors/${stationId}`, { dateFrom, dateTo });
}

export function getStationAvailableDiseaseModels(stationId, cultivationId, dateFrom, dateTo) {
  return axios.post(`${SUBSCRIPTIONS_URL}/stationAvailableDiseases/${stationId}/${cultivationId}`, { dateFrom, dateTo });
}

export function getStationDiseasesCultivations(stationId) {
  return axios.get(`${SUBSCRIPTIONS_URL}/station/${stationId}/diseases/cultivations`);
}

export function getStationInsectOccurrencesCultivations(stationId) {
  return axios.get(`${SUBSCRIPTIONS_URL}/station/${stationId}/insectOccurrences/cultivations`);
}

export function getStationInsectModelsCultivations(stationId) {
  return axios.get(`${SUBSCRIPTIONS_URL}/station/${stationId}/insectModels/cultivations`);
}

export function getStationCultivationInsectOccurrences(stationId, cultivationId, dateFrom, dateTo) {
  return axios.post(`${SUBSCRIPTIONS_URL}/station/insectOccurrences/${stationId}/${cultivationId}`, { dateFrom, dateTo });
}

export function getStationCultivationInsectModels(stationId, cultivationId, year) {
  return axios.post(`${SUBSCRIPTIONS_URL}/station/insectModels/${stationId}/${cultivationId}`, { year });
}

export function getStationCultivationInfo(stationId, cultivationId) {
  return axios.post(`${SUBSCRIPTIONS_URL}/stationCultivationInfo`, { stationId, cultivationId });
}

export function getPrices() {
  return axios.get(`${SUBSCRIPTIONS_URL}/getPrices`);
}

export function getVirtualStationAvailableSensors(virtualStationId, dateFrom, dateTo) {
  return axios.post(`${SUBSCRIPTIONS_URL}/virtualStationAvailableSensors/${virtualStationId}`, { dateFrom, dateTo });
}

export function getAvailableVirtualStations() {
  return axios.get(`${SUBSCRIPTIONS_URL}/availableVirtualStations`);
}

export function getDiscountCodeInfo(code) {
  return axios.post(`/getDiscountCodeInfo`, {code});
}

export function getSubscriptionsOrders() {
  return axios.get('/orders/list');
}