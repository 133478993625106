import * as requestFromServer from "./affiliateApi";
import { affiliateSlice, callTypes } from "./affiliateSlice";

const { actions } = affiliateSlice;

export const fetchCompanyUsers = _ => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getCompanyUsers()
    .then(response => {
      const entities = response.data;
      dispatch(actions.companyUsersFetched(entities));
    })
    .catch(error => {
      error.clientMessage = "Can't find users";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

export const fetchUserSubscriptions = userId => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAgentUserSubscriptions(userId)
    .then(response => {
      const entities = response.data;
      dispatch(actions.userSubscriptionsFetched(entities));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch users subscriptions";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

export const createUserSubscription = (userId, selectedStation, selectedCultivation, monthsCount) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .postAgentUserSubscription(userId, selectedStation, selectedCultivation, monthsCount)
    .then(response => {
      const entity = response.data;
      dispatch(actions.userSubscriptionCreated(entity));
      dispatch(actions.userSubscriptionCreatedReset());
    })
    .catch(error => {
      error.clientMessage = "Can't create user subscriptions";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}