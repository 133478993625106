/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useSubheader } from "../../../_metronic/layout";
import { useIntl, FormattedHTMLMessage } from "react-intl";
import { useLang } from "../../../_metronic/i18n/Metronici18n";
import { Button } from "react-bootstrap";
import {Checkbox} from '@material-ui/core';
import axios from 'axios';
import GoogleMapReact from 'google-map-react';
import ReCAPTCHA from "react-google-recaptcha";
import reCaptchaKey from "../Shared/PublicKeys/reCaptcha";
import googleMap from "../Shared/PublicKeys/googleMapKey";
import LocationOn from '@material-ui/icons/LocationOn';

export default function SignUpMapCommonPage({
  history,
  companyName
}) {
  const subheader = useSubheader();
  const dispatch = useDispatch();
  var marker;
  var lines = [];
  const ref = useRef(null);
  const langName = useLang();
  const intl = useIntl();
  const { messages: msg } = intl;
  const recaptcha = useRef();
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [stations, setStations] = useState(null);
  const [cultivations, setCultivations] = useState(null);
  const [cultivation, setCultivation] = useState(null);
  const [selectedStation, setSelectedStation] = useState(null);
  const [postCode, setPostCode] = useState('');
  const [postCodeCoords, setPostcodeCoords] = useState({lat: null, lng: null});

  const handleApiLoaded = async (mapEntity, mapsEntity) => {
    setMap(mapEntity);
    setMaps(mapsEntity);
  };

  useEffect(() => {
    const instance = axios.create({
      baseURL: 'https://api.admin.farmsmart.pl/getStationAvailableForUser'
    });

    instance('').then(res => {
      const {data} = res;
      setStations(data);
    });

    const instance2 = axios.create({
      baseURL: 'https://api.admin.farmsmart.pl/getCultivationsAvailableForUser'
    });

    instance2('').then(res => {
      const {data} = res;

      setCultivations(data.sort(compare));
    });
  }, []);

  const [mapSettings, setMapSettings] = useState({
    center: {
      lat: 52.133231,
      lng: 19.596804
    },
    zoom: 6
  });

  function compare( a, b ) {
    if ( a.names[0] < b.names[0] ){
      return -1;
    }
    if ( a.names[0] > b.names[0] ){
      return 1;
    }
    return 0;
  }

  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [userSurname, setUserSurname] = useState('');
  const [userMobile, setUserMobile] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [userPasswordConfirmation, setUserPasswordConfirmation] = useState('');
  const [termsAccept, setTermsAccept] = useState(false);
  const [logoImage, setLogoImage] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [token, setToken] = useState('');
  const [btnBlocked, setBtnBlocked] = useState(false);
  const [code, setCode] = useState('');
  const [maxPostcodeOrCityLength, setMaxPostcodeOrCityLength] = useState(40);
  const [markerPos, setMarkerPos] = useState({lat: null, lng: null});
  const [promoCode, setPromoCode] = useState('');

  useEffect(()=>{
    if (!isNaN(postCode.charAt(0)) && !isNaN(postCode.charAt(1)) ) {
      setMaxPostcodeOrCityLength(6);
      if (postCode.length === 2) {
        setPostCode(`${postCode}-`);
      }
    }
    else {
      setMaxPostcodeOrCityLength(40);
    }
  }, [postCode]);

  const getPostcodeCoords = async (locationString) => {
    try {
      axios.post(`/getLocationCoords`,
        { locationString 
      }).then((res) => {
        // const {lat, lng} = res.data.results[0].geometry.location;
        const {lat, lng} = res.data;
        setPostcodeCoords({lat, lng});
        map.setCenter({lat, lng});

        if (postCode == '')
        map.setZoom(7);
        putMarker(lat, lng);
        
       resetLines();
      });
    } catch (e) {
    }
  }

  const calculateDistance = (lat1, lon1, lat2, lon2) => {    
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    }
    else {
      var radlat1 = Math.PI * lat1/180;
      var radlat2 = Math.PI * lat2/180;
      var theta = lon1-lon2;
      var radtheta = Math.PI * theta/180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180/Math.PI;
      dist = dist * 60 * 1.1515;
      dist = dist * 1.609344;
      return dist;
    }
    }
  
  const drawLines = (lat, lng) => {
    stations.map((location)=> {

      const distance = calculateDistance(location.latitude, location.longitude, lat, lng);
      if (distance <= 20)
      {
        var newline = new maps.Polyline({
          path: [
              new maps.LatLng(lat, lng), 
              new maps.LatLng(location.latitude, location.longitude)
          ],
          strokeColor: "#FF0000",
          strokeOpacity: 1.0,
          strokeWeight: 2,
          map: map
        });
        lines.push(newline);
      }
    
 });
  }

  const resetLines = _ => {
    if (lines.length > 0) {
      for (let loop=0; loop<lines.length; loop++) 
      {                           
        lines[loop].setMap(null);
      }
      lines = [];
    }
  }

  const putMarker = (lat, lng) => {
    if (marker!=null)
        marker.setMap(null);
      marker = new maps.Marker({
        position: {lat, lng},
        map,
        draggable:true,
        labelInBackground: false,
        labelAnchor: new maps.Point(0, 40),
        animation: maps.Animation.DROP,
        label: {
          text: `${msg.DRAG_TO_SELECT_LOCATION}`,
          color: 'black',
          fontSize: '16px',      
          className: 'marker-position',
        },
      });
      setTimeout(() => {
        drawLines(lat, lng);
      }, 1000);
      marker.addListener('drag', e => {
        let {lat, lng} = marker.getPosition().toJSON();
        setPostcodeCoords({lat, lng});
        resetLines();
        drawLines(lat, lng);
      });

      marker.addListener('dragend', e => {
        let {lat, lng} = marker.getPosition().toJSON();
        if (map.getZoom() < 10)
        map.setZoom(10);
        map.setCenter({lat, lng});
        setMarkerPos({lat, lng});
      });
}

  useEffect(() => {
    document.title = "Tworzenie nowego konta";

    switch(companyName) {
      case 'Plantpress': setLogoImage('plantpress'); break;
      case 'PZU': setLogoImage('PZU'); break;
      case 'PodOslonami': setLogoImage('podoslonami'); break;
    }
  }, []);

  var errorTimeout;

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const createAccount = token => {
    var err = false;
    setErrMsg('');

    if (!validateEmail(userEmail)) {
      setErrMsg('Nieprawidłowy adres email.');
      err = true;
    }

    if (!err && userName == '') {
      setErrMsg('Proszę podać imię.');
      err = true;
    }
    if (!err && userSurname == '') {
      setErrMsg('Proszę podać nazwisko.');
      err = true;
    }
    
    if (!err && userMobile == '') {
      setErrMsg('Proszę podać numer telefonu.');
      err = true;
    }

    if (!err && userPassword.length < 8) {
      setErrMsg('Hasło musi składać z co najmniej 8 znaków, zawierać duże litery, cyfry.');
      err = true;
    }

    if (!err && userPassword !== userPasswordConfirmation) 
    {
      setErrMsg('Hasła różnią się od siebie.');
      err = true;
    }

    if (err)
    {
      clearTimeout(errorTimeout);
      errorTimeout = setTimeout(() => {setErrMsg('')}, 5000);
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    } else {
      postData(token);
    }
  }

  const postData = async (token) => {
    setBtnBlocked(true);
    try {
      
      await axios.post('createAccount', {userEmail, userName, userSurname, userMobile, userPassword, companyName, token, selectedStation, cultivation, promoCode}).then(function(response) {

        if (response.data?.err) {

          let decoded = JSON.parse(response.data.err);

          switch(decoded.message) {
            case 'The specified password does not comply with password complexity requirements. Please provide a different password.': alert('Hasło musi zawierać duże litery i cyfry.'); break;
            case 'Another object with the same value for property userPrincipalName already exists.': alert('Użytkownik z podanym adresem email już istnieje.'); break;
            case 'Another object with the same value for property proxyAddresses already exists.': alert('Proszę podać inny adres email.'); break;
            default: alert(decoded.message); break;
          }        
          recaptcha.current.reset();
          setBtnBlocked(false);
        } else {
          alert('Konto zostało utworzone.');
          window.location.href = '/';
        }
      });
    } catch(e) {
      setBtnBlocked(false);
      console.log(e);
    }
  }

  const verify = async () => {
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      alert("Proszę zaznaczyć pole \"Nie jestem robotem\".");
      setToken(token);
    } else {
      await createAccount(captchaValue);
    }
  }

  let Marker = ({location, markerColor, markerSize, entity}) => {
    let {name, latitude, longitude} = location;
    
    return <div lat={latitude} lng={longitude}><LocationOn onClick={()=>clickMarker(entity)} style={{color: markerColor, fontSize: `${markerSize}px`, marginTop: '-38px', marginLeft: '-16px'}} /> <h4>{name}</h4> </div>
  }
  
  const clickMarker = (location) => {
    setSelectedStation(location);
  }

  return (

        <div className="text-center" style={{backgroundImage: "url('/media/bg/illustration.jpg')", backgroundSize: 'cover', width: '100%', height: 'auto' }}>
          <div ref={ref} style={{width: '50%', minWidth: '600px', height: 'auto', margin: '5% auto', backgroundColor: 'rgba(255,255,255,0.98)', borderRadius: '5px', paddingTop: '20px'}}>
          <div>
              <img src="/media/logos/farm-smart-logo.png" style={{width: '40%', height: 'auto', margin: '0 5%'}} className="mt-5" />
              {companyName!=="FarmSmart"&&<img src={`/media/logos/${logoImage}.png`} style={{width: '40%', height: 'auto', margin: '0 5%', padding: '1%'}} className="mt-5" />}
            </div>
            {companyName === 'PZU' && <div style={{width: '80%', margin: '20px auto'}}><FormattedHTMLMessage id="PZU_DESC"/></div>}
            {errMsg != '' && <div className="alert alert-danger" style={{width: '80%', margin: '40px auto', textAlign: 'center'}}>{errMsg}</div>}
            <div style={{width: '80%', margin: '40px auto', textAlign: 'center'}}>
              <input type="text" 
                className="form-control" 
                placeholder={`Email`} 
                style={{marginTop:15, width: '100%', borderColor: '#ccc'}} 
                value={userEmail} 
                onChange={e=>setUserEmail(e.target.value)}
              />
              <input type="text" 
                className="form-control" 
                placeholder={`Imię`} 
                style={{marginTop:15, width: '100%', borderColor: '#ccc'}} 
                value={userName} 
                onChange={e=>setUserName(e.target.value)}
              />
              <input type="text" 
                className="form-control" 
                placeholder={`Nazwisko`} 
                style={{marginTop:15, width: '100%', borderColor: '#ccc'}} 
                value={userSurname} 
                onChange={e=>setUserSurname(e.target.value)}
              />
              <input type="text" 
                className="form-control" 
                placeholder={`Numer telefonu`} 
                style={{marginTop:15, width: '100%', borderColor: '#ccc'}} 
                value={userMobile} 
                onChange={e=>setUserMobile(e.target.value)}
              />
              <input type="password" 
              className="form-control" 
              placeholder={`Hasło (min. 8 znaków)`} 
              style={{marginTop:15, width: '100%', borderColor: '#ccc'}} 
              value={userPassword} 
              onChange={e=>setUserPassword(e.target.value)}
             />
            <input type="password" 
              className="form-control mb-5" 
              placeholder={`Powtórz hasło`} 
              style={{marginTop:15, width: '100%', borderColor: '#ccc'}} 
              value={userPasswordConfirmation} 
              onChange={e=>setUserPasswordConfirmation(e.target.value)}
              />
             
              <div style={{ height: '600px', width: '100%'}}>
                <h6>Dane ze stacji, prognoza pogody oraz monitoring chorób i szkodników na 14 dni za 0 zł.</h6>
                <h6>Proszę wybrać najbliższą Państwu stację pogodową oznaczoną symbolem <LocationOn/></h6>
                <h4 style={{color:'darkblue'}}>{`${selectedStation!==null?selectedStation?.name:'Nie wybrano stacji pogodowej.'}`}</h4>
            <div className="row mt-5 mb-5">
              <select className="form-control" onChange={e => setCultivation(e.target.value)}>
                <option value={null}>Wybierz uprawę</option>
                  {cultivations && cultivations.map(cultivation => (
                    <option value={cultivation.id} key={cultivation.id}>{cultivation.names[0]}</option>
                  ))}
              </select>
            </div>
            {companyName==="FarmSmart" &&
              <div className="row mt-5 mb-5">
                <div className="jumbotron mt-5" style={{backgroundColor:'yellow'}}>
                <p className="lead">(opcjonalnie)</p>
                  <p className="lead" style={{fontWeight:'bold'}}>Kod promocyjny do bezpłatnego dostępu do monitoringu infekcji chorób przechowalniczych jabłek i gruszek do końca października 2024:</p>
                  <p className="lead">
                    <input type="text" 
                      className="form-control mb-5" 
                      placeholder={`Kod promocyjny`} 
                      style={{marginTop:15, width: '100%', borderColor: '#ccc'}} 
                      value={promoCode} 
                      onChange={e=>setPromoCode(e.target.value)}
                    />
                  </p>
                </div>
              </div>
            }
            <label>
            <input type="text" 
              className="form-control" 
              placeholder={msg.POST_CODE_OR_CITY} 
              style={{marginTop:7, width: 250}} 
              value={postCode} 
              maxLength={maxPostcodeOrCityLength}
              onChange={e=>{if (e.nativeEvent.data == '-') return false; if (e.nativeEvent.inputType === 'deleteContentBackward') setPostCode(''); else setPostCode(e.target.value)}}
              onKeyDown={e => {if (e.nativeEvent.key == 'Enter') {getPostcodeCoords(postCode); if (postCode!='') map.setZoom(10)}}}
            />
          </label> 
          <Button 
            style={{marginLeft: 10}} 
            onClick={()=>{getPostcodeCoords(postCode); if (postCode!='') map.setZoom(10)}} 
            >{msg.SHOW_NEAREST_LOCATIONS}
          </Button>
            <GoogleMapReact
              bootstrapURLKeys={{key: googleMap.publicKey}}
              defaultCenter={mapSettings.center}
              defaultZoom={mapSettings.zoom}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
              options={{'dataMode': 'regions', 'region': 'PL'}}
            >
              {stations && stations.map((station, key) => {
                return <Marker key={key} id={station.id} lat={station.latitude} lng={station.longitude} location={station.name} markerColor={station.id!==selectedStation?.id?'green':'darkblue'} markerSize={28} entity={station} />;
              })}
            </GoogleMapReact>
          </div>
          <div style={{margin: '0px auto', marginBottom: 0, display: 'inline-block', textAlign: 'center', marginTop: companyName==='FarmSmart' ? '600px' : '240px'}}>
                <ReCAPTCHA ref={recaptcha} sitekey={reCaptchaKey.publicKey} />
              </div>
          <div className="mt-5">
                <Checkbox
                  checked={termsAccept}
                  onChange={e=>setTermsAccept(e.target.checked)}
                  value="termsAccept"
                  color="secondary"
                  inputProps={{
                    'aria-label': 'secondary checkbox',
                  }}
                /> akceptuję <a href="https://farmsmart.pl/regulamin-portalu/" target="_blank">regulamin</a>
            </div>
            <Button 
              style={{marginLeft: 8, height: 42, width: '50%', marginBottom: '60px', marginTop: '30px'}} 
              className="btn"
              onClick={_=>{verify()}}
              disabled={!termsAccept || selectedStation === null || cultivation == null || btnBlocked}
            >
              Utwórz konto!
            </Button> 
        </div>
      </div>
    </div>
  );
}
