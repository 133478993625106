import * as requestFromServer from "./subscriptionsApi";
import { subscriptionsSlice, callTypes } from "./subscriptionsSlice";

const { actions } = subscriptionsSlice;

export const fetchUserSettings = _ => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getUserSettings()
    .then(response => {
      const entities = response.data;
      dispatch(actions.userSettingsFetched(entities));
    })
    .catch(error => {
      error.clientMessage = "Can't find locations";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

export const fetchLocations = _ => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getLocations()
    .then(response => {
      const entities = response.data;
      dispatch(actions.locationsFetched(entities));
    })
    .catch(error => {
      error.clientMessage = "Can't find locations";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}
export const fetchAffiliateEarnings = (dateFrom, dateTo) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAffiliateEarnings(dateFrom, dateTo)
    .then(response => {
      const earnings = response.data;
      dispatch(actions.affiliateEarningsFetched(earnings));
    })
    .catch(error => {
      error.clientMessage = "Can't find locations";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

export const fetchCultivationsList = _ => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getCultivationsList()
    .then(response => {
      const entities = response.data;
      dispatch(actions.cultivationsListFetched(entities));
    })
    .catch(error => {
      error.clientMessage = "Can't find cultivations";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}
export const fetchUserCultivationsList = _ => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getUserCultivationsList()
    .then(response => {
      const entities = response.data;
      dispatch(actions.userCultivationsListFetched(entities));
    })
    .catch(error => {
      error.clientMessage = "Can't find user cultivations";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

export const fetchStationCultivationInfo = (stationId, cultivationId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(actions.stationCultivationInfoReset());
  return requestFromServer
    .getStationCultivationInfo(stationId, cultivationId)
    .then(response => {
      const entities = response.data;
      dispatch(actions.stationCultivationInfoFetched(entities));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch station cultivation info";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

export const fetchAllAvailableLocations = _ => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllAvailableLocations()
    .then(response => {
      const entities = response.data;
      dispatch(actions.allAvailablelocationsFetched(entities));
    })
    .catch(error => {
      error.clientMessage = "Can't find available locations";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}

export const fetchStationForecast = (stationId, langName) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getStationForecast(stationId, langName)
    .then(response => {
      var stationForecast = response.data;
      dispatch(actions.stationForecastFetched({ stationForecast }));
    })
    .catch(error => {
      error.clientMessage = "Can't find station forecast";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchVirtualStationForecast = (stationId, langName) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getVirtualStationForecast(stationId, langName)
    .then(response => {
      var virtualStationForecast = response.data;
      dispatch(actions.virtualStationForecastFetched({ virtualStationForecast }));
    })
    .catch(error => {
      error.clientMessage = "Can't find virtual station forecast";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchStationAvailableSensors = (stationId, dateFrom, dateTo) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(actions.resetSensorsState());
  return requestFromServer
    .getStationAvailableSensors(stationId, dateFrom, dateTo)
    .then(response => {
      var stationAvailableSensors = response.data;
      dispatch(actions.stationAvailableSensorsFetched({ stationAvailableSensors }));
    })
    .catch(error => {
      error.clientMessage = "Can't find station available sensors";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchStationAvailableDiseaseModels = (stationId, cultivationId, dateFrom, dateTo) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(actions.resetStationAvailableDiseaseModels());
  return requestFromServer
    .getStationAvailableDiseaseModels(stationId, cultivationId, dateFrom, dateTo)
    .then(response => {
      var stationAvailableDiseaseModels = response.data;
      dispatch(actions.stationAvailableDiseaseModelsFetched({ stationAvailableDiseaseModels }));
    })
    .catch(error => {
      error.clientMessage = "Can't find station disease models";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchStationCultivationInsectOccurences = (stationId, cultivationId, dateFrom, dateTo) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(actions.resetStationCultivationInsectOccurrences());
  return requestFromServer
    .getStationCultivationInsectOccurrences(stationId, cultivationId, dateFrom, dateTo)
    .then(response => {
      var stationCultivationInsectOccurrences = response.data;
      dispatch(actions.stationCultivationInsectOccurrencesFetched({ stationCultivationInsectOccurrences }));
    })
    .catch(error => {
      error.clientMessage = "Can't find station cultivation insect occurrences";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchStationCultivationInsectModels = (stationId, cultivationId, year) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(actions.resetStationCultivationInsectModels());
  return requestFromServer
    .getStationCultivationInsectModels(stationId, cultivationId, year)
    .then(response => {
      var stationCultivationInsectModels = response.data;
      dispatch(actions.stationCultivationInsectModelsFetched({ stationCultivationInsectModels }));
    })
    .catch(error => {
      error.clientMessage = "Can't find station cultivation insect models";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchStationDiseasesCultivations = stationId => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getStationDiseasesCultivations(stationId)
    .then(response => {
      var stationDiseasesCultivations = response.data;
      dispatch(actions.stationDiseasesCultivationsFetched({ stationDiseasesCultivations }));
    })
    .catch(error => {
      error.clientMessage = "Can't find station cultivations for diseases";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchStationInsectOccurrencesCultivations = stationId => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getStationInsectOccurrencesCultivations(stationId)
    .then(response => {
      var stationInsectOccurrencesCultivations = response.data;
      dispatch(actions.stationInsectOccurrencesCultivationsFetched({ stationInsectOccurrencesCultivations }));
    })
    .catch(error => {
      error.clientMessage = "Can't find station cultivations for insect occurrences";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchStationInsectModelsCultivations = stationId => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getStationInsectModelsCultivations(stationId)
    .then(response => {
      var stationInsectModelsCultivations = response.data;
      dispatch(actions.stationInsectModelsCultivationsFetched({ stationInsectModelsCultivations }));
    })
    .catch(error => {
      error.clientMessage = "Can't find station cultivations for insect occurrences";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchPrices = _ => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getPrices()
    .then(response => {
      dispatch(actions.pricesFetched(response.data));
    })
    .catch(error => {
      error.clientMessage = "Can't get prices";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSubscriptions = _ => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getSubscriptions()
    .then(response => {
      dispatch(actions.subscriptionsFetched(response.data));
    })
    .catch(error => {
      error.clientMessage = "Can't get subscriptions";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchSubscriptionsOrders = _ => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getSubscriptionsOrders()
    .then(response => {
      dispatch(actions.subscriptionsOrdersFetched(response.data));
    })
    .catch(error => {
      error.clientMessage = "Can't get subscriptions";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchUserInvoiceInfo = _ => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getUserInvoiceInfo()
    .then(response => {
      dispatch(actions.invoiceInfoFetched(response.data));
    })
    .catch(error => {
      error.clientMessage = "Can't get invoice info";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const calculateOrder = (order, discountCode) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(actions.resetOrder());
  return requestFromServer
    .calculateOrder(order, discountCode)
    .then(response => {
      dispatch(actions.orderCalculated(response.data));
    })
    .catch(error => {
      error.clientMessage = "Can't calculate order";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const pay = (order, langName, invoiceData, discountCode) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .pay(order, langName, invoiceData, discountCode)
    .then(response => {
      window.location.href = response.data.redirect;
    })
    .catch(error => {
      error.clientMessage = "Can't process order";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const resetDataState = _ => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return dispatch(actions.resetDataStateDone());
};

export const fetchVirtualStationAvailableSensors = (virtualStationId, dateFrom, dateTo) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(actions.resetVirtualSensorsState());
  return requestFromServer
    .getVirtualStationAvailableSensors(virtualStationId, dateFrom, dateTo)
    .then(response => {
      var virtualStationAvailableSensors = response.data;
      dispatch(actions.virtualStationAvailableSensorsFetched({ virtualStationAvailableSensors }));
    })
    .catch(error => {
      error.clientMessage = "Can't find virtual station available sensors";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const fetchAvailableVirtualStations = _ => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(actions.virtualStationsReset());
  return requestFromServer
    .getAvailableVirtualStations()
    .then(response => {
      var virtualStations = response.data;
      dispatch(actions.availableVirtualStationsFetched({ virtualStations }));
    })
    .catch(error => {
      error.clientMessage = "Can't find available virtual stations";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchDiscountCodeInfo = code => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  dispatch(actions.virtualStationsReset());
  return requestFromServer
    .getDiscountCodeInfo(code)
    .then(response => {
      var codeInfo = response.data;
      dispatch(actions.discountCodeInfoFetched(codeInfo));
    })
    .catch(error => {
      error.clientMessage = "Can't fetch discount code info";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const resetVirtualStations = _ => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return dispatch(actions.virtualStationsReset());
};

export const resetDiscountCodeInfo = _ => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return dispatch(actions.discountCodeInfoReset());
};