import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";


export const actionTypes = {
  Login: "AAD_LOGIN_SUCCESS",
  Logout: "Logout",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  requestUser: 'requestUser'
};

const initialAuthState = {
  user: {},
  authToken: undefined
};

export const requestUser = (user) => {
  return {
    type: actionTypes.requestUser,
    payload: { user },
  }
}

export const logoutUser = _ => {
  return {
    type: actionTypes.Logout,
    payload: {},
  }
}

export const reducer = persistReducer(
  { storage, key: 'root', whitelist: ["authToken", "user"] },
  (state = initialAuthState, action) => {
    switch (action.type) {

      case actionTypes.Login: {
        const { jwtIdToken, account } = action.payload;
        return { jwtIdToken, user: { email: account.idToken.emails[0] } };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;
        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }
      case actionTypes.requestUser: {
        const { user } = action.payload;
        if (user) {
          delete user['discriminator'];
          delete user['_rid'];
          delete user['_self'];
          delete user['_etag'];
          delete user['_attachments'];
          delete user['_ts'];
          return { ...state, user };
        } else {
          return state
        }
      }
      default:
        return state;
    }
  }
);

// export const actions = {
//   login: (authToken) => async () => {
//     return { type: actionTypes.Login, payload: { authToken } }
//   },
//   register: (authToken) => ({
//     type: actionTypes.Register,
//     payload: { authToken },
//   }),
//   logout: () => ({ type: actionTypes.Logout }),
//   requestUser: (user) => async () => {
//     console.log('user', user)
//     return {
//       type: actionTypes.UserRequested,
//       payload: { user },
//     }
//   },
//   fulfillUser: (user) => async () => {
//     return {
//       type: actionTypes.UserLoaded, payload: { user }
//     }
//   },
//   setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),

// };
